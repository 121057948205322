import React, {useEffect, useState} from 'react';
import StudentProfileForm from '../components/ProfileInfoForm/StudentProfileForm';
import ParentProfileForm from '../components/ProfileInfoForm/ParentProfileForm';
import AuthLayout from "../components/AuthLayout";
import {get} from '../services/Profile';
import {toast} from "react-toastify";
import auth from "../actions/auth";

const ParentStudentProfileForm = (props) => {
    const [role, setRole] = useState(null);
    const [details, setDetails] = useState(null);

    useEffect(() => {
        get(auth.check().id).then((res) => {
            console.log(res)
            if(res.status === 200) {
                const data = res.data.data;
                setRole(data.role_id);
                setDetails({
                    ...res.data.data
                })
            }else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            toast.error("Something went wrong!");
        })
    }, [])
    return (
            <AuthLayout>
                {role && role === "4" && (<StudentProfileForm studentData={details} {...props} />)}
                {role && role === "3" && (<ParentProfileForm parentData={details} {...props} />)}
            </AuthLayout>
        );
}

export default ParentStudentProfileForm;