import React, {useRef, useState} from 'react';
import {  Modal} from 'react-bootstrap';
import {MdClose} from 'react-icons/md';

export default function SubscriptionModal(props) {
    const [isSubmitted, setSubmitFlag] = useState(false);
    const onSubmit = (e) => {
        e.preventDefault();
        console.log(firstNameRef.current.value);
        console.log(lastNameRef.current.value);
        console.log(emailRef.current.value);
        setSubmitFlag(true)
    }
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const emailRef = useRef(null);
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body closeButton>
            <div className="close-btn-container">
                <button onClick={props.onHide}>
                    <MdClose />
                </button>
                
            </div>
            {!isSubmitted ? (
                <>
                    <div className="header">
                        <h6>Subscribe to SISTEM</h6>
                        <p>Kindly provide your name and email address.</p>
                    </div>
                    <form onSubmit={onSubmit}>
                        <div className="form-group">
                            <label htmlFor="firstName">First Name</label>
                            <input type="text" className='form-control' id="firstName" name="firstName" ref={firstNameRef} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastName">Last Name</label>
                            <input type="text" className='form-control' id="lastName" name="lastName" ref={lastNameRef} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email Address</label>
                            <input type="text" className='form-control' id="email" name="email" ref={emailRef}  />
                        </div>
                        <div className="submit-btn-container">
                            <button type="submit" className='btn btn-primary text-white btn-padding'>
                            Continue
                            </button>
                        </div>
                        
                    </form>
                </>
            ): (
                <div className="success-block">
                    <div className="header">
                        <h6>You have successfully signed up to receive SISTEM newsletters</h6>
                        <p>You will be receiving updates from us, from time to time. Not to worry, we won’t spam you.</p>
                    </div>
                    <div className="submit-btn-container">
                        <button type="button" className='btn btn-primary text-white btn-padding' onClick={props.onHide}>
                            Close
                        </button>
                    </div>
                </div>
            )}
            
        </Modal.Body>
      </Modal>
    );
  }
  