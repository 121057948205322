/* eslint-disable */
import React, { Component } from "react";
import Layout from "../components/Layout";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { FaRegCheckCircle } from "react-icons/fa";
import cookie from "../actions/cookie";
import { Link } from 'react-router-dom';

class Verified extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    componentDidMount() {
       if(!cookie.getCookie("_token")) {
           // unauth
       }else {
           this.props.history.push("/dashboard");
       }
    };

    render() {

        return (
            <Layout>
                <div className='layout-container bg-bubble'>
                    <section className={`container`}>
                        <div className='form-content'>
                            <div className="intro-content">
                                <h3 className='text-center text-dark my-5'>Email Verification Successful!</h3>
                                <FaRegCheckCircle className="text-primary w-100 mx-auto mb-5" size={150}/>
                                <p className="text-center text-dark w-75 mx-auto">You have succesfully verified your email. You can now login and explore the platform.</p>
                                <div className='d-flex justify-content-center align-items-center my-5'>
                                    <Link to="/">
                                        <Button
                                            variant='primary'
                                            className='btn-padding--lg text-white'
                                        >
                                            Done
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Layout>
        );
    }
}

Verified.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default Verified;