import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import BlankAuthLayout from '../components/BlankAuthLayout';
import guard from "../actions/guard";
import {useHistory, Link} from 'react-router-dom';
import TutorDetail from '../components/BookTutorDetail/TutorInfo';
import TutorReviews from '../components/BookTutorDetail/TutorReviews';
import { findTutorById } from "../services/Tutor";
import './BookTutorDetail.css';

const BookTutorDetail = () => {

    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState(4);
    const [tutorDetail, setTutorDetail] = useState(null);
    const history = useHistory();
    const {id} = useParams();

    useEffect(() => {
        if (guard.role() === "tutor") {
            history.push('/dashboard')
        } else {
            if(!id) return;
            getTutorById(id);
        }
    }, [history, id]);

    const getTutorById = (id) => {
        findTutorById(id).then(res => {
            setTutorDetail(res.data.data)
        })
    }

    return (
        <BlankAuthLayout>
            <div className="tutor-detail-container">
                <header className='header'>
                        <div className="logo-wrapper">
                            <Link to="/">
                                <img src="/images/logo.svg" alt="site-logo"/>
                            </Link>
                        </div>
                        <h4 className="title">Tutor’s Profile</h4>
                        <button className="btn btn-primary btn-padding--md text-white mb-3 mx-auto mx-md-0 text-capitalize">Back to Results</button>
                </header>
                {tutorDetail && (<>
                    <TutorDetail value={value} tutorDetail={tutorDetail} />
                    <TutorReviews />
                </>)}
                
            </div>
        </BlankAuthLayout>
    );
}

export default BookTutorDetail;