import React, { Component } from 'react'
import validation from "../../../actions/validation";
import {toast} from "react-toastify";

const questions = ['Improve academic performance', 'Help with assignments and school work', 'Guidance and support', 'Homeschooling'];

export default class Additional extends Component {

  constructor(props) {
    super(props);
    this.state = {
        complete: false,
        additional_details_for_achive: [],
        about_student: "",
        //validation
        isValid: false,
        loading: false,
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.loading !== prevProps.loading || this.props.complete !== prevProps.complete) {
        this.setState({
            ...this.state,
            loading: this.props.loading,
            complete: this.props.complete,
        });
    }
  }

  onChange = (e) => {
      e.persist();
      if (e.target.type === "checkbox") {
        if (e.target.checked) {
            this.state[e.target.name].push(e.target.value);
            this.setState({
                ...this.state,
                [e.target.name]: this.state[e.target.name],
                isValid: false
            })
        } else {
            let index = this.state[e.target.name].indexOf(e.target.value);
            this.state[e.target.name].splice(index, 1);
            this.setState({
                ...this.state,
                [e.target.name]: this.state[e.target.name],
                isValid: false
            })
        }
      }else {
        this.setState({
          ...this.state,
          [e.target.name]: e.target.value,
          isValid: false
        })
      }
  };

  onSubmit = () => {
    const { additional_details_for_achive, about_student } = this.state;
      this.setState({
        ...this.state,
        loading: true,
        isValid: true,
      }, () => {
        if(validation.text(about_student)
          && additional_details_for_achive.length > 0
        ) {
          this.props.getData({
            additional_details_for_achive,
            about_student
          });
        }else {
          this.setState({
            ...this.state,
            loading: false
          });
          toast.error("Please fill in the form.");
        }
      });
  }

  render() {
    const { additional_details_for_achive, about_student, complete, loading, isValid } = this.state; 
    return (
      <div>
        {
          !complete ?
          <section className='additional-section px-5'>
            <h3 className='text-center text-dark my-5'>Additional Detail</h3>
            <div className='form-group mb-5'>
              <label htmlFor='achieve' className='col-form-label'>What do you hope to achieve? Select all that apply</label>
              {
                questions.map((q, key) => (
                  <div className='custom-control custom-checkbox mb-2' key={key}>
                    <input
                      type='checkbox'
                      className='custom-control-input'
                      id={'additional_details_for_achive' + key}
                      onChange={this.onChange} 
                      value={q}
                      name="additional_details_for_achive"
                    />
                    <label className={`custom-control-label checkbox-2 ${(isValid && (additional_details_for_achive.length > 0 ? '' : 'error is-invalid')).toString()}`} htmlFor={'additional_details_for_achive' + key}>
                      {q}
                    </label>
                  </div>
                ))
              }
            </div>
            <div className="from-group">
              <label htmlFor="suggest" className='col-form-label'>Tell us a little more about the student(s)</label>
              <textarea id="suggest" 
              className={`form-control ${(isValid && (validation.text(about_student) ? '' : 'error is-invalid')).toString()}`}
              onChange={this.onChange} 
              rows="4" name="about_student"></textarea>
            </div>
            <div className='d-flex justify-content-center align-items-center my-5'>
                  <button
                    disabled={loading}
                    onClick={this.onSubmit}
                    className={`btn btn-padding px-5 text-white btn-primary`}>
                    Place Request
                  </button>
            </div>
          </section>
          :
          <section className='additional-section padding-2x'>
            <h3 className='text-center text-dark my-5'>You have successfully booked a tutor</h3>
            <p className="text-center text-dark mx-auto">You will get a confirmation email and a message on your dashboard when your tutor has confirmed availability.</p>
            <div className='d-flex justify-content-center align-items-center my-5'>
                  <button
                    onClick={this.props.back}
                    className={`btn btn-padding px-5 text-white btn-primary`}>
                    Back to Dashboard
                  </button>
            </div>
          </section>
        }
      </div>
    )
  }
}
