/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import validation from "../../actions/validation";
import { toast } from "react-toastify";
import { educations } from "../../config/variables";

class TutorS3 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			highest_level_education: "",
			years_of_tutoring_exp: "",
            gpa: "",
            occupation: "",
            preference: "",
			//validation
			isValid: false,
			loading: false
		};
	}

	onChange = (e) => {
        e.persist();
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            isValid: false
        })
    };
	
	onSubmit = () => {
		const { highest_level_education, years_of_tutoring_exp, gpa, occupation, preference } = this.state;
		this.setState({
            ...this.state,
            loading: true,
            isValid: true,
        }, () => {
			if(validation.text(highest_level_education)
                && validation.text(years_of_tutoring_exp)
                && validation.text(gpa)
                && validation.text(occupation)
                && validation.text(preference)
			) {
				const data = JSON.parse(JSON.stringify(this.state));
				this.props.getData(_.omit(data, ['isValid', 'loading']));
			}else {
				this.setState({
					...this.state,
					loading: false
				});
				toast.error("Validation Error!")
			}
		});
	}

	render() {
		const { highest_level_education, years_of_tutoring_exp, gpa, occupation, preference, isValid, loading} = this.state;

		return(
            <React.Fragment>
                <div id='signup-form'>
                    <div className='form-row'>
                        <div className='form-group col-md-6'>
                            <label htmlFor='highest_level_education'>Highest level of education</label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(highest_level_education) ? '' : 'error is-invalid')).toString()}`}
                                    id="highest_level_education" value={highest_level_education}
                                    name="highest_level_education"
                                    onChange={this.onChange}>
                                <option value="">Choose One</option>
                                {
                                    educations.map((val, key) => (
                                        <option key={key} value={val}>{val}</option>
                                    ))
                                }
                            </select>
                            <div className="invalid-feedback">
                                Select your highest level of education.
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                            <label htmlFor='gpa'>GPA</label>
                            <input
                                type='number'
                                name='gpa'
                                id="gpa"
                                value={gpa}
                                onChange={this.onChange}
                                className={`form-control light ${(isValid && (validation.text(gpa) ? '' : 'error is-invalid')).toString()}`}
                            />
                            <div className="invalid-feedback">
                                Type your gpa.
                            </div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-group col-md-6'>
                            <label htmlFor='occupation'>Occupation</label>
                            <input
                                type='text'
                                name='occupation'
                                id="occupation"
                                value={occupation}
                                onChange={this.onChange}
                                className={`form-control light ${(isValid && (validation.text(occupation) ? '' : 'error is-invalid')).toString()}`}
                            />
                            <div className="invalid-feedback">
                                Select your occupation.
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                            <label htmlFor='years_of_tutoring_exp'>Years of tutoring experience</label>
                            <input
                                type='number'
                                name='years_of_tutoring_exp'
                                id="years_of_tutoring_exp"
                                value={years_of_tutoring_exp}
                                onChange={this.onChange}
                                className={`form-control light ${(isValid && (validation.text(years_of_tutoring_exp) ? '' : 'error is-invalid')).toString()}`}
                            />
                            <div className="invalid-feedback">
                                Type your years of tutoring experience.
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className='form-group col-md-6'>
                            <label htmlFor='preference'>Session preference</label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(preference) ? '' : 'error is-invalid')).toString()}`}
                                    id="preference" name="preference" value={preference}
                                    onChange={this.onChange}>
                                <option value="">Choose One</option>
                                <option value="Online">Online</option>
                                <option value="In person">In person</option>
                                <option value="Both">Both</option>
                            </select>
                            <div className="invalid-feedback">
                                Select your preference.
                            </div>
                        </div>
                    </div>

                    <div className='d-flex justify-content-center align-items-center my-5'>
                        <button
                            disabled={loading}
                            className='btn btn-primary btn-padding--lg text-white'
                            onClick={this.onSubmit}
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </React.Fragment>
		);
	}
}

export default TutorS3;