/* eslint-disable */
import React, {Component} from 'react';
import AuthLayout from "../../components/AuthLayout";
import validation from "../../actions/validation";
import { FiCheck } from "react-icons/fi";
import {get} from "../../services/Profile";
import { subscriptionDetails, subscriptionDetailsParent } from "../../services/Payment";
import Calendar from 'react-calendar';
import { FaRegCalendarAlt, FaCheckCircle } from "react-icons/fa";
import { MdAccessTime } from "react-icons/md";
import { Link } from 'react-router-dom';
import auth from "../../actions/auth";
import {toast} from "react-toastify";
import moment from "moment";
import _ from  "lodash";
import RejectModal from "../Modal/Reject";
import RescheduleModal from "../Modal/Reschedule";
import NoticeBoard from '../../components/Notification/NoticeBoard';
import { withRouter } from "react-router-dom";
import $ from "jquery";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import TutorCredits from './TutorCredits';
import guard from "../../actions/guard";

class ParentStudent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pending: [],
            upcoming: [],
            completed: [],
            canceled: [],
            upcomingLoading: true,
            selectedId: null,
            selectedTutorId: null,
            view: 'completed',
            isProfileComplete: false,
            students: [],
            billingDetails: null,
            billingDetailsLoading: true,
            role: null
        }
    }

    componentDidMount() {
        this.getProfile();
        guard.check(() => {
            if (guard.role() === "parent") {
                this.getDetailsParent();
                this.setState({
                    ...this.state,
                    role: "parent"
                })
            } else if(guard.role() === "student") {
                this.getDetails();
                this.setState({
                    ...this.state,
                    role: "student"
                })
            }
        });
    }

    checkProfileCompletion(data) {
        if(data.role_id === "4") {
            if(data.first_name
                && data.last_name
                && data.phone_number
                && data.race
                && data.student_details.address
                && data.student_details.current_gpa
                && data.student_details.current_grade
                && data.student_details.current_school
                && data.student_details.disablities
                && data.student_details.dob
                && data.student_details.major
                && data.student_details.special_needs
                && data.student_details.state
                && data.student_details.zip_code) {
                    return true;
                } else {
                    return false;
                }
        } else if(data.role_id === "3") {
            if(data.first_name
                && data.last_name
                && data.phone_number
                && data.race
                && data.parent_details.address
                && data.parent_details.dob
                && data.parent_details.state
                && data.parent_details.zip_code) {
                    return true;
                } else {
                    return false;
                }
        }
    } 

    getDetails = () => {
        subscriptionDetails().then((res) => {
            console.log(res)
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    billingDetails: res.data.data,
                    billingDetailsLoading: false
                });
            }else {
                this.setState({
                    ...this.state,
                    // billingDetailsLoading: false
                });
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            this.setState({
                ...this.state,
                // billingDetailsLoading: false
            });
            toast.error("Something went wrong!");
        })
    }

    getDetailsParent = () => {
        subscriptionDetailsParent().then((res) => {
            console.log('parent',res)
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    billingDetails: res.data.data,
                    billingDetailsLoading: false,
                });
            }else {
                this.setState({
                    ...this.state,
                    // billingDetailsLoading: false
                });
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            this.setState({
                ...this.state,
                // billingDetailsLoading: false
            });
            toast.error("Something went wrong!");
        })
    }

    getProfile = () => {
        get(auth.check().id).then((res) => {
            console.log(res)
            if(res.status === 200) {
                const data = res.data.data;
                const isProfileComplete = this.checkProfileCompletion(data);
                this.setState({
                    ...this.state,
                    students: res.data.data.student_mapping,
                    pending: _.filter(res.data.data.session_scheduled, {is_rescheduled: 1, progression_status: 0, approved: 0, rejected: 0}),
                    canceled: res.data.data.cancled_session_scheduled,
                    upcoming: _.filter(res.data.data.session_scheduled, {is_completed: 0, approved: 1}),
                    completed: _.filter(res.data.data.session_scheduled, {is_completed: 1, approved: 1}),
                    upcomingLoading: false,
                    isProfileComplete: isProfileComplete
                });
            }else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            toast.error("Something went wrong!");
        })
    }

    modalOpen = (id) => {
        this.setState({
            ...this.state,
            selectedId: id
        }, () => {
            $("#reject-request").modal();
        });
    }

    modalClose = () => {
        this.setState({
            ...this.state,
            selectedId: null
        }, () => {
            $("#reject-request").modal('hide');
        });
    }

    rescheduleClose = () => {
        this.setState({
            ...this.state,
            selectedId: null,
            selectedTutorId: null
        }, () => {
            $("#reschedule").modal('hide');
        });
    }

    rescheduleOpen = (id, tutorId) => {
        this.setState({
            ...this.state,
            selectedId: id,
            selectedTutorId: tutorId
        }, () => {
            $("#reschedule").modal();
        });
    }

    completeProfile= () => {
        const {history} = this.props;
        history.push('/add-profile');
    }

    render() {
        const {isProfileComplete, upcoming, upcomingLoading, canceled, selectedId, selectedTutorId, pending, completed, view } = this.state;
        console.log('isProfileComplete', isProfileComplete)
        return (
            <AuthLayout>
                <div className="auth-container">
                    {!isProfileComplete && (
                    <div className="row">
                        <div className="col-md-12">
                            <Alert variant="primary"className="profile-info-alert">
                                <p>Please Complete your profile.</p>
                                <Button variant="primary" onClick={this.completeProfile}>Complete Profile</Button>
                            </Alert>
                        </div>
                    </div>
                    )}
                    <div className="row">
                        <section className="col-md-8 mb-4">
                            <div className="book-tutor-cta">
                                <div>
                                    <h5>{moment().format("LL")}</h5>
                                    <p>Click date on calendar to see a schedule or book a tutor here.</p>
                                </div>
                                <Link to="/tutor/schedule" onClick={e => !isProfileComplete && e.preventDefault()}>
                                    <button className={`btn btn-primary-darker btn-padding text-white ${!isProfileComplete ? 'disabled' : ''}`}>Book A Tutor</button>
                                </Link>
                            </div>
                            {this.state.billingDetails && this.state.role === 'parent' && <TutorCredits students={this.state.billingDetails} />}
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card upcoming-card" style={{minHeight: 600}}>
                                        <div className="card-header py-3 px-4">
                                           <h6 className='mb-0'>Upcoming</h6>
                                        </div>
                                        <div className="card-body">
                                            {
                                                !upcomingLoading && upcoming.length > 0 && upcoming.map((list,i) => (
                                                    <div key={i}>
                                                        <div className="d-flex align-items-center my-3">
                                                            <div className='mr-2'>
                                                                {/* <p>Math Tutorial</p> */}
                                                                <p className='txt-14--light text-capitalize'>Tutor: &nbsp;<b>{list.tutordetails.first_name} {list.tutordetails.last_name}</b></p>
                                                            </div>
                                                            {
                                                                list.tutordetails.details.profile_picture ? 
                                                                <img src={list.tutordetails.details.profile_picture} alt="tutor-avatar" style={{width: 50, height: 50, borderRadius: '50%'}}/>
                                                                :
                                                                <img src="/images/Oval.png" alt="tutor-avatar"/>
                                                            }
                                                        </div>
                                                        <div className='position-relative border-bottom'>
                                                            <span className='dot-text --primary txt-14--light'>{list.online ? 'Online' : 'In-person'}</span>
                                                            <div className="d-flex align-items-start my-2">
                                                                <p className="txt-14--light d-flex align-items-center mr-3"><FaRegCalendarAlt/>&nbsp;{moment(list.schedule_date).format('LL')}</p>
                                                                <p className="txt-14--light d-flex align-items-center"><MdAccessTime/>&nbsp;{moment(list.start_time, ["HH.mm"]).format('LT')} - {moment(list.end_time, ["HH.mm"]).format('LT')}</p>
                                                            </div>
                                                            <p className='txt-14--light text-capitalize'>Student: &nbsp;<b>{list.studentdetails.first_name} {list.studentdetails.last_name}</b></p>
                                                            <div className="d-flex align-items-center my-4">
                                                                <button className="btn btn-outline-primary mr-3" onClick={this.rescheduleOpen.bind(this, list.id, list.tutordetails.id)}>Reschedule</button>
                                                                <button className="btn btn-outline-danger mr-3" onClick={this.modalOpen.bind(this, list.id)}>Cancel</button>
                                                                {
                                                                    list.progression_status === 1 && list.is_completed === 0 &&
                                                                    <button className="btn btn-primary text-white" onClick={() => this.props.history.push("/room/" + btoa(list.id))}>Join</button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            {
                                                !upcomingLoading && upcoming.length === 0 &&
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <p className=" font-weight-bold">No Records!</p>
                                                </div>
                                            }
                                            {
                                                upcomingLoading &&
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <p className=" font-weight-bold">Loading...</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card upcoming-card" style={{minHeight: 600}}>
                                        <div className="card-header py-3 px-4">
                                            <div className="d-flex justify-content-around">
                                                <div className={`tab ${view === "completed" ? 'active' : ''}`} onClick={ () => this.setState({ ...this.state, view: "completed"})}>Completed Sessions</div>
                                                <div className={`tab ${view === "canceled" ? 'active' : ''}`} onClick={ () => this.setState({ ...this.state, view: "canceled"})}>Canceled</div>
                                            </div>
                                        </div>
                                        {
                                            view === "completed" &&
                                            <div className="card-body">
                                                {
                                                    !upcomingLoading && completed.length > 0 && completed.map((list,i) => (
                                                        <div key={i}>
                                                            <div className="d-flex align-items-center my-3">
                                                                <div className='mr-2'>
                                                                    {/* <p>Math Tutorial</p> */}
                                                                    <p className='txt-14--light text-capitalize'>Tutor: &nbsp;<b>{list.tutordetails.first_name} {list.tutordetails.last_name}</b></p>
                                                                </div>
                                                                {
                                                                    list.tutordetails.details.profile_picture ? 
                                                                    <img src={list.tutordetails.details.profile_picture} alt="tutor-avatar" style={{width: 50, height: 50, borderRadius: '50%'}}/>
                                                                    :
                                                                    <img src="/images/Oval.png" alt="tutor-avatar"/>
                                                                }
                                                            </div>
                                                            <div className='position-relative border-bottom pb-3'>
                                                                <span className='dot-text --primary txt-14--light'>{list.online ? 'Online' : 'In-person'}</span>
                                                                <div className="d-flex align-items-start my-2">
                                                                    <p className="txt-14--light d-flex align-items-center mr-3"><FaRegCalendarAlt/>&nbsp;{moment(list.schedule_date).format('LL')}</p>
                                                                    <p className="txt-14--light d-flex align-items-center"><MdAccessTime/>&nbsp;{moment(list.start_time, ["HH.mm"]).format('LT')} - {moment(list.end_time, ["HH.mm"]).format('LT')}</p>
                                                                </div>
                                                                <p className='txt-14--light text-capitalize mb-2'>Student: &nbsp;<b>{list.studentdetails.first_name} {list.studentdetails.last_name}</b></p>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    !upcomingLoading && completed.length === 0 &&
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <p className=" font-weight-bold">No Records!</p>
                                                    </div>
                                                }
                                                {
                                                    upcomingLoading &&
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <p className=" font-weight-bold">Loading...</p>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {
                                            view === "canceled" &&
                                            <div className="card-body">
                                                {
                                                    !upcomingLoading && canceled.length > 0 && canceled.map((list,i) => (
                                                        <div key={i}>
                                                            <div className="d-flex align-items-center my-3">
                                                                <div className='mr-2'>
                                                                    {/* <p>Math Tutorial</p> */}
                                                                    <p className='txt-14--light text-capitalize'>Tutor: &nbsp;<b>{list.tutordetails.first_name} {list.tutordetails.last_name}</b></p>
                                                                </div>
                                                                {
                                                                    list.tutordetails.details.profile_picture ? 
                                                                    <img src={list.tutordetails.details.profile_picture} alt="tutor-avatar" style={{width: 50, height: 50, borderRadius: '50%'}}/>
                                                                    :
                                                                    <img src="/images/Oval.png" alt="tutor-avatar"/>
                                                                }
                                                            </div>
                                                            <div className='position-relative border-bottom pb-3'>
                                                                <span className='dot-text --primary txt-14--light'>{list.online ? 'Online' : 'In-person'}</span>
                                                                <div className="d-flex align-items-start my-2">
                                                                    <p className="txt-14--light d-flex align-items-center mr-3"><FaRegCalendarAlt/>&nbsp;{moment(list.schedule_date).format('LL')}</p>
                                                                    <p className="txt-14--light d-flex align-items-center"><MdAccessTime/>&nbsp;{moment(list.start_time, ["HH.mm"]).format('LT')} - {moment(list.end_time, ["HH.mm"]).format('LT')}</p>
                                                                </div>
                                                                <p className='txt-14--light text-capitalize mb-2'>Student: &nbsp;<b>{list.studentdetails.first_name} {list.studentdetails.last_name}</b></p>
                                                                <p className='txt-14--light text-capitalize'>Comment: &nbsp;<b>{list.rejected_comment}</b></p>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    !upcomingLoading && canceled.length === 0 &&
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <p className=" font-weight-bold">No Records!</p>
                                                    </div>
                                                }
                                                {
                                                    upcomingLoading &&
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <p className=" font-weight-bold">Loading...</p>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/*empty state*/}
                            {/* <div className="card upcoming-card">
                                <div className="card-header p-4">
                                    <div className="d-flex justify-content-between">
                                        <div className="position-relative">
                                            <span className="dot-text --primary">Upcoming Sessions</span>
                                        </div>
                                        <div className="position-relative">
                                            <div className="dot-text --danger">You have a free trial</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="block-container text-center mx-auto my-5">
                                        <h5 className="mb-4">Welcome, John</h5>
                                        <p className="mb-4">It looks lonely in here. Get started by booking a tutor.
                                            The first one is on the house.  😉</p>
                                        <div className="d-flex justify-content-around profile-cta">
                                            <button
                                                className="btn btn-primary-darker text-white"
                                            >
                                                Book tutor with free trial
                                            </button>
                                            <button
                                                className="btn btn-primary text-white"
                                            >
                                                Subscribe to a plan
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </section>

                        <section className="col-md-4">
                            <section className="right-section">
                                <Calendar className='cal-sidebar' />
                                <NoticeBoard notification={pending} history={this.props.history}/>
                            </section>
                        </section>
                    </div>
                </div>

                <RejectModal 
                    id={selectedId} 
                    close={this.modalClose} 
                    next={this.getProfile}
                />

                <RescheduleModal 
                    id={selectedId} 
                    tutorId={selectedTutorId} 
                    dayTimeMapping={undefined} 
                    close={this.rescheduleClose} 
                    next={this.getProfile}
                />

            </AuthLayout>
        );
    }
}

export default withRouter(ParentStudent);