import React, { Component } from 'react'
import AuthLayout from '../components/AuthLayout';
import {Button} from 'react-bootstrap';
import validation from "../actions/validation";
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import guard from "../actions/guard";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import moment from "moment";
import { availSubscription, availSubscriptionParent } from "../services/Payment";
import { FiCheckCircle } from 'react-icons/fi';
import auth from '../actions/auth';

export default class Login extends Component {

    constructor(props) {
        super(props);
        const {location} = props;
        const query = new URLSearchParams(location.search);
        const studentId = query.get("studentId");
        console.log(studentId);
        this.state = {
            cvc: "",
            name: "",
            number: "",
            month: "",
            year: "",
            focus: "number",
            isValidCard: false,

            loading: false,
            isValid: false,
            onSuccess: false,
            studentId: studentId
        };
    }

    componentDidMount() {
        guard.check(() => {
            if (guard.role() !== "tutor") {
                
            } else {
              this.props.history.push("/dashboard");
            }
        });
    }

    onSubmit = () => {
        let input = {
            "plan_id": atob(this.props.match.params.id),
            "number": this.state.number,
            "exp_month": this.state.month,
            "cvc": this.state.cvc,
            "exp_year": this.state.year,
            "student_id": this.state.studentId
        };
        this.setState({
            ...this.state,
            loading: true,
            isValid: true
        }, () => {
            if(this.state.isValidCard
                && validation.text(this.state.month)
                // && validation.text(this.state.year)
                && validation.text(this.state.name)
                && validation.positiveNumber(this.state.cvc)
            ) {
                console.log('input',input)
                console.log(this.state.studentId)
                if(input.student_id) {
                    availSubscriptionParent(input).then((res) => {
                        if(res.status === 200) {
                            this.setState({
                                ...this.state,
                                loading: false,
                                onSuccess: true
                            });
                            auth.update("subscription_plan_id", res.data.data.subscription_plan_id);
                            auth.update("subscription_id", res.data.data.subscription_id);
                            auth.update("card_id", res.data.data.card_id);
                        }else {
                            this.setState({
                                ...this.state,
                                loading: false,
                            });
                            throw new Error (res.response.data.message);
                        }
                    }).catch((err) => {
                        this.setState({
                            ...this.state,
                            loading: false,
                        });
                        toast.error(err.message);
                    })
                } else {
                    availSubscription(input).then((res) => {
                        if(res.status === 200) {
                            this.setState({
                                ...this.state,
                                loading: false,
                                onSuccess: true
                            });
                            auth.update("subscription_plan_id", res.data.data.subscription_plan_id);
                            auth.update("subscription_id", res.data.data.subscription_id);
                            auth.update("card_id", res.data.data.card_id);
                        }else {
                            this.setState({
                                ...this.state,
                                loading: false,
                            });
                            throw new Error (res.response.data.message);
                        }
                    }).catch((err) => {
                        this.setState({
                            ...this.state,
                            loading: false,
                        });
                        toast.error(err.message);
                    })
                }
                
            }else {
                toast.error("Validation Error!");
            }
        })
    }

    onChange = (e) => {
        e.persist();
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            focus: e.target.name,
            isValid: false,
            loading: false
        })
    }

    render() {
        const { cvc, name, number, focus, month, year, isValid, isValidCard, loading, onSuccess } = this.state;

        return (
            <AuthLayout>
                <div className="auth-container">
                    <div className="book-tutor-section">
                        <div className="d-flex align-items-center mb-2">
                            <h6 className='mr-2 mb-0'>Payment</h6>
                        </div>
                        <section className="book-tutor-block">
                            {
                                onSuccess ?
                                <div className="padding-form">
                                    <div className="d-flex justify-content-center">
                                        <div className="text-center">
                                            <div className="animation-ctn">
                                                <div className="icon icon--order-success svg mb-5">
                                                    <FiCheckCircle color="green" size="150"/>
                                                </div>
                                            </div>
                                            <h2 className="bold mb-2">Subscription Successful!</h2>
                                            <p className="mb-4">Your Subscription was successfully processed. Check your email for your receipt.</p>
                                            <button className="btn btn-link" onClick={() => this.props.history.push("/dashboard")}>Back to dashboard</button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="padding-form">
                                    {/* <div className="tab-group mb-5">
                                        <button className={`tab active`} aria-label="credit card tab">
                                            <div className="d-flex flex-column">
                                                <img src="/images/Card.svg" alt="cc" className='mb-2'/>
                                                <div className='tab-text'>Credit Card</div>
                                            </div>
                                        </button>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="form-group">
                                                <label htmlFor='endtime' className="txt-14 text-deep">Card number</label>
                                                <input
                                                    type='number'
                                                    name='number'
                                                    value={number}
                                                    onChange={this.onChange}
                                                    className={`form-control light ${(isValid && (isValidCard ? '' : 'error is-invalid')).toString()}`}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor='expiry' className="txt-14 text-deep">Valid until</label>
                                                <div className="form-row justify-content-between">
                                                    <div className="form-group col-md-6">
                                                        <select className={`custom-select form-control light ${(isValid && (validation.text(month) ? '' : 'error is-invalid')).toString()}`}
                                                                id="month" name="month" value={month} onChange={this.onChange}>
                                                            <option value="">Choose Month</option>
                                                            {
                                                                [...Array(12)].map((val, key) => (
                                                                    <option key={key} value={key+1 > 9 ? key+1 : '0'+(key+1)}>{key+1 > 9 ? key+1 : '0'+(key+1)}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <select className={`custom-select form-control light ${(isValid && (validation.text(year) ? '' : 'error is-invalid')).toString()}`}
                                                                id="year" name="year" value={year} onChange={this.onChange}>
                                                            <option value="">Choose Year</option>
                                                            {
                                                                [...Array(20)].map((val, key) => (
                                                                    <option key={key-1} value={moment().add(key-1, 'year').format('YYYY')}>{moment().add(key-1, 'year').format('YYYY')}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor='cardholder' className="txt-14 text-deep">Card holder</label>
                                                <input
                                                    type='text'
                                                    name='name'
                                                    value={name}
                                                    onChange={this.onChange}
                                                    placeholder='Enter name'
                                                    className={`form-control light ${(isValid && (validation.text(name) ? '' : 'error is-invalid')).toString()}`}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor='cvv' className="txt-14 text-deep">CVV</label>
                                                <div className="form-row justify-content-between align-items-center">
                                                    <input
                                                        type='number'
                                                        name='cvc'
                                                        value={cvc}
                                                        onChange={this.onChange}
                                                        className={`form-control light col-md-5 mb-3 mb-md-0 ${(isValid && (validation.positiveNumber(cvc) ? '' : 'error is-invalid')).toString()}`}
                                                    />
                                                    <p className='col-md-6' style={{fontSize: 11}}>
                                                        Check back of card for three digits.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <Cards
                                                cvc={cvc}
                                                expiry={month+year}
                                                focused={focus}
                                                name={name}
                                                number={number}
                                                callback={(type, isValid) => this.setState({...this.state, isValidCard: isValid})}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className='custom-control custom-checkbox'>
                                        <input
                                            type='checkbox'
                                            className='custom-control-input'
                                            id='savemycard'
                                        />
                                        <label className='custom-control-label' htmlFor='savemycard'>
                                            Save card for future payments?</label>
                                    </div> */}
                                    <div className='d-flex justify-content-end align-items-center my-5'>
                                        <Button
                                            type='submit'
                                            variant='primary'
                                            className='btn-padding--md text-white'
                                            onClick={this.onSubmit}
                                            disabled={loading}
                                        >
                                            Pay
                                        </Button>
                                    </div>
                                </div>
                            }
                        </section>
                    </div>
                </div>
            </AuthLayout>
        )
    }
}

Login.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};