/* eslint-disable */
import React, {useState} from 'react';
import _ from "lodash";
import { putFile } from "../services/Common";
import { toast } from "react-toastify";

export default function ProfileImgUpload({prefix, maxSize, mimeTypes, response, existingFile}) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  
    const onFileInput = (e) => {
      if (e.target.files.length > 0) {
          const file = e.target.files[0];
          e.target.value = '';
          if (file.name) {
              if (_.indexOf(mimeTypes, file.type) > -1) {
                  var size = file.size / 1024 / 1024;
                  if (size <= maxSize) {
                      putFile(file).then(res => {
                          if(res.status === 200) {
                            setLoading(false);
                            setName(file.name);
                            response({prefix: prefix, loading: false, status: "success", msg: res.data.message, resp: res.data.upload_path});
                          }else {
                            setLoading(false);
                            response({prefix: prefix, loading: false, status: "error", msg: res.data.message, resp: ""});
                          }
                      }).catch(err => {
                          toast.error(err.message)
                      });
                  } else {
                    response({prefix: prefix, loading: false, status: "error", msg: "File Size is too large", resp: ""});
                  }
              }else {
                response({prefix: prefix, loading: false, status: "error", msg: "File type not supported.", resp: ""});
              }
          } else {
            response({prefix: prefix, loading: false, status: "error", msg: "No File Name Found", resp: ""});
          }
      }
  }
  
  return (
    <div>
      <div className="d-flex mx-auto profile-avatar">
          <label className='upload-photo-label' htmlFor={prefix}>
            {
              !loading ?
              <img src={existingFile ? existingFile : "https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png?1592828498"} alt="avatar" className="profile-img"/>
              :
              <p>Uploading</p>
            }
          </label>
          <input type="file" name="photo" className="upload-photo" id={prefix} onChange={onFileInput}/>
      </div>
      <p className="text-capitalize txt-14 text-wrap text-center mb-1" style={{color: '#2A2A2A'}}>Click avatar to upload new picture.</p>
    </div>
  )
}
