/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import validation from "../../actions/validation";
import { toast } from "react-toastify";
import DropZone from "../DropZone";

class TutorS6 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			resume: "",
			profile_picture: "",
			certificates: "",
			cover_letter: "",
			//validation
			isValid: false,
			loading: false,
		};
	}

	componentDidUpdate(prevProps) {
        if(this.props.loading != prevProps.loading) {
            this.setState({
                ...this.state,
                loading: this.props.loading,
            });
        }
    }
	
	onSubmit = () => {
		const { profile_picture, certificates, cover_letter, resume } = this.state;
		this.setState({
            ...this.state,
            loading: true,
            isValid: true,
        }, () => {
			if(validation.text(resume)
				&& validation.text(profile_picture)
				&& validation.text(certificates)
				&& validation.text(cover_letter)
			) {
				const data = JSON.parse(JSON.stringify(this.state));
				this.props.getData(_.omit(data, ['isValid', 'loading']));
			}else {
				this.setState({
					...this.state,
					loading: false
				});
				toast.error("Upload docements & images first.");
			}
		});
	}

	getFile = (data) => {
		if(data.resp) {
			this.setState({
				...this.state,
				loading: data.loading,
				[data.prefix]: data.resp
			});
		}
	}

	render() {
		const { profile_picture, resume, certificates, cover_letter, loading } = this.state;

		return(
		<React.Fragment>
			<div id='signup-form'>
				<div className="mb-3">
					<label className="mb-1 ml-4 pl-1 font-weight-bold">
						Upload Your Photo
					</label>
					<DropZone 
						maxSize={5} 
						existingFile={profile_picture}
						response={this.getFile.bind(this)} 
						prefix={"profile_picture"} 
						mimeTypes="image/png, image/jpeg, image/jpg"
					/>
				</div>

				<div className="mb-3">
					<label className="mb-1 ml-4 pl-1 font-weight-bold">
						Upload Resume
					</label>
					<DropZone 
						maxSize={5} 
						existingFile={resume}
						response={this.getFile.bind(this)} 
						prefix={"resume"} 
						mimeTypes="image/png, image/jpeg, image/jpg"
					/>
				</div>

				<div className="mb-3">
					<label className="mb-1 ml-4 pl-1 font-weight-bold">
						Upload Cover Letter
					</label>
					<DropZone 
						maxSize={5}
						existingFile={cover_letter} 
						response={this.getFile.bind(this)} 
						prefix={"cover_letter"} 
						mimeTypes="image/png, image/jpeg, image/jpg"
					/>
				</div>

				<div className="mb-3">
					<label className="mb-1 ml-4 pl-1 font-weight-bold">
						Upload Teaching Certificate ot Transcript
					</label>
					<label className="mb-2 mx-4 p-1">
						If you are a certifed teacher, please provide your certificate or most recent transcript 
					</label>
					<DropZone 
						maxSize={5} 
						existingFile={certificates}
						response={this.getFile.bind(this)} 
						prefix={"certificates"} 
						mimeTypes="image/png, image/jpeg, image/jpg"
					/>
				</div>
				<div className='d-flex justify-content-center align-items-center my-5'>
					<button
						disabled={loading}
						className='btn btn-primary btn-padding--lg text-white'
						onClick={this.onSubmit}
					>
						Continue
					</button>
				</div>
			</div>
		</React.Fragment>
		);
	}
}

export default TutorS6;