import React, {useEffect} from 'react'
import { Dropdown } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { MdMenu, MdClose } from 'react-icons/md';
import './MenuNav.css';
import Menubar from './Menubar';
import cookie from '../../../actions/cookie';
import auth from '../../../actions/auth';
import env from "../../../config/env.json";

export default function Navbar({ path }) {

  const history = useHistory();
  
  const logout = () => {
    cookie.deleteCookie("_token");
    cookie.deleteCookie("_session");
    history.push("/");
  }

  const NavLinks = () => (
    <ul className="navigation-links">
      <li className={path === '/tutor' ? 'active' : ''}><a href={env.LANDING_URI + '/k-12-subscriptions'} target="_blank" rel="noopener noreferrer">K-12 Videos</a></li>
      {/* <li className={path === '/how' ? 'active' : ''}><Link to="/podcasts">Podcasts</Link></li> */}
      <li className={path === '/faq' ? 'active' : ''}><a href={env.LANDING_URI + '/faq'} target="_blank" rel="noopener noreferrer">FAQs</a></li>
      <li className={path === '/help' ? 'active' : ''}><a href={env.LANDING_URI + '/contact'} target="_blank" rel="noopener noreferrer">Help and Support</a></li>
    </ul>
  );
  const showNav = () => {
    document.querySelector(".mobile-nav").classList.add("show");
    document.querySelector(".mobile-header--overlay").classList.add("show");
  };
  const hideNav = () => {
    document.querySelector(".mobile-nav").classList.remove("show");
    document.querySelector(".mobile-header--overlay").classList.remove("show");
  };
  const navFunc = () => {
    document.querySelector(".mobile-header--icon").onclick = function() {
      showNav();
    };
    document.querySelector(".mobile-nav--close").onclick = function() {
      hideNav();
    };
    document.querySelector(
      ".mobile-header--overlay"
    ).onclick = function() {
      hideNav();
    };
  };
  useEffect(() => {
    navFunc();
  });

  return (
    <React.Fragment>
      <header className="fixed-tutor-header desktop-header">
        <div className="container-fluid px-5 py-2">
          <div className="d-flex justify-content-between align-items-center">
            <div className="logo-wrapper">
              <Link to="/">
                <img src="/images/logo.svg" alt="site-logo" />
              </Link>
            </div>
            <nav className="nav-wrapper">
              <NavLinks />
            </nav>
            <div className='d-flex'>
              <div className="icn-circle primary-dark font-weight-bold mr-2 text-capitalize">
                  {auth.check().first_name.split("")[0]}
              </div>
              <Dropdown>
                <Dropdown.Toggle variant="transparent text-grey text-capitalize" id="dropdown-basic">
                  Hello, <b style={{color: '#000'}}>{auth.check().first_name}</b>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="noti-icon">
                <img src="/images/bell-icon.png" alt="bell"/>
                <span className="noti-count">4</span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="mobile-header">
        <header aria-label="mobile-header" className="fixed-header">
          <div className="d-flex align-items-center h-100 position-relative">
            <div className="mobile-header--icon pointer">
              <MdMenu fontSize="24px" />
            </div>
            <div className="logo-wrapper center-hr">
              <Link to="/">
                <img src="/images/logo.svg" alt="site-logo"/>
              </Link>
            </div>
          </div>
        </header>

        <div className="mobile-header--overlay" />

        <aside className="mobile-nav">
          <div className="mobile-nav--close pointer">
            <div className="middle">
              <Link to="/">
                <img src="/images/logo.svg" alt="site-logo"/>
              </Link>
            </div>
            <div className="rounded-circle border bg-light p-2 mr-3">
              <MdClose fontSize="22px" />
            </div>
          </div>
          <div className="nav-wrapper p-3">
            <Menubar />
            <h6 className="txt-14--light bold">MORE</h6>
            <hr className="mt-2 b-3"/>
            <NavLinks />
          </div>
        </aside>
      </div>
    </React.Fragment>
  )
}
