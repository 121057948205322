import React from 'react';
import Rating from '@material-ui/lab/Rating';
import LinearProgress from '@material-ui/core/LinearProgress';

const AverageRating = () => {
    return (
        <div className="average-rating-container">
            <h4 className="title">Average Rating</h4>
            <h2 className="average-rating">4.0</h2>
            <Rating name="read-only" value="4" readOnly />
            <div className="number-rating">
                <div className="five-star">
                    <h5>5</h5>
                    <Rating defaultValue="1" max="1" />
                    <LinearProgress variant="determinate" value="70" />
                    <h5>320</h5>
                </div>
                <div className="four-star">
                    <h5>4</h5>
                    <Rating defaultValue="1" max="1" />
                    <LinearProgress variant="determinate" value="20" />
                    <h5>145</h5>
                </div>
                <div className="three-star">
                    <h5>3</h5>
                    <Rating defaultValue="1" max="1" />
                    <LinearProgress variant="determinate" value="10" />
                    <h5>95</h5>
                </div>
                <div className="two-star">
                    <h5>2</h5>
                    <Rating defaultValue="1" max="1" />
                    <LinearProgress variant="determinate" value="8" />
                    <h5>90</h5>
                </div>
                <div className="one-star">
                    <h5>1</h5>
                    <Rating defaultValue="1" max="1" />
                    <LinearProgress variant="determinate" value="2" />
                    <h5>21</h5>
                </div>
            </div>
        </div>
    )
}

export default AverageRating;