/* eslint-disable */
import React, { Component } from "react";
import TutorLayout from "../../components/TutorLayout";
import { FiCheck } from "react-icons/fi";
import { FaCheckCircle } from "react-icons/fa";
import { MdAccessTime } from "react-icons/md";
import {get} from "../../services/Profile";
import Calendar from 'react-calendar';
import { BsThreeDotsVertical } from 'react-icons/bs';
import Badge from "../../components/Tutor/Badge";
import RequestAlert from '../../components/Tutor/RequestAlert';
import NoticeBoard from '../../components/Notification/NoticeBoard';
import auth from "../../actions/auth";
import {toast} from "react-toastify";
import moment from "moment";
import _ from  "lodash";
import { withRouter } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

class TutorDashboard extends Component {

	constructor(props) {
        super(props);
        this.state = {
			pending: [],
			upcoming: [],
			completed: [],
            scheduleLoading: true,
            trigger: false,
			isProfileComplete: false,
        }
    }

	checkProfileCompletion(data) {
        if(data.role_id === "2") {
            if(data.first_name
                && data.last_name
                && data.race
				&& data.email
				&& data.phone_number
                && data.details.address
                && data.details.certificates
				&& data.details.city
				&& data.details.cover_letter
				&& data.details.dob
				&& data.details.gpa
				&& data.details.highest_level_education
				&& data.details.how_do_you_hear
				&& data.details.occupation
				&& data.details.profile_picture
				&& data.details.resume
				&& data.details.state
				&& data.details.years_of_tutoring_exp
				&& data.details.zip_code
                ) {
                    return true;
                } else {
                    return false;
                }
        } else {
            return false;
        }
    } 

    componentDidMount() {
        this.getProfile();
    }

    getProfile = () => {
        get(auth.check().id).then((res) => {
            if(res.status === 200) {
				const data = res.data.data;
				const isProfileComplete = this.checkProfileCompletion(data);
				console.log(isProfileComplete)
                this.setState({
					...this.state,
					pending: _.filter(res.data.data.schedules, {progression_status: 0, approved: 0, rejected: 0}),
					upcoming: _.filter(res.data.data.schedules, {is_completed: 0, approved: 1}),
					completed: _.filter(res.data.data.schedules, {is_completed: 1, approved: 1}),
                    scheduleLoading: false,
					isProfileComplete: isProfileComplete
				});
            }else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
			console.log(err)
            toast.error("Something went wrong!");
        })
    }

	openAlert = () => {
		this.setState({
			trigger: true
		})
	}

	closeAlert = () => {
		this.setState({
			trigger: false
		})
	}

	completeProfile= () => {
        const {history} = this.props;
        history.push('/tutor/add-profile');
    }

	render() {
		const { scheduleLoading, upcoming, pending, completed, isProfileComplete } = this.state;
		
		return (
			<TutorLayout>
				<div className="tutor-auth-container">
					{!isProfileComplete && (
						<div className="row">
							<div className="col-md-12">
								<Alert variant="primary"className="profile-info-alert">
									<p>Please Complete your profile.</p>
									<Button variant="primary" onClick={this.completeProfile}>Complete Profile</Button>
								</Alert>
							</div>
						</div>
					)}
					<div className="tutor-dashboard-grid">
						<section className="mb-4">
							<div className="stat-card-grid">
								<div className="stat-card teal">
										<h6>Upcoming Tutorials</h6>
										<h2>{upcoming.length}</h2>
								</div>
								<div className="stat-card ">
									{/* <h6>Number of Tutorial Hours Tracked Last Month</h6> */}
									<h6>Completed Tutorial</h6>
									<h2>{completed.length}</h2>
								</div>
								<div className="stat-card grey">
									<h6>Pending Tutorial Requests</h6>
									<h2>{pending.length}</h2>
								</div>
							</div>
							<div className="upcoming-completed-grid">
									<div className="card upcoming-card" style={{ minHeight: 600 }}>
										<div className="card-header py-3 px-4">
											<h6 className="mb-0 text-teal">Upcoming sessions</h6>
										</div>
										<div className="card-body">
											{
												!scheduleLoading && upcoming.length > 0 && upcoming.map((list, i) => (
													<div className='upcoming-row' key={i}>
														<p className='text-grey font-weight-bold align-self-start'>{i+1}.</p>
														<div className='txt-14--light'>
															<div className="d-flex align-items-start">
																<p className='text-grey mb-2 mr-2 text-capitalize'>
																	{list.studentdetails.first_name} {list.studentdetails.last_name} 
																</p>
																<Badge type="pin" badgeText={list.online ? 'Online' : 'In-person'} fontStyle='normal'/>
															</div>
															<div className="badge-grid">
																<Badge type="teal" badgeText={`${moment(list.start_time, ["HH.mm"]).format('LT')} - ${moment(list.end_time, ["HH.mm"]).format('LT')}`} fontStyle="bold"  className='mr-2'/>
																<Badge type="clear" badgeText={moment(list.schedule_date).format('LL')} className="mr-2"/>
															</div>
														</div>
														<div className='image-group'>
															{
                                                                list.tutordetails.details.profile_picture ? 
                                                                <img src={list.tutordetails.details.profile_picture} alt="tutor-avatar" style={{width: 50, height: 50, borderRadius: '50%'}}/>
                                                                :
                                                                <img src="/images/Oval.png" alt="tutor-avatar"/>
                                                            }
															{
                                                                list.studentdetails.student_details.profile_picture ? 
                                                                <img src={list.studentdetails.student_details.profile_picture} alt="student-avatar" style={{width: 50, height: 50, borderRadius: '50%'}}/>
                                                                :
                                                                <img src="/images/Oval.png" alt="tutor-avatar"/>
                                                            }
														</div>
														{/* <div className='pointer' onClick={this.openAlert}>
															<BsThreeDotsVertical />
														</div> */}
													</div>
												))
											}
											{
                                                !scheduleLoading && upcoming.length === 0 &&
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <p className=" font-weight-bold">No Records!</p>
                                                </div>
                                            }
                                            {
                                                scheduleLoading &&
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <p className=" font-weight-bold">Loading...</p>
                                                </div>
                                            }
										</div>
									</div>
								
									<div className="card upcoming-card" style={{ minHeight: 600 }}>
										<div className="card-header py-3 px-4">
											<h6 className="mb-0 text-teal">Completed</h6>
										</div>
										<div className="card-body">
											{
												!scheduleLoading && completed.length > 0 && completed.map((list, i) => (
												<div className='upcoming-row' key={i}>
													<div className='text-teal font-weight-bold align-self-start'>
														<FaCheckCircle  />
													</div>
													<div className='txt-14--light'>
														<div className="d-flex align-items-start">
															<p className='text-grey mb-2 mr-2 text-capitalize'>
																{list.studentdetails.first_name} {list.studentdetails.last_name} 
															</p>
															<Badge type="pin" badgeText={list.online ? 'Online' : 'In-person'} fontStyle='normal'/>
														</div>
														<div className="badge-grid">
															<Badge type="teal" badgeText={`${moment(list.start_time, ["HH.mm"]).format('LT')} - ${moment(list.end_time, ["HH.mm"]).format('LT')}`} fontStyle="bold"  className='mr-2'/>
															<Badge type="clear" badgeText={moment(list.schedule_date).format('LL')} className="mr-2"/>
														</div>
													</div>
													<div className='image-group'>
														{
															list.tutordetails.details.profile_picture ? 
															<img src={list.tutordetails.details.profile_picture} alt="tutor-avatar" style={{width: 50, height: 50, borderRadius: '50%'}}/>
															:
															<img src="/images/Oval.png" alt="tutor-avatar"/>
														}
														{
															list.studentdetails.student_details.profile_picture ? 
															<img src={list.studentdetails.student_details.profile_picture} alt="student-avatar" style={{width: 50, height: 50, borderRadius: '50%'}}/>
															:
															<img src="/images/Oval.png" alt="tutor-avatar"/>
														}
													</div>
												</div>
												))
											}
											{
                                                !scheduleLoading && completed.length === 0 &&
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <p className=" font-weight-bold">No Records!</p>
                                                </div>
                                            }
                                            {
                                                scheduleLoading &&
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <p className=" font-weight-bold">Loading...</p>
                                                </div>
                                            }
										</div>
									</div>
							</div>
						</section>
						<section className="right-section">
							<Calendar className='cal-sidebar' />
							<NoticeBoard notification={pending} history={this.props.history}/>
						</section>
					</div>
				</div>
				{/* <RequestAlert show={trigger} closeFunc={this.closeAlert} /> */}
			</TutorLayout>
		);
	}
}

export default withRouter(TutorDashboard);
