import axios from "axios";
import cookie from "../actions/cookie.js";
import { API_END_POINT } from "../config/env.json";

export const putFile = (stream) => {

    let formData = new FormData();
    formData.append('files', stream);

    const config = {
        headers: {
            'accept': "*/*",
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        },
    };
    return axios.post(`${API_END_POINT}/v1/upload`, formData, config)
        .then(res => res)
        .catch(error => error);
};

export const subjectList = () => {
    const options = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return axios.get(`${API_END_POINT}/v1/list-subject`, options)
        .then(res => res)
        .catch(error => error);
};

export const startSession = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/sessions`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const endSession = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.put(`${API_END_POINT}/v1/sessions/${payload.id}`, payload, options)
        .then(res => res)
        .catch(error => error);
};