/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import validation from "../../actions/validation";
import { toast } from "react-toastify";
import { FiUser, FiCheck, FiLock, FiPhoneForwarded } from "react-icons/fi";
import moment from "moment";

class Common extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tnc: "no",
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            dob: "",
            password: "",
            password_confirmation: "",
            role: "",
			//validation
			isValid: false,
			loading: false
		};
	}

    componentDidUpdate(prevProps) {
        if(this.props.role != prevProps.role) {
            this.setState({
                ...this.state,
                role: this.props.role,
            });
        }
    }

	onChange = (e) => {
        e.persist();
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            isValid: false
        })
    };
	
	onSubmit = () => {
		const { tnc, role, first_name, last_name, password_confirmation, password, phone_number, email, dob } = this.state;
		this.setState({
            ...this.state,
            loading: true,
            isValid: true,
        }, () => {
            if(moment().diff(dob, 'years') >= 13) {
                if(moment().diff(dob, 'years') >= 13 
                    && validation.text(first_name)
                    && validation.text(last_name)
                    && validation.text(dob)
                    && validation.email(email)
                    && validation.passwordStrength(password, "medium")
                    && validation.passwordStrength(password_confirmation, "medium")
                    && validation.text(role)
                    && validation.text(phone_number)
                    && password === password_confirmation
                    && tnc === "yes"
                ) {
                    const data = JSON.parse(JSON.stringify(this.state));
                    this.props.getData(_.omit(data, ['isValid', 'loading', 'tnc']));
                }else {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    toast.error("Validation Error!")
                }
            }else {
                this.setState({
                    ...this.state,
                    loading: false
                });
                toast.error("You should be atleast 13 years old.")
            }
		});
	}

	render() {
		const { tnc, role, first_name, last_name, password_confirmation, password, phone_number, email, dob, isValid, loading} = this.state;

		return(
		<React.Fragment>
			<h3 className='text-center text-dark mb-4'>Set up your account</h3>
                <div id='signup-form'>
                    <div className='form-row'>
                        <div className='form-group col-md-6'>
                            <label htmlFor='first_name'>First Name</label>
                            <div className='input-group'>
                                <div class='input-group-prepend'>
                                    <span class='input-group-text' id='first_name'>
                                        <FiUser />
                                    </span>
                                </div>
                                <input
                                    type='text'
                                    name='first_name'
                                    value={first_name}
                                    onChange={this.onChange}
                                    className={`form-control light ${(isValid && (validation.text(first_name) ? '' : 'error is-invalid')).toString()}`}
                                />
                                <div className="invalid-feedback">
                                    Please enter your first name.
                                </div>
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                            <label htmlFor='last_name'>Last Name</label>
                            <div className='input-group'>
                                <div class='input-group-prepend'>
                                    <span class='input-group-text' id='last_name'>
                                        <FiUser />
                                    </span>
                                </div>
                                <input
                                    type='text'
                                    name='last_name'
                                    value={last_name}
                                    onChange={this.onChange}
                                    className={`form-control light ${(isValid && (validation.text(last_name) ? '' : 'error is-invalid')).toString()}`}
                                />
                                <div className="invalid-feedback">
                                    Please enter your last name.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-group col-md-6'>
                            <label htmlFor='email'>Email Address</label>
                            <div className='input-group'>
                                <div class='input-group-prepend'>
                                    <span class='input-group-text' id='email'>
                                        <img src='/images/mdi-email-edit.svg' alt='email' />
                                    </span>
                                </div>
                                <input type='email'
                                        name='email'
                                        value={email}
                                        onChange={this.onChange}
                                        className={`form-control light ${(isValid && (validation.email(email) ? '' : 'error is-invalid')).toString()}`}
                                />
                                <div className="invalid-feedback">
                                    Please enter a valid email.
                                </div>
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                            <label htmlFor='phone_number'>Phone Number (optional)</label>
                            <div className='input-group'>
                                <div class='input-group-prepend'>
                                    <span class='input-group-text' id='phone_number'>
                                        <FiPhoneForwarded />
                                    </span>
                                </div>
                                <input type='text'
                                        name='phone_number'
                                        value={phone_number}
                                        onChange={this.onChange}
                                        className={`form-control light ${(isValid && (validation.mobile(phone_number) ? '' : 'error is-invalid')).toString()}`}
                                />
                                <div className="invalid-feedback">
                                    Please enter valid, 10 digits long mobile no.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-group col-md-6'>
                            <label htmlFor='password'>Password</label>
                            <div className='input-group'>
                                <div class='input-group-prepend'>
                                    <span class='input-group-text' id='password'>
                                        <FiLock />
                                    </span>
                                </div>
                                <input
                                    type='password'
                                    name='password'
                                    value={password}
                                    onChange={this.onChange}
                                    className={`form-control light ${(isValid && ((validation.passwordStrength(password, "medium") && password === password_confirmation) ? '' : 'error is-invalid')).toString()}`}
                                />
                                <div className="invalid-feedback">
                                    Password miss-matching.
                                </div>
                            </div>
                            <div className='d-flex align-items-center text-grey my-1'>
                                <FiCheck className='mr-1' />
                                <div className='txt-14'>One numeric character</div>
                            </div>
                            <div className='d-flex align-items-center text-grey my-1'>
                                <FiCheck className='mr-1' />
                                <div className='txt-14'>Minimum of 8 characters</div>
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                            <label htmlFor='password_confirmation'>Confirm Password</label>
                            <div className='input-group'>
                                <div class='input-group-prepend'>
                                    <span class='input-group-text' id='password_confirmation'>
                                        <FiLock />
                                    </span>
                                </div>
                                <input
                                    type='password'
                                    name='password_confirmation'
                                    value={password_confirmation}
                                    onChange={this.onChange}
                                    className={`form-control light ${(isValid && ((validation.passwordStrength(password_confirmation, "medium") && password === password_confirmation) ? '' : 'error is-invalid')).toString()}`}
                                />
                                <div className="invalid-feedback">
                                    Password miss-matching.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-group col-md-6'>
                            <label htmlFor='dob'>Date of Birth</label>
                            <input
                                type='date'
                                name='dob'
                                id="dob"
                                value={dob}
                                onChange={this.onChange}
                                className={`form-control light ${(isValid && (validation.text(dob) ? '' : 'error is-invalid')).toString()}`}
                            />
                            <div className="invalid-feedback">
                                Select your dob.
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <label htmlFor='name'>Are you a parent or student?</label>
                        <div className='d-md-flex'>
                            <div className='custom-control custom-checkbox mb-3 mb-md-0 mr-md-3'>
                                <input
                                    type='checkbox'
                                    className='custom-control-input'
                                    name="role"
                                    checked={role === "Parent"}
                                    value="Parent"
                                    onChange={this.onChange}
                                    id='Parent'
                                />
                                <label className={`custom-control-label ${(isValid && (validation.text(role) ? '' : 'error')).toString()}`} htmlFor='Parent'>
                                    Parent</label>
                            </div>
                            <div className='custom-control custom-checkbox'>
                                <input
                                    type='checkbox'
                                    name="role"
                                    checked={role === "Student"}
                                    value="Student"
                                    onChange={this.onChange}
                                    className='custom-control-input'
                                    id='Student'
                                />
                                <label className={`custom-control-label ${(isValid && (validation.text(role) ? '' : 'error is-invalid')).toString()}`} htmlFor='Student'>
                                    Student</label>
                                <div className="invalid-feedback">
                                    Select a role.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='custom-control custom-checkbox'>
                        <input type='checkbox' className='custom-control-input'
                                name="tnc" value={tnc === "no" ? "yes" : "no"}
                                checked={tnc === "yes"} id='tnc'
                                onChange={this.onChange}
                        />
                        <label className={`custom-control-label ${(isValid && (tnc === "yes"  ? '' : 'error is-invalid')).toString()}`} htmlFor='tnc'>
                            I accept the <span>terms and conditions.</span>
                        </label>
                        <div className="invalid-feedback">
                            Agree and continue.
                        </div>
                    </div>

                    <div className='d-flex justify-content-center align-items-center my-5'>
                        <button
                            disabled={loading}
                            className='btn btn-primary btn-padding--lg text-white'
                            onClick={this.onSubmit}
                        >
                            Continue
                        </button>
                    </div>
                </div>
		</React.Fragment>
		);
	}
}

export default Common;