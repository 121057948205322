/* eslint-disable */
import React, { Component } from 'react';
import TutorLayout from "../../components/TutorLayout";
import TutorProfile from "../../components/Profile/TutorProfile";
import TutorAvailability from "../../components/Profile/TutorAvailability";
import TutorBank from "../../components/Profile/TutorBank";
import TutorSecurity from "../../components/Profile/TutorSecurity";

class Tutor extends Component {

  state = {
    tab: 'profile',
  }

  changeTab = (tab) => {
    this.setState({
      ...this.state,
      tab
    });
  }

  render() {
    const { tab } = this.state;
    
    return (
      <TutorLayout>
        <div className="tutor-auth-container p-0">
          <section className="tutor-profile-grid">
            <aside className="tutor-profile-header">
              <button className={`tutor-profile-tab ${tab === 'profile' ? 'active' : ''}`} onClick={this.changeTab.bind(this, 'profile')}>Profile Settings</button>
              <button className={`tutor-profile-tab ${tab === 'availability' ? 'active' : ''}`} onClick={this.changeTab.bind(this, 'availability')}>Availability</button>
              <button className={`tutor-profile-tab ${tab === 'bank' ? 'active' : ''}`} onClick={this.changeTab.bind(this, 'bank')}>Bank Details</button>
              <button className={`tutor-profile-tab ${tab === 'security' ? 'active' : ''}`} onClick={this.changeTab.bind(this, 'security')}>Security & Notification</button>
            </aside>
            <div className='profile-container'>
              <div className="profile-body">
                {
                  tab === 'profile' && (
                    <TutorProfile />
                  )
                }
                {
                  tab === 'availability' && (
                    <TutorAvailability />
                  )
                }
                {
                  tab === 'bank' && (
                    <TutorBank />
                  )
                }
                {
                  tab === 'security' && (
                    <TutorSecurity />
                  )
                }
              </div>
            </div>
          </section>
        </div>
      </TutorLayout>
    )
  }
}

export default Tutor;
