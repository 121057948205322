import React from 'react';
import Review from './Review';
import AverageRating from './AverageRating';
import WhatRatingMeans from './WhatRatingMeans';
import './index.css';

const TutorReviews = () => {
    return (
        <div className="tutor-review">
            <h3 className="title">
                Most recent reviews
            </h3>
            <div>
                <div className="reviews-container">
                    {Array.of(1,2,3).map(i => (
                        <Review key={i} />
                    ))}
                </div>
                <div className="rating-info">
                    <AverageRating />
                    <WhatRatingMeans />
                </div>
            </div>
        </div>
    )
}

export default TutorReviews;