/* eslint-disable */
import React, {Component} from 'react';
import _ from "lodash";
import ProfileImgUpload from '../../components/ProfileImgUpload';
import { FiCheckCircle } from "react-icons/fi";
import validation from "../../actions/validation";
import {toast} from "react-toastify";
import auth from "../../actions/auth";
import {get, put} from "../../services/Profile";
import { states } from "../../config/variables";

class ParentStudentSecurity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            city: "",
            state: "",
            zip_code: "",
            address: "",
            highest_level_education: "",
            //validation
            isValid: false,
            loading: false,
            //image
            profile_picture: "https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png?1592828498",
            bio: null
        }
    }

    componentDidMount() {
        this.getProfile();
    }

    getProfile = () => {
        get(auth.check().id).then((res) => {
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    first_name: res.data.data.first_name,
                    last_name: res.data.data.last_name,
                    email: res.data.data.email,
                    phone_number: res.data.data.phone_number,
                    profile_picture: res.data.data.details.profile_picture,
                    state: res.data.data.details.state,
                    city: res.data.data.details.city,
                    zip_code: res.data.data.details.zip_code,
                    address: res.data.data.details.address,
                    highest_level_education: res.data.data.details.highest_level_education,
                    bio: res.data.data.details.bio ? res.data.data.details.bio : null
                });
            }else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            toast.error("Something went wrong!");
        })
    }

    update = () => {
        const { first_name, last_name, email, phone_number, profile_picture, city, state, zip_code, address, highest_level_education, bio } = this.state;
        let input = {
            basic: {
                first_name: first_name,
                last_name: last_name,
                email: email,
                phone_number: phone_number,
            },
            details: {
                profile_picture,
                city,
                state,
                zip_code,
                address,
                highest_level_education,
                bio
            }
        }
        this.setState({
            ...this.state,
            isValid: true,
            loading: true
        }, () => {
            if(validation.text(first_name)
                && validation.text(last_name)
                && validation.email(email)
                && validation.mobile(phone_number)
                && validation.text(city)
                && validation.text(state)
                && validation.text(zip_code)
                && validation.text(address)
                && validation.text(highest_level_education)
                && validation.text(bio)
            ) {
                put(auth.check().id, input).then((res) => {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    if(res.status === 200) {
                        toast.success(res.data.message);
                        auth.update("first_name", input.basic.first_name);
                        auth.update("last_name", input.basic.last_name);
                    }else {
                        toast.error(res.data.message);
                    }
                }).catch((err) => {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    toast.error("Something went wrong!");
                })
            }else {
                this.setState({
                    ...this.state,
                    loading: false
                })
                toast.error("Validation Error!");
            }
        })
    }

    onChange = (e) => {
        e.persist();
        if(e.target && e.target.type === "checkbox") {
            if (e.target.checked) {
                this.state[e.target.name].push(e.target.value);
                this.setState({
                    ...this.state,
                    [e.target.name]: this.state[e.target.name],
                    isValid: false
                });
            }else {
                let i = _.indexOf(this.state[e.target.name], e.target.value.toString());
                this.state[e.target.name].splice(i, 1);
                this.setState({
                    ...this.state,
                    [e.target.name]: this.state[e.target.name],
                    isValid: false
                });
            }
        }else {
            this.setState({
                ...this.state,
                [e.target.name]: e.target.value,
                isValid: false
            });
        }
    };

    getFile = (data) => {
        if(data.status === "success") {
            this.setState({
                ...this.state,
                loading: data.loading,
                profile_picture: data.resp
            }, () => {
                toast[data.status](data.msg);
            })
        }else {
            toast[data.status](data.msg);
        }
	}

    render() {
        const { first_name, last_name, phone_number, email, profile_picture, city, state, zip_code, address, highest_level_education, bio, isValid, loading } = this.state;

        return (
            <div>
                <h5 className='bold serif text-dark mb-4'>Profile</h5>
                <div className="row">
                <section className="col-md-2 mb-4">
                    <ProfileImgUpload 
                        maxSize={5} 
                        response={this.getFile.bind(this)} 
                        prefix={"profile"} 
                        mimeTypes={['image/png', 'image/jpeg', 'image/jpg']} 
                        existingFile={profile_picture}
                    />
                </section>
                <section className="col-md-6 offset-md-1 ">
                    <label className="col-form-label text-teal bold mb-4">Basic Details</label>
                    <div className='row'>
                    <div className='form-group col-md-6'>
                        <label className="txt-14" htmlFor='first_name'>First Name</label>
                        <div className=''>
                        <input
                            type='text'
                            name='first_name'
                            value={first_name}
                            placeholder="Julian"
                            onChange={this.onChange}
                            className={`form-control light ${(isValid && (validation.text(first_name) ? '' : 'error is-invalid')).toString()}`}
                        />
                        <div className="invalid-feedback">
                            Please enter your first name.
                                                </div>
                        </div>
                    </div>
                    <div className='form-group col-md-6'>
                        <label className="txt-14" htmlFor='last_name'>Last Name</label>
                        <div className=''>
                        <input
                            type='text'
                            name='last_name'
                            placeholder="Moore"
                            value={last_name}
                            onChange={this.onChange}
                            className={`form-control light ${(isValid && (validation.text(last_name) ? '' : 'error is-invalid')).toString()}`}
                        />
                        <div className="invalid-feedback">
                            Please enter your last name.
                                                </div>
                        </div>
                    </div>
                    </div>
                    <div className='row'>
                    <div className='form-group col-md-6'>
                        <label className="txt-14" htmlFor='first_name'>Email</label>
                        <div className='input-group'>
                        <input
                            type='email'
                            name='first_name'
                            className={`form-control light`}
                            placeholder="julianmoore@gmail.com"
                            disabled
                            value={email}
                        />
                        <div className="invalid-feedback">
                            Please enter your first name.
                        </div>
                        <div className='input-group-append'>
                            <span className='input-group-text' id='last_name'>
                            <FiCheckCircle className="text-primary" />
                            </span>
                        </div>
                        </div>
                    </div>
                    <div className='form-group col-md-6'>
                        <label className="txt-14" htmlFor='last_name'>Mobile Number</label>
                        <div className=''>
                        <input type='text'
                            name='phone_number'
                            placeholder="512-408-6025"
                            value={phone_number}
                            onChange={this.onChange}
                            className={`form-control light ${(isValid && (validation.mobile(phone_number) ? '' : 'error is-invalid')).toString()}`}
                        />
                        <div className="invalid-feedback">
                            Please enter valid, 10 digits long mobile no.
                        </div>
                        </div>

                        
                    </div>
                    </div>

                    <div className='row'>
                    <div className='form-group col-md-12'>
                        <label className="txt-14" htmlFor='first_name'>Bio</label>
                        <div className='input-group'>
                        <textarea
                            name='bio'
                            className={`form-control light`}
                            value={bio}
                            onChange={this.onChange}
                        />
                        <div className="invalid-feedback">
                            Please enter your bio.
                        </div>
                        </div>
                    </div>
                    
                    </div>
                    <hr className='tutor-profile-separate' />
                    <label className="col-form-label text-teal bold mb-4">Location</label>
                    <div className="row">
                    <div className="form-group col-md-6">
                        <label htmlFor="city" className='txt-14'>City</label>
                        <select name="city" id="city"
                            onChange={this.onChange}
                            value={state} 
                            className={`custom-select ${(isValid && (validation.text(city) ? '' : 'error is-invalid')).toString()}`}>
                        <option value="">Choose One</option>
                        {
                            states.map((state, key) => (
                                <option key={key} value={state}>{state}</option>
                            ))
                        }
                        </select>
                        <div className="invalid-feedback">
                            Please select city.
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="state" className='txt-14'>State</label>
                        <select name="state" id="state" 
                            className={`custom-select ${(isValid && (validation.text(state) ? '' : 'error is-invalid')).toString()}`}
                            onChange={this.onChange}
                            value={state}
                        >
                        <option value="">Choose One</option>
                        {
                            states.map((state, key) => (
                                <option key={key} value={state}>{state}</option>
                            ))
                        }
                        </select>
                        <div className="invalid-feedback">
                            Please select state.
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className='form-group col-md-6'>
                        <label className="txt-14" htmlFor='zip_code'>Zip Code</label>
                        <input
                            type='text'
                            name='zip_code'
                            value={zip_code}
                            onChange={this.onChange}
                            className={`form-control ${(isValid && (validation.text(zip_code) ? '' : 'error is-invalid')).toString()}`}
                            placeholder="721321"
                        />
                        <div className="invalid-feedback">
                            Please enter valid Zip code.
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="address" className='txt-14'>Address</label>
                        <input type="text" 
                        className={`form-control ${(isValid && (validation.text(address) ? '' : 'error is-invalid')).toString()}`}
                        id="address" 
                        name="address"
                        onChange={this.onChange}
                        value={address}
                        placeholder="5174 Richmond Avenue" />
                        <div className="invalid-feedback">
                            Please enter valid address.
                        </div>
                    </div>
                    </div>
                    <label className="col-form-label text-teal bold mt-5 mb-4">Educational Qualification</label>
                    <div className="row">
                    <div className="form-group col-md-6">
                        <label htmlFor="highest_level_education" className='txt-14'>Highest degree attained</label>
                        <select name="highest_level_education" id="highest_level_education"
                        value={highest_level_education}
                        onChange={this.onChange}
                        className={`custom-select ${(isValid && (validation.text(highest_level_education) ? '' : 'error is-invalid')).toString()}`}>
                        <option value="">Choose One</option>
                        {
                            states.map((state, key) => (
                                <option key={key} value={state}>{state}</option>
                            ))
                        }
                        </select>
                        <div className="invalid-feedback">
                            Select your highest level of education.
                        </div>
                    </div>
                    </div>
                    <div className="d-flex justify-content-end mt-5">
                    <button className="btn btn-primary btn-padding--md text-white"  onClick={this.update}
                                disabled={loading}>
                        Update
                    </button>
                    </div>
                </section>
                </div>
            </div>
        );
    }
}

export default ParentStudentSecurity;