/* eslint-disable */
import React, { Component } from 'react'
import BlankAuthLayout from '../components/BlankAuthLayout'
import {FiCheck} from 'react-icons/fi'
import { Link } from 'react-router-dom';
import TutorListing from '../components/Tutor/BookTutor/TutorListing';
import Schedule from '../components/Tutor/BookTutor/Schedule';
import Additional from '../components/Tutor/BookTutor/Additional';
import guard from "../actions/guard";
import { scheduleParent, scheduleStudent } from "../services/Tutor";
import {toast} from "react-toastify";

export default class BookTutorBuilder extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            payload: {},
            loading: false,
            complete: false,
            role: null,
        }
    }

    componentDidMount() {
        guard.check(() => {
            if (guard.role() !== "tutor") {
                if(guard.role() === "parent") {
                    this.setState({
                        ...this.state,
                        step: 1,
                        role: "parent"
                    })
                } else if(guard.role() === "student") {
                    this.setState({
                        ...this.state,
                        step: 1,
                        role: "student"
                    })
                }
                
            } else {
              this.props.history.push("/dashboard");
            }
        });
    }

    getData = (data) => {
        const { step } = this.state;
        if(step === 1) {
            this.setState({
                ...this.state,
                payload: {
                    ...this.state.payload,
                    ...data
                },
                step: 2
            });
        }else if(step === 2) {
            this.setState({
                ...this.state,
                payload: {
                    ...this.state.payload,
                    ...data
                },
                step: 3
            });
        }else if(step === 3) {
            this.setState({
                ...this.state,
                loading: true,
                payload: {
                    ...this.state.payload,
                    ...data
                }
            }, () => {
                this.schedule();
            });
        } 
    }

    schedule = () => {
        const { payload } = this.state;
        if(this.state.role === "parent") {
            scheduleParent(payload).then((res) => {
                if(res.status === 200) {
                    toast.success("Booking Successfully!");
                    this.setState({
                        ...this.state,
                        complete: true
                    });
                }else {
                    throw new Error (Object.values(res.response.data).reduce((init, current) => (init + " " + current.toString()), ""));
                }
            }).catch((err) => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                toast.error(err.message);
            });
        } else if(this.state.role === "student") {
            scheduleStudent(payload).then((res) => {
                if(res.status === 200) {
                    toast.success("Booking Successfully!");
                    this.setState({
                        ...this.state,
                        complete: true
                    });
                }else {
                    throw new Error (Object.values(res.response.data).reduce((init, current) => (init + " " + current.toString()), ""));
                }
            }).catch((err) => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                toast.error(err.message);
            });
        }
        
    };

    render() {
        const { step, loading, payload, complete } = this.state;
        
        return (
            <BlankAuthLayout>
                <header className='tutor-list__header'>
                    <div className="logo-wrapper">
                        <Link to="/">
                            <img src="/images/logo.svg" alt="site-logo"/>
                        </Link>
                    </div>
                    <div className='step-nav'>
                        <ul>
                            {
                                [...Array(3)].map((el, index) => (
                                    <li className={`${step >= index + 1 ? "active" : ""}`} key={index}>
                                        {step > index + 1 ? (
                                            <span>
                                                <FiCheck />
                                            </span>
                                        ) : (
                                                <span>{index + 1}</span>
                                            )}
                                        {index + 1 === 1 && 'Filter based on your needs'}
                                        {index + 1 === 2 && 'Schedule periods'}
                                        {index + 1 === 3 && 'Additional Details'}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className='d-flex align-items-center align-items-lg-end'>
                        <h6 className="txt-14 text-teal bold mb-0 pointer" onClick={() => this.props.history.push("/dashboard")}>BACK TO DASHBOARD</h6>
                    </div>
                </header>
                <section className='position-relative'>
                    {step === 1 &&
                        <TutorListing getData={this.getData}/>
                    } 
                    {step === 2 &&
                        <Schedule getData={this.getData} tutorId={payload.tutor_id} studentId={payload.student_id} parentId={payload.parent_id} studentName={payload.student_name} studentProfile={payload.student_profile}/>
                    } 
                    {step === 3 &&
                        <Additional getData={this.getData} loading={loading} complete={complete} back={() => this.props.history.push("/dashboard")}/>
                    } 
                </section>
            </BlankAuthLayout>
        )
    }
}
