import React from 'react';
import { Route, Switch } from "react-router-dom";
import Login from './pages/Login.jsx';
import SignUp from './pages/SignUp.jsx';
import SignupTutor from './pages/SignupTutor.jsx';
import Dashboard from './pages/Dashboard.jsx';
import Profile from './pages/Profile.jsx';
import Media from './pages/Media.jsx';
import Notification from './pages/Notification.jsx';
import Payment from './pages/Payment.jsx';
import BookTutorBuilder from './pages/BookTutorBuilder.jsx';
import BookTutorDetail from './pages/BookTutorDetail.jsx';
import Verified from './pages/Verified.jsx';
import Pricing from './pages/Pricing.jsx';
import Session from './pages/Session.jsx';
import ClassRoom from './pages/ClassRoom.jsx';
import Confirmation from './pages/Confirmation.jsx';
import ParentStudentProfileForm from './pages/ParentStudentProfileForm';
import TutorProfileForm from './pages/TutorProfileForm';

function App() {
    return (
        <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/tutor/signup" component={SignupTutor} />
            <Route exact path="/verification" component={Verified} />
            <Route exact path="/confirmation" component={Confirmation} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/room/:id" component={ClassRoom} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/media" component={Media} />
            <Route exact path="/notification" component={Notification} />
            <Route exact path="/payment/:id" component={Payment} />
            <Route exact path="/tutor/schedule" component={BookTutorBuilder} />
            <Route exact path="/tutor/detail/:id" component={BookTutorDetail} />
            <Route exact path="/pricing" component={Pricing} />
            <Route exact path="/session" component={Session} />
            <Route exact path="/add-profile" component={ParentStudentProfileForm} />
            <Route exact path="/tutor/add-profile" component={TutorProfileForm} />
            
        </Switch>
    );
}

export default App;
