import React from 'react';
import Rating from '@material-ui/lab/Rating';

const Review = () => {
    return (
        <div className="review">
            <div>
                <h4 className="title">“Very good tutor”</h4>
                <h5>Feb 14, 2021</h5>
            </div>
            
            <h5>By Jennifer Bridgerton</h5>
            <Rating name="read-only" value="4" readOnly />
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                sed do eiusmod tempor incididunt ut labore et dolore magna 
                aliqua. Ut enim ad minim veniam.</p>
        </div>
    )
}

export default Review;