import React, {useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import './ParentStudentSubscriptionTable.css';
import moment from "moment";
import { FiAlertOctagon, FiCheckCircle } from 'react-icons/fi';
import $ from "jquery";

const SubscriptionTable = ({students, cancelPlan, onSuccess, role, billingDetails}) => {
    const [cancelId, setCancelId] = useState(null);
    const history = useHistory();
    function cancelSubscription(studentId) {
        setCancelId(studentId);
        $("#cancelSubscription").modal();
    }
    return (
        <>
        <div className="subscription-table">
            <div className="row">
                <div className="col student">Name of student/Plan</div>
                <div className="col plan-status">Status</div>
                <div className="col plan-price">Price</div>
                <div className="col actions">Actions</div>
            </div>
            {role === 'parent' && students && students.map(stu => {
                return (
                    <div className="row">
                    <div className="col student">
                        <h5 className="name">
                            {`${stu?.first_name} ${stu?.last_name}`}
                        </h5>
                        {stu?.subscription_id ? <p>{stu?.subscription?.plan?.name}</p> : null}
                    </div>
                    <div className="col plan-status">
                        <h5 className="status active">
                            {stu?.subscription_id ? 'Active' : null}
                        </h5>
                        <p className="expiry">
                            {stu?.subscription_id ? `Expires ${stu?.subscription?.current_period_end ? moment(new Date(stu?.subscription?.current_period_end * 1000)).format('DD MMM YYYY'): null}` : null}
                        </p>
                    </div>
                    <div className="col plan-price">
                        <h5 className="price">
                            {stu?.subscription_id ? `$${stu?.subscription?.plan?.amount}` : null}
                        </h5>
                        <p className="sessions">
                            {stu?.subscription_id ? `${stu?.credit} credit` : null}
                        </p>
                    </div>
                    <div className="col actions">
                        {stu?.subscription_id ? (<><Link to={`/pricing?studentId=${stu?.id}`}><button className="button upgrade">Upgrade</button></Link>
                        <button className="button cancel" onClick={() => cancelSubscription(stu?.id)}>Cancel</button></>) : (<Link to={`/pricing?studentId=${stu?.id}`}>
                                <button className='btn btn-primary text-white w-100 py-2'>Buy subscription</button>
                            </Link>)}
                        
                    </div>
                </div>
                )
            })}
            {role === 'student' && (
                <div className="row">
                <div className="col student">
                    <h5 className="name">
                        {`${billingDetails?.first_name} ${billingDetails?.last_name}`}
                    </h5>
                    {billingDetails?.subscription_id ? <p>{billingDetails?.subscription?.plan?.name}</p> : null}
                </div>
                <div className="col plan-status">
                    <h5 className="status active">
                        {billingDetails?.subscription_id ? 'Active' : null}
                    </h5>
                    <p className="expiry">
                        {billingDetails?.subscription_id ? `Expires ${billingDetails?.subscription?.current_period_end ? moment(new Date(billingDetails?.subscription?.current_period_end * 1000)).format('DD MMM YYYY'): null}` : null}
                    </p>
                </div>
                <div className="col plan-price">
                    <h5 className="price">
                        {billingDetails?.subscription_id ? `$${billingDetails?.subscription?.plan?.amount}` : null}
                    </h5>
                    <p className="sessions">
                        {billingDetails?.subscription_id ? `${billingDetails?.credit} credit` : null}
                    </p>
                </div>
                <div className="col actions">
                    {billingDetails?.subscription_id ? (<><Link to={`/pricing?studentId=${billingDetails?.id}`}><button className="button upgrade">Upgrade</button></Link>
                    <button className="button cancel" onClick={() => cancelSubscription(billingDetails?.id)}>Cancel</button></>) : (<Link to={`/pricing`}>
                            <button className='btn btn-primary text-white w-100 py-2'>Buy subscription</button>
                        </Link>)}
                    
                </div>
            </div>
            )}
        </div>
        <div className="modal fade" id="cancelSubscription" tabindex="-1" role="dialog" aria-labelledby="cancelSubscription" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Cancel Subscription</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {
                                onSuccess ?
                                <div className="d-flex justify-content-center">
                                    <div className="text-center">
                                        <div className="animation-ctn">
                                            <div className="icon icon--order-success svg mb-5">
                                                <FiCheckCircle color="green" size="120"/>
                                            </div>
                                        </div>
                                        <h2 className="bold mb-2">Subscription Cancelled!</h2>
                                        <p className="mb-4">Your Subscription was successfully cancelled.</p>
                                    </div>
                                </div>
                                :
                                <div className="d-flex justify-content-center">
                                    <div className="text-center">
                                        <div className="animation-ctn">
                                            <div className="icon icon--order-success svg mb-5">
                                                <FiAlertOctagon color="green" size="120"/>
                                            </div>
                                        </div>
                                        <h2 className="bold mb-2">Are you sure?</h2>
                                        <p className="mb-4">Your are cancelling your current subscription. Please confirm to continue.</p>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="modal-footer">
                            {
                                !onSuccess ? 
                                <button type="button" className="btn btn-primary text-white" onClick={() =>cancelPlan(cancelId)}>Confirm</button>
                                : <Link to="/"><button type="button" className="btn btn-primary text-white" data-dismiss="modal" onClick={() => history.push('/')}>Go To Dashboard</button></Link>
                            }
                        </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default SubscriptionTable;