/* eslint-disable */
import React, { Component } from "react";
import StudentS1 from "../SignUp/StudentS1";
import StudentS2 from "../SignUp/StudentS2";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import StepperNavigation from '../StepperNavigation';
import {updateProfile} from '../../services/Profile';
import auth from "../../actions/auth";
import {toast} from "react-toastify";

class StudentProfileForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            payload: {},
            loading: false
        };
    }

    componentDidMount() {
       
    };

    getData = (data) => {
        const { step } = this.state;
        if(step === 1) {
            this.setState({
                ...this.state,
                payload: {
                    ...this.state.payload,
                    ...data
                },
                step: 2
            });
        }else if(step === 2) {
            this.setState({
                ...this.state,
                loading: true,
                payload: {
                    ...this.state.payload,
                    ...data
                }
            }, () => {
                this.updateProfile();
            });
        }
    }

    updateProfile() {
        const { payload } = this.state;
        const updatedPayload = {
            basic: {
                first_name: this.props.studentData.first_name,
                last_name: this.props.studentData.last_name,
                phone_number: payload.phone_number ,
                race: payload.race,
            },
            details: {
                // parent_id: "2",
                state: payload.state,
                address: payload.address,
                zip_code: payload.zip_code,
                disablities: payload.disablities,
                current_school: payload.current_school,
                current_grade: payload.current_grade,
                current_gpa: payload.current_gpa,
                special_needs: payload.special_needs,
                major: payload.major,
                parent_name: payload.parent_name,
                parent_phone_number: payload.parent_phone_number,
                parent_email: payload.parent_email,
                profile_picture: payload.profile_picture
            },
        }
        console.log(updatedPayload);
        updateProfile(updatedPayload, auth.check().id).then((res) => {
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    step: 3
                });
                toast.success("Profile Updated Successfully!");
            }else {
                throw new Error (Object.values(res.response.data).reduce((init, current) => (init + " " + current.toString()), ""));
            }
        }).catch((err) => {
            this.setState({
                ...this.state,
                loading: false
            });
            toast.error(err.message)
        });
    }

    render() {
        const { step, loading } = this.state;

        return (
            <div className='layout-container bg-bubble'>
                <section className={`container signup-grid ${step === 0 ? '': '--onlyform'}`}>
                    <div className='form-content'>
                        
                        <StepperNavigation step={step} />
                        {
                            (step === 1) &&
                            <StudentS1 studentData={this.props.studentData} getData={this.getData}/>
                        }
                        {
                            (step === 2) &&
                            <StudentS2 studentData={this.props.studentData} getData={this.getData} loading={loading}/>
                        }
                        {
                            step === 3 &&
                            <div className="intro-content">
                                <h3 className='text-center text-dark my-5'>Profile Updated</h3>
                                <p className="text-center text-dark w-75 mx-auto">Your Profile is updated.</p>
                                <div className='d-flex justify-content-center align-items-center my-5'>
                                    <Link to="/">
                                        <Button
                                            variant='primary'
                                            className='btn-padding--lg text-white'
                                        >
                                            Done
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        }
                    </div>
                </section>
            </div>
        );
    }
}

StudentProfileForm.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default StudentProfileForm;