import React, {Component} from 'react';
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom';
import AuthNav from './Header/AuthNav';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class AuthLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <main className="position-relative">
                <AuthNav path={this.props.location.pathname} />
                {this.props.children}
                <ToastContainer
                    position="bottom-center"
                    autoClose={3000}
                    hideProgressBar
                    transition={Zoom}
                    toastClassName="txt-16--body text-white px-3"
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover={false}
                />
            </main>
        );
    }
}

AuthLayout.propTypes = {
  children: PropTypes.node
};

export default withRouter(AuthLayout);

