import React from 'react';
import { Link } from 'react-router-dom';

const TutorCredits = ({students}) => {
    return (
        <div className="tutor-credits">
            <h5 className="title">Tutorial Hours Remaining</h5>
            <div className="student-container">
                {students.map(student => {
                    let className = '';
                    console.log(student)
                    switch(student?.subscription?.plan?.name) {
                        case 'Silver': className='silver';
                                        break;
                        case 'Charcoal Plan': className='charcol';
                                        break;
                        case 'Ivory Plan': className='ivory';
                                        break;
                        case 'Turquoise Plan': className='turquoise';
                                            break;
                        case 'Teal': className='teal';
                                        break;
                        default: className='';
                                    break;
                    }
                    return (
                        <div className="student" style={{justifyContent: student?.subscription_id ? 'flex-start' : 'space-between'}}>
                            <div className="name">
                                {`${student?.first_name} ${student?.last_name}`}
                            </div>
                            {student?.subscription_id ? (
                                <>
                                    <div className="credit-left">
                                        {student?.credit}
                                    </div>
                                    <div className={`button ${className}`}>{student?.subscription?.plan?.name}</div>
                                </>
                            ) : (
                                <Link to={`/pricing?studentId=${student?.id}`}><button type="button" className="button subscribe">Subscribe</button></Link>
                            )}
                            
                        </div>
                    )
                })}
                {/* <div className="student">
                    <div className="name">
                        Jenifer
                    </div>
                    <div className="credit-left">
                        32
                    </div>
                    <div className="silver button">Silver</div>
                </div> */}
                {/* <div className="student">
                    <div className="name">
                        Brandon
                    </div>
                    <div className="credit-left">
                        25
                    </div>
                    <div className="silver button">Silver</div>
                </div>
                <div className="student">
                    <div className="name">
                        Christie
                    </div>
                    <div className="credit-left">
                        57
                    </div>
                    <div className="charcol button">Charcol</div>
                </div>
                <div className="student">
                    <div className="name">
                        Colby
                    </div>
                    <div className="credit-left">
                        14
                    </div>
                    <div className="ivory button">Ivory</div>
                </div>
                <div className="student">
                    <div className="name">
                        Durant
                    </div>
                    <div className="credit-left">
                        19
                    </div>
                    <div className="turquoise button">Turquoise</div>
                </div>
                <div className="student">
                    <div className="name">
                        Sheila
                    </div>
                    <div className="credit-left">
                        44
                    </div>
                    <div className="teal button">Teal</div>
                </div> */}
            </div>
        </div>
    )
}

export default TutorCredits;