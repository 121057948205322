import React, {Component} from 'react';
import _ from "lodash";
import {toast} from "react-toastify";
import auth from "../../actions/auth";
import {get, availability} from "../../services/Profile";

class ParentStudentSecurity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            day: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            time: ['00:00:00', '01:00:00', '02:00:00', '03:00:00', '04:00:00', '05:00:00', '06:00:00', '07:00:00', '08:00:00', '09:00:00', '10:00:00', '11:00:00', '12:00:00', '13:00:00', '14:00:00', '15:00:00', '16:00:00', '17:00:00', '18:00:00', '19:00:00', '20:00:00', '21:00:00', '22:00:00', '23:00:00', '24:00:00'],
            day_time_mapping: [],
            //validation
            isValid: false,
            loading: false
        }
    }

    componentDidMount() {
        this.getProfile();
    }

    getProfile = () => {
        get(auth.check().id).then((res) => {
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    day_time_mapping: res.data.data.day_time_mapping
                });
            }else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            toast.error("Something went wrong!");
        })
    }

    onChange = (all=false, e) => {
        e.persist();
        const { day, time } = this.state;
        if(e.target && e.target.type === "checkbox") {
            if(all) {
                if (e.target.checked) {
                    time.forEach((val, key) => {
                        this.state[e.target.name].push({
                            day: day[e.target.value],
                            start_time: val
                        })
                    });
                    this.setState({
                        ...this.state,
                        [e.target.name]: this.state[e.target.name],
                        isValid: false
                    });
                }else {
                    let data = _.reject(this.state[e.target.name], ['day', day[e.target.value]]);
                    this.setState({
                        ...this.state,
                        [e.target.name]: data,
                        isValid: false
                    });
                }
            }else {
                if (e.target.checked) {
                    let index = e.target.value.split("-");
                    this.state[e.target.name].push({
                        day: day[index[1]],
                        start_time: time[index[0]]
                    });
                    this.setState({
                        ...this.state,
                        [e.target.name]: this.state[e.target.name],
                        isValid: false
                    });
                }else {
                    let index = e.target.value.split("-");
                    let i = _.findIndex(this.state[e.target.name], {day: day[index[1]], start_time: time[index[0]]});
                    this.state[e.target.name].splice(i, 1);
                    this.setState({
                        ...this.state,
                        [e.target.name]: this.state[e.target.name],
                        isValid: false
                    });
                }
            }
        }
    };

    update = () => {
        const { day_time_mapping } = this.state;
        let input = {
            day_time_mapping: day_time_mapping,
        }
        this.setState({
            ...this.state,
            isValid: true,
            loading: true
        }, () => {
            if(day_time_mapping.length > 0) {
                availability(auth.check().id, input).then((res) => {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    if(res.status === 200) {
                        toast.success(res.data.message);
                    }else {
                        toast.error(res.data.message);
                    }
                }).catch((err) => {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    toast.error("Something went wrong!");
                })
            }else {
                this.setState({
                    ...this.state,
                    loading: false
                })
                toast.error("Select min one slot before update!");
            }
        })
    }

    render() {
        const { day, time, day_time_mapping, loading, isValid } = this.state;
        
        return (
            <div>
                <h5 className='bold serif text-dark mb-2'>Availability</h5>
                <p className='mb-5'>Tick times you are available to tutor.</p>
                <div className="availability-wrapper">
                <section className="availability-grid">
                    <div className="day-row">
                    <div className="day">MON</div>
                    <div className="day">TUE</div>
                    <div className="day">WED</div>
                    <div className="day">THUR</div>
                    <div className="day">FRI</div>
                    <div className="day">SAT</div>
                    <div className="day">SUN</div>
                    </div>
                    <div className="time-col">
                    <div className="time"></div>
                    <div className="time w-75 text-teal">Select entire column</div>
                    <div className="time">12:00AM</div>
                    <div className="time">1:00AM</div>
                    <div className="time">2:00AM</div>
                    <div className="time">3:00AM</div>
                    <div className="time">4:00AM</div>
                    <div className="time">5:00AM</div>
                    <div className="time">6:00AM</div>
                    <div className="time">7:00AM</div>
                    <div className="time">8:00AM</div>
                    <div className="time">9:00AM</div>
                    <div className="time">10:00AM</div>
                    <div className="time">11:00AM</div>
                    <div className="time">12:00PM</div>
                    <div className="time">1:00PM</div>
                    <div className="time">2:00PM</div>
                    <div className="time">3:00PM</div>
                    <div className="time">4:00PM</div>
                    <div className="time">5:00PM</div>
                    <div className="time">6:00PM</div>
                    <div className="time">7:00PM</div>
                    <div className="time">8:00PM</div>
                    <div className="time">9:00PM</div>
                    <div className="time">10:00PM</div>
                    <div className="time">11:00PM</div>
                    </div>
                    <div className="checkbox-grid">
                    {
                        day.map((val, i) => (
                        <div className="custom-control custom-checkbox" key={i}>
                            <input type="checkbox" className="custom-control-input" id={`check_all_${i}`} name="day_time_mapping"
                            checked={_.filter(day_time_mapping, ['day', val]).length === 25}
                            value={i} onChange={this.onChange.bind(this, true)}/>
                            <label className={`custom-control-label checkbox-2 ${(isValid && (day_time_mapping.length > 0 ? '' : 'error is-invalid')).toString()}`} htmlFor={`check_all_${i}`}></label>
                        </div>
                        ))
                    }
                    {
                        time.map((time, index) => (
                            day.map((day, key) => (
                                <div className="custom-control custom-checkbox" key={`${index}-${key}`}>
                                    <input type="checkbox" className="custom-control-input" id={`check_${index}-${key}`}
                                    checked={_.findIndex(day_time_mapping, {day: day, start_time: time}) > -1}
                                    name="day_time_mapping" value={`${index}-${key}`} onChange={this.onChange.bind(this, false)}/>
                                    <label className={`custom-control-label checkbox-2 ${(isValid && (day_time_mapping.length > 0 ? '' : 'error is-invalid')).toString()}`} htmlFor={`check_${index}-${key}`}></label>
                                </div>
                            ))
                        ))
                    }
                    </div>
                    <div className="ingrid-position d-flex justify-content-end mt-5">
                    <button type="submit" className="btn btn-primary btn-padding--md text-white"
                        onClick={this.update}
                        disabled={loading}
                    >Update</button>
                    </div>
                </section>
                </div>
            </div>
        );
    }
}

export default ParentStudentSecurity;