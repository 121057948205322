/* eslint-disable */
import React, {Component} from 'react';
import _ from "lodash";
import { FaAngleDown } from "react-icons/fa";
import moment from "moment";

class NoticeBoard extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {

        return (
            <div className="book-tutor-block" style={{minHeight: 'unset', height: 'auto'}}>
                <div className="p-3">
                    <h6 className='text-teal bold'>Notice Board</h6>
                </div>
                <div className="card-body position-relative">
                    <div className="timeline">
                        {
                            this.props?.notification?.length > 0 && this.props.notification.map((notify, i) => (
                            <div className={`timeline-event ${i == 0 ? 'active':''}`} key={i}>
                                <div className="timeline-event__image"></div>
                                <div className="timeline-event__date">
                                    <div className="d-flex justify-content-between">
                                        {
                                            notify.is_rescheduled ? 
                                            <h6 className='bold'>Rescheduled Session</h6>
                                            :
                                            <h6 className='bold'>New Request</h6>
                                        }
                                        <span className='timeline-time'>{moment(notify.created_at).fromNow()}</span>
                                    </div>
                                    {
                                       notify.is_rescheduled ? 
                                       <p className="timeline-data">
                                            This session has been rescheduled to {moment(notify.schedule_date).format("LL")}.
                                        </p>
                                        :
                                        <p className="timeline-data">
                                            New session request on {moment(notify.schedule_date).format("LL")}.
                                        </p> 
                                    }
                                </div>
                            </div>
                            ))
                        }
                        {
                            this.props?.notification?.length === 0 &&
                            <div className="d-flex justify-content-center align-items-center mb-5">
                                <p className=" font-weight-bold">No Notification!</p>
                            </div>
                        }
                    </div>
                </div>
                <button className="btn btn-link text-teal position-absolute" onClick={() => this.props.history.push("/notification")} style={{ bottom: 20, left: 20, fontSize: "16px", lineHeight: 1, opacity: .9 }}>
                    Go to notifications&nbsp;
                    <FaAngleDown />
                </button>
            </div>
        );
    }
}

export default NoticeBoard;