/* eslint-disable */
import React, { Component } from 'react'
import TutorLayout from "../components/TutorLayout";
import TutorS1 from "../components/SignUp/TutorS1";
import TutorS2 from "../components/SignUp/TutorS2";
import TutorS3 from "../components/SignUp/TutorS3";
import TutorS4 from "../components/SignUp/TutorS4";
import TutorS5 from "../components/SignUp/TutorS5";
import TutorS6 from "../components/SignUp/TutorS6";
import { FaAngleLeft } from "react-icons/fa";
import {toast} from "react-toastify";
import _ from "lodash";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import { Button } from "react-bootstrap";
import {updateProfile} from '../services/Profile';
import auth from "../actions/auth";
import {get} from '../services/Profile';

export default class TutorProfileForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stepTitles: ['Residential Information', 'Academic & Work Qualification', 'Subjects', 'Additional Details', 'Upload Details', 'Confirmation'],
            step: 1,
            payload: {},
            //validation
            loading: false,
            tutorData: null,
            role: null,
        };
    }

    componentDidMount() {
        this.getProfile();
    }

    getProfile = () => {
        get(auth.check().id).then((res) => {
            if(res.status === 200) {
                this.setState({
					...this.state,
					tutorData: res.data.data,
				});
            }else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            toast.error("Something went wrong!");
        })
    }

    onChange = (e) => {
        e.persist();
        if(e.target && e.target.type === "checkbox") {
            if (e.target.checked) {
                this.state[e.target.name].push(e.target.value);
                this.setState({
                    ...this.state,
                    [e.target.name]: this.state[e.target.name],
                    isValid: false
                });
            }else {
                let i = _.indexOf(this.state[e.target.name], e.target.value.toString());
                this.state[e.target.name].splice(i, 1);
                this.setState({
                    ...this.state,
                    [e.target.name]: this.state[e.target.name],
                    isValid: false
                });
            }
        }else {
            this.setState({
                ...this.state,
                [e.target.name]: e.target.value,
                isValid: false
            });
        }
    };

    getData = (data) => {
        const { step } = this.state;
        if(step === 1) {
            this.setState({
                ...this.state,
                payload: {
                    ...this.state.payload,
                    ...data
                },
                step: 2
            });
        }else if(step === 2) {
            this.setState({
                ...this.state,
                payload: {
                    ...this.state.payload,
                    ...data
                },
                step: 3
            });
        }else if(step === 3) {
            this.setState({
                ...this.state,
                payload: {
                    ...this.state.payload,
                    ...data
                },
                step: 4
            });
        }else if(step === 4) {
            this.setState({
                ...this.state,
                payload: {
                    ...this.state.payload,
                    additional_details: data
                },
                step: 5
            });
        }else if(step === 5) {
            this.setState({
                ...this.state,
                loading: true,
                payload: {
                    ...this.state.payload,
                    ...data
                }
            }, () => {
                this.updateProfile();
            });
        }
    }

    stepBack = () => {
        this.setState({
            ...this.state,
            step: this.state.step - 1
        });
    }

    updateProfile() {
        const { payload } = this.state;
        const updatedPayload = {
            basic: {
                first_name: this.state.tutorData.first_name,
                last_name: this.state.tutorData.last_name,
                phone_number: payload.phone_number,
                race: payload.race,
            },
            details: {
                state: payload.state,
                city: payload.city,
                address: payload.address,
                zip_code: payload.zip_code,
                highest_level_education: payload.highest_level_education,
                gpa: payload.gpa,
                years_of_tutoring_exp: payload.years_of_tutoring_exp,
                how_do_you_hear: payload.how_do_you_hear,
                occupation: payload.occupation,
                resume: payload.resume,
                profile_picture: payload.profile_picture,
                certificates: payload.certificates,
                cover_letter: payload.cover_letter,
                gender: payload.gender,
                additional_details: {
                    work_in_usa: payload.additional_details.work_in_usa === 'Yes' ? 1 : 0,
                    criminal_records: payload.additional_details.criminal_records === 'Yes' ? 1 : 0,
                    student_with_disability: payload.additional_details.student_with_disability === 'Yes' ? 1 : 0,
                    reliable_transportation: payload.additional_details.reliable_transportation === 'Yes' ? 1 : 0,
                    group_tutoring: payload.additional_details.group_tutoring === 'Yes' ? 1 : 0,
                    home_school_help: payload.additional_details.home_school_help === 'Yes' ? 1 : 0,
                    required_hours: payload.additional_details.required_hours,
                    languages: payload.additional_details.languages,
                    other_informations: payload.additional_details.other_informations
                }
            },
        }
        console.log(updatedPayload);
        updateProfile(updatedPayload, auth.check().id).then((res) => {
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    step: 6
                });
                toast.success("Profile Updated Successfully!");
            }else {
                throw new Error (Object.values(res.response.data).reduce((init, current) => (init + " " + current.toString()), ""));
            }
        }).catch((err) => {
            this.setState({
                ...this.state,
                loading: false
            });
            toast.error(err.message)
        });
    }

    render() {
        const { step, stepTitles, payload, loading } = this.state;
        
        return (
            <TutorLayout>
                <div className='layout-container profile-form'>
                    <div className='tutor-signup-step-nav'>
                        <div className="d-flex justify-content-around align-items-center">
                            <h6 className='text-dark'>{stepTitles[step - 1]}</h6>
                            <div className="hide-on--mobile  w-25">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6>{step} of 6</h6>
                                    {step > 1 && (<h6 className="pointer" onClick={this.stepBack}><FaAngleLeft /> Go Back</h6>)}
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="hide-on--desktop">
                            <div className="d-flex justify-content-between align-items-center hide-on--desktop">
                                <h6>{step} of 6</h6>
                                <h6 className="pointer" onClick={this.stepBack}><FaAngleLeft /> Go Back</h6>
                            </div>
                        </div>
                    </div>
                    <section className={`container signup-grid --onlyform`}>
                        <div className='form-content'>
                            {
                                step === 1 &&
                                <TutorS2 getData={this.getData} payload={payload}/>
                            }
                            {
                                step === 2 &&
                                <TutorS3 getData={this.getData} payload={payload}/>
                            }
                            {
                                step === 3 &&
                                <TutorS4 getData={this.getData} payload={payload}/>
                            }
                            {
                                step === 4 &&
                                <TutorS5 getData={this.getData} payload={payload}/>
                            }
                            {
                                step === 5 &&
                                <TutorS6 getData={this.getData} loading={loading} payload={payload}/>
                            }
                            {
                                step === 6 &&
                                <div className="intro-content">
                                    <h3 className='text-center text-dark my-5'>Profile Updated</h3>
                                    <p className="text-center text-dark w-75 mx-auto">Your Profile is updated.</p>
                                    <div className='d-flex justify-content-center align-items-center my-5'>
                                        <Link to="/">
                                            <Button
                                                variant='primary'
                                                className='btn-padding--lg text-white'
                                            >
                                                Done
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            }
                        </div>
                    </section>
                </div>
            </TutorLayout>
        )
    }
}

TutorProfileForm.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};
