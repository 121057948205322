/* eslint-disable */
import React, {Component} from 'react';
import _ from "lodash";
import Calendar from 'react-calendar';
import validation from "../../actions/validation";
import moment from "moment";
import {toast} from "react-toastify";
import {get} from "../../services/Profile";
import {reSchedule} from "../../services/Tutor";
import $ from "jquery";

const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

class Reschedule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            day_time_mapping: [],
            start_time: "",
            end_time: "",
            rescheduled_reason: "",
            profileLoading: true,

            //validation
            isValid: false,
            loading: false,
        }
    }

    componentDidUpdate(prevProp) {
      if(this.props.id && this.props.tutorId && this.props.id !== prevProp.id) {
        this.setState({
          ...this.state,
          day_time_mapping: [],
          start_time: "",
          end_time: "",
          rescheduled_reason: "",
          isValid: false,
          loading: false,
        }, () => {
          if(this.props.dayTimeMapping) {
            this.setState({
              ...this.state,
              day_time_mapping: this.props.dayTimeMapping,
              profileLoading: false
          });
          }else {
            this.getProfile();
          }
        });
      }
    }

    getProfile = () => {
      get(this.props.tutorId).then((res) => {
          if(res.status === 200) {
              this.setState({
                  ...this.state,
                  day_time_mapping: res.data.data.day_time_mapping,
                  profileLoading: false
              });
          }else {
              toast.error("Something went wrong!");
          }
      }).catch((err) => {
          toast.error("Something went wrong!");
      })
    }

    tileDisabled = (e) => {
      const { day_time_mapping } = this.state;
      let uniqDay = _.uniq(day_time_mapping.map((val) => val.day));
      return _.indexOf(uniqDay, days[moment(e.date).day()]) === -1;
    }

    onChange = (e) => {
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value,
        isValid: false
      });
    }

    onSubmit = () => {
      const { date, start_time, end_time, rescheduled_reason } = this.state;
      this.setState({
        isValid: true,
        loading: true
      }, () => {
        if(validation.text(start_time)
          && validation.text(end_time)
          && validation.text(rescheduled_reason)) {
            let input = {
              id: this.props.id,
              schedule_date: moment(date).format('YYYY-MM-DD'),
              start_time: start_time,
              end_time: end_time,
              duration: moment.duration(moment(end_time, ["HH.mm"]).diff(moment(start_time, ["HH.mm"]))),
              is_rescheduled: 1,
              rescheduled_reason: rescheduled_reason,
              approved: 0
            };
            reSchedule(input).then((res) => {
              this.setState({
                ...this.state,
                loading: false
              });
              if(res.status === 200) {
                  $("#reschedule").modal('hide');
                  toast.success("Reschedule Successful!");
                  this.props.next();
              }else {
                  toast.error("Something went wrong!");
              }
            }).catch((err) => {
                this.setState({
                  ...this.state,
                  loading: false
                });
                toast.error("Something went wrong!");
            })
          }else {
            this.setState({
              ...this.state,
              loading: false
            })
            toast.error("Validation Error!");
          }
      })
    }

    render() {
        const { isValid, loading, date, day_time_mapping, start_time, end_time, rescheduled_reason } = this.state;

        return (
            <div className="modal media-modal" id="reschedule">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">

                  <div className="modal-header">
                    <h4 className="modal-title">Reschedule</h4>
                    <button type="button" className="close" data-dismiss="modal" onClick={this.props.close}>&times;</button>
                  </div>

                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="start_time">Pick a date:</label>
                        <Calendar 
                          className='cal-schedule' 
                          calendarType={"US"}
                          onChange={(date) => this.setState({ ...this.state, date })}
                          value={this.state.date}
                          tileDisabled={this.tileDisabled}
                          minDate={new Date()}
                          // maxDate={new Date("2020-07-29")}
                        />
                      </div>
                      <div className="col-md-6"> 
                        <div className="row my-3">
                          <div className="col-6">
                            <div className="form-group mb-0">
                              <label htmlFor="start_time">Start times:</label>
                              <select name="start_time" id="start_time" 
                              className={`custom-select ${(isValid && (validation.text(start_time) ? '' : 'error is-invalid')).toString()}`}
                              value={start_time}
                              onChange={this.onChange.bind(this)}>
                                <option value="">Select Time</option>
                                {
                                  _.filter(day_time_mapping, ['day', days[moment(date).day()]]).length > 0 && _.filter(day_time_mapping, ['day', days[moment(date).day()]]).map((time, key) => (
                                    <option value={time.start_time} key={key}>{moment(time.start_time, ["HH.mm"]).format("hh:mm a")}</option>
                                  ))
                                }
                              </select>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group mb-0">
                              <label htmlFor="end_time">End times:</label>
                              <select name="end_time" id="end_time"
                              className={`custom-select ${(isValid && (validation.text(end_time) ? '' : 'error is-invalid')).toString()}`}
                              value={end_time}
                              onChange={this.onChange.bind(this)}>
                                <option value="">Select Time</option>
                                {
                                  _.filter(day_time_mapping, ['day', days[moment(date).day()]]).length > 0 && _.filter(day_time_mapping, ['day', days[moment(date).day()]]).map((time, key) => (
                                    <option value={time.start_time} key={key}>{moment(time.start_time, ["HH.mm"]).format("hh:mm a")}</option>
                                  ))
                                }
                              </select>
                            </div>
                          </div>
                          <div className="col-12 mt-2">
                            <div className="form-group">
                              <label htmlFor="rescheduled_reason">Reason (Required)</label>
                              <textarea className={`form-control ${(isValid && (validation.text(rescheduled_reason) ? '' : 'error is-invalid')).toString()}`} name="rescheduled_reason" id="rescheduled_reason" rows="4" value={rescheduled_reason} onChange={this.onChange.bind(this)}></textarea>
                            </div>
                          </div>
                        </div>
                        <button className="btn btn-primary text-white float-right" onClick={this.onSubmit} disabled={loading}>Reschedule</button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
        );
    }
}

export default Reschedule;