/* eslint-disable */
import React, { Component } from "react";
import TutorLayout from "../components/TutorLayout";
import { FaTrashAlt, FaUserAlt, FaMapMarkerAlt, FaRegCalendarAlt, FaRegClock } from "react-icons/fa";
import NoticeBoard from '../components/Notification/NoticeBoard';
import RescheduleModal from "../components/Modal/Reschedule";
import {get} from "../services/Profile";
import { startSession, endSession } from "../services/Common";
import Calendar from 'react-calendar';
import auth from "../actions/auth";
import validation from "../actions/validation";
import guard from "../actions/guard";
import {toast} from "react-toastify";
import moment from "moment";
import { withRouter } from "react-router-dom";
import _ from  "lodash";
import $ from "jquery";

class Session extends Component {

	constructor(props) {
        super(props);
        this.state = {
            pending: [],
            schedules: [],
            scheduleLoading: true,
            selectedId: null,
            selectedTutorId: null,
            dayTimeMapping: [],
            remainingTime: 0,
            counter: null,
            schedule: {}
        }
    }

    componentDidMount() {
        guard.check(() => {
            if (guard.role() === "tutor") {
                this.getProfile();
            }else {
                this.props.history.push("/dashboard");
            }
        });
    }

    getProfile = () => {
        get(auth.check().id).then((res) => {
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    pending: _.filter(res.data.data.schedules, {progression_status: 0, approved: 0, rejected: 0}),
                    schedules: _.filter(res.data.data.schedules, {approved: 1, is_completed: 0}),
                    dayTimeMapping: res.data.data.day_time_mapping,
                    schedule: _.find(res.data.data.schedules, {progression_status: 1, is_completed: 0}),
                    scheduleLoading: false
                }, () => {
                    if(this.state.schedule) {
                        if(!this.state.schedule.online) {
                            if(moment(moment(this.state.schedule.schedule_date).format("YYYY-MM-DD")).isSame(moment().format("YYYY-MM-DD"))
                                && moment(moment(`${this.state.schedule.schedule_date} ${this.state.schedule.end_time}`).format("YYYY-MM-DD HH:mm:ss")).isSameOrAfter(moment().format("YYYY-MM-DD HH:mm:ss"))
                                && moment.duration(moment(`${this.state.schedule.schedule_date} ${this.state.schedule.end_time}`, ["YYYY-MM-DD HH.mm"]).diff(moment(moment().format("YYYY-MM-DD HH:mm:ss"), ["YYYY-MM-DD HH.mm"]))).asSeconds() > 0
                            ) {
                                this.setState({
                                    ...this.state,
                                    remainingTime: moment.duration(moment(`${this.state.schedule.schedule_date} ${this.state.schedule.end_time}`, ["YYYY-MM-DD HH.mm"]).diff(moment(moment().format("YYYY-MM-DD HH:mm:ss"), ["YYYY-MM-DD HH.mm"]))).asSeconds()
                                }, () => {
                                    this.timer();
                                });
                            }else {
                                this.end(this.state.schedule.id);
                            }
                        }
                    }
                });
            }else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            toast.error("Something went wrong!");
        })
    }

    rescheduleClose = () => {
        this.setState({
            ...this.state,
            selectedId: null,
            selectedTutorId: null
        }, () => {
            $("#reschedule").modal('hide');
        });
    }

    rescheduleOpen = (id, tutorId) => {
        this.setState({
            ...this.state,
            selectedId: id,
            selectedTutorId: tutorId
        }, () => {
            $("#reschedule").modal();
        });
    }

    start = (id, type) => {
        if(this.state.schedule) {
            toast.info("One session is active! Please end the active session first.");
        }else {
            if(type === "online") {
                this.props.history.push("/room/" + btoa(id))
            }else {
                let input = {
                    "schedule_id": id,
                    "start_time": moment().format('YYYY-MM-DD HH:mm:ss')
                }
                startSession(input).then((res) => {
                    if(res.status === 201) {
                        toast.success("Starting your session...");
                        this.setState({
                            ...this.state,
                            schedule: res.data.schedule,
                            remainingTime: moment.duration(moment(res.data.schedule.end_time, ["HH.mm"]).diff(moment(moment().format("HH:mm:ss"), ["HH.mm"]))).asSeconds()
                        }, () => {
                            this.getProfile();
                        });
                    }else {
                        toast.error("Something went wrong!");
                    }
                }).catch((err) => {
                    toast.error("Something went wrong!");
                });
            }
        }
    }

    end = (id) => {
        let input = {
            "id": _.find(this.state.schedules, ['id', id]).schedule_session.id,
            "schedule_id": id,
            "end_time":  moment().format('YYYY-MM-DD HH:mm:ss')
        }
        endSession(input).then((res) => {
            if(res.status === 200) {
                this.getProfile();
                toast.success("Session completed successful.");
            }else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            toast.error("Something went wrong!");
        });
    }

    timer = () => {
        let counter = setInterval(() => {
            if(this.state.remainingTime > 0) {
                this.setState({
                    ...this.state,
                    remainingTime: this.state.remainingTime - 1
                });
            }else {
                clearInterval(this.state.counter);
                this.end(this.state.schedule.id);
            }
        }, 1000);
        this.setState({
            ...this.state,
            counter
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.counter);
    }

	render() {
		const { scheduleLoading, schedules, pending, selectedId, selectedTutorId, dayTimeMapping, remainingTime } = this.state;
		
		return (
			<TutorLayout>
				<div className="tutor-auth-container">
                    <div class="tutoring-wrap">
                        <div class="row">
                            <div class="col-md-9">
                                <div class="tutoring-list">
                                    <div class="tutoring-date">{moment().format("LL")}</div>
                                    {
                                        !scheduleLoading && schedules.length > 0 && schedules.map((list, i) => (
                                            <div class="tutoring-list-box" key={i}>
                                                <div class="tutoring-name">
                                                    <div class="t-name">{list.studentdetails.email}</div>
                                                    <div class="s-name text-capitalize">Student: {list.studentdetails.first_name} {list.studentdetails.last_name} </div>
                                                </div>
                                                <div class="tutoring-pic">
                                                    {
                                                        list.tutordetails.details.profile_picture ? 
                                                        <img src={list.tutordetails.details.profile_picture} alt="tutor-avatar" style={{width: 50, height: 50, borderRadius: '50%'}}/>
                                                        :
                                                        <img src="/images/Oval.png" alt="tutor-avatar"/>
                                                    }
                                                </div>
                                                <div class="tutoring-date-time">
                                                    <div class="t-online"><span class="active"></span>{list.online ? 'Online' : 'In-person'}</div>
                                                    <div class="date-time">
                                                        <span className="text-dark"><FaRegCalendarAlt /> {moment(list.schedule_date).format('LL')}</span>
                                                        <span className="text-dark"><FaRegClock /> {`${moment(list.start_time, ["HH.mm"]).format('LT')} - ${moment(list.end_time, ["HH.mm"]).format('LT')}`}</span>
                                                    </div>
                                                </div>
                                                {
                                                    list.online ?
                                                    <>
                                                        {
                                                            list.progression_status === 0 ?
                                                            <div class="t-bnt">
                                                                <button className="btn btn-primary mr-2" onClick={this.start.bind(this, list.id, "online")}>Start</button>
                                                                <button className="btn btn-outline-primary" onClick={this.rescheduleOpen.bind(this, list.id, list.tutordetails.id)}>Reschedule</button>
                                                            </div>
                                                            :
                                                            <div class="t-bnt">
                                                                <button className="btn btn-secondary mr-2" onClick={() => this.props.history.push("/room/" + btoa(list.id))}>In Progres</button>
                                                                <button className="btn btn-link" onClick={this.end.bind(this, list.id)}>Done</button>
                                                            </div>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            list.progression_status === 0 ?
                                                            <div class="t-bnt">
                                                                <button className="btn btn-primary mr-2" onClick={this.start.bind(this, list.id, "in-person")}>Start Timer</button>
                                                                <button className="btn btn-outline-primary" onClick={this.rescheduleOpen.bind(this, list.id, list.tutordetails.id)}>Reschedule</button>
                                                            </div>
                                                            :
                                                            <div class="t-bnt">
                                                                <button className="btn btn-link" onClick={this.end.bind(this, list.id)}>Done</button>
                                                                <div>{validation.secondsToHms(remainingTime, ":")}</div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        ))
                                    }
                                    {
                                        !scheduleLoading && schedules.length === 0 &&
                                        <div className="d-flex justify-content-center align-items-center">
                                            <p className=" font-weight-bold">No Records!</p>
                                        </div>
                                    }
                                    {
                                        scheduleLoading &&
                                        <div className="d-flex justify-content-center align-items-center">
                                            <p className=" font-weight-bold">Loading...</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div class="col-md-3">
                                <section className="right-section">
                                    <Calendar className='cal-sidebar' />
                                    <NoticeBoard notification={pending} history={this.props.history}/>
                                </section>
                            </div>
                        </div>
                    </div> 
                    
                </div>

                <RescheduleModal 
                    id={selectedId} 
                    tutorId={selectedTutorId} 
                    dayTimeMapping={dayTimeMapping} 
                    close={this.rescheduleClose} 
                    next={this.getProfile}
                />

			</TutorLayout>
		);
	}
}

export default withRouter(Session);
