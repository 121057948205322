/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types';
import BlankAuthLayout from '../components/BlankAuthLayout';
import _ from "lodash";
import guard from "../actions/guard";
import { allPlans, upgradeSubscription, upgradeSubscriptionParent,subscriptionDetails, subscriptionDetailsParent } from "../services/Payment";
import { withRouter } from 'react-router-dom';
import {toast} from "react-toastify";
import { FiAlertOctagon, FiCheckCircle } from 'react-icons/fi';
import auth from "../actions/auth";
import $ from "jquery";
import { Link } from 'react-router-dom';
import {useQuery} from '../hooks';
import './Pricing.css';

class Pricing extends Component {

  constructor(props) {
    super(props);
    const {location} = props;
    const query = new URLSearchParams(location.search);
    const studentId = query.get("studentId");
    console.log(studentId);
    this.state = {
        plans: {},
        plansLoading: true,
        onSuccess: false,
        loading: false,
        planId: null,
        studentId: studentId,
        students: null,
        billingDetailsLoading: true,
        billingDetails: null,
        role: null,
        selectedPlanType: 'basic'
    };
  }

  componentDidMount() {
    guard.check(() => {
        if (guard.role() === "tutor") {
            this.props.history.push("/dashboard");
        }else {
            this.getAllPlans();
            if (guard.role() === "parent") {
                this.setState({
                    ...this.state,
                    role: 'parent'
                })
                this.getDetailsParent();
            } else if(guard.role() === "student") {
                this.setState({
                    ...this.state,
                    role: 'student'
                })
                this.getDetails();
            }
        }
    });
  }

  getDetails = () => {
    subscriptionDetails().then((res) => {
        console.log(res)
        if(res.status === 200) {
            this.setState({
                ...this.state,
                billingDetails: res.data.data,
                billingDetailsLoading: false
            });
        }else {
            this.setState({
                ...this.state,
                // billingDetailsLoading: false
            });
            toast.error("Something went wrong!");
        }
    }).catch((err) => {
        this.setState({
            ...this.state,
            // billingDetailsLoading: false
        });
        toast.error("Something went wrong!");
    })
}

getDetailsParent = () => {
    subscriptionDetailsParent().then((res) => {
        console.log('parent',res)
        if(res.status === 200) {
            this.setState({
                ...this.state,
                students: res.data.data,
                billingDetailsLoading: false
            });
        }else {
            this.setState({
                ...this.state,
                // billingDetailsLoading: false
            });
            toast.error("Something went wrong!");
        }
    }).catch((err) => {
        this.setState({
            ...this.state,
            // billingDetailsLoading: false
        });
        toast.error("Something went wrong!");
    })
}

    getAllPlans = () => {
        allPlans().then((res) => {
            if(res.status === 200) {
                console.log('plans', res.data.data.data);
                const plans = {basic: [], pro: []};
                res.data.data.data.forEach(plan => {
                    if(plan.product.metadata.plan_type === 'pro') {
                        plans.pro.push(plan);
                    } else {
                        plans.basic.push(plan);
                    }
                })
                this.setState({
                    ...this.state,
                    plans: plans,
                    plansLoading: false
                });
            }else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            toast.error("Something went wrong!");
        })
    }

    paymentGo = (id) => {
        this.setState({
            ...this.state,
            planId: id
        });
        if(this.state.role === 'parent') {
            const stu = this.state.students.find(s => +s.id === +this.state.studentId)
            if(stu.subscription_plan_id) {
                $("#changeSubscription").modal();
            }else {
                this.props.history.push("/payment/"+ btoa(id) + "?studentId="+this.state.studentId);
            }
        } else if(this.state.role === 'student') {
            if(this.state.billingDetails.subscription_plan_id) {
                $("#changeSubscription").modal();
            }else {
                this.props.history.push("/payment/"+ btoa(id));
            }
        }
        
    }

    upgrade = () => {
        this.setState({
            ...this.state,
            onSuccess: false,
            loading: true
        }, () => {
            let input = {
                "plan_id": this.state.planId
            };
            if(this.state.role === 'parent') {
                input.student_id = this.state.studentId;
                upgradeSubscriptionParent(input).then((res) => {
                    if(res.status === 200) {
                        this.setState({
                            ...this.state,
                            onSuccess: true,
                            loading: false
                        });
                        auth.update("subscription_plan_id", res.data.data.subscription_plan_id);
                        auth.update("subscription_id", res.data.data.subscription_id);
                        auth.update("card_id", res.data.data.card_id);
                    }else {
                        toast.error("Something went wrong!");
                    }
                }).catch((err) => {
                    toast.error("Something went wrong!");
                })
            } else {
                upgradeSubscription(input).then((res) => {
                    if(res.status === 200) {
                        this.setState({
                            ...this.state,
                            onSuccess: true,
                            loading: false
                        });
                        auth.update("subscription_plan_id", res.data.data.subscription_plan_id);
                        auth.update("subscription_id", res.data.data.subscription_id);
                        auth.update("card_id", res.data.data.card_id);
                    }else {
                        toast.error("Something went wrong!");
                    }
                }).catch((err) => {
                    toast.error("Something went wrong!");
                })
            }

        });
    }

getClassName(planeName) {
    switch(planeName) {
        case 'Charcoal Plan': 
            return 'charcoal';
        case 'Ivory Plan':
            return 'ivory';
        case 'Turquoise Plan':
            return 'turquoise';
        default :
            return '';
    }
}

  render() {
    const { plans, plansLoading, onSuccess, loading, billingDetailsLoading } = this.state;
    
    return (
        <BlankAuthLayout>
        <div className="pricing-page">
                <div className="header">
                    <div className="center-info">
                        <p>TUTORING SUBSCRIPTION PLANS</p>
                        <h2>Pricing</h2>
                    </div>
                </div>
                <div className="main-body">
                    <div className="tabs">
                        <div className="tab-header">
                            <div className={`tab ${this.state.selectedPlanType === 'basic' ? 'active' : ''}`} onClick={() => {
                                this.setState({
                                    ...this.state,
                                    selectedPlanType: 'basic'
                                })
                            }}>
                                <strong>Basic Plans</strong>
                            </div>
                            <div className={`tab ${this.state.selectedPlanType === 'pro' ? 'active' : ''}`} onClick={() => {
                                this.setState({
                                    ...this.state,
                                    selectedPlanType: 'pro'
                                })
                            }}>
                                <strong>Pro Plans</strong>
                            </div>
                        </div>
                        <div className="tab-content">
                        {!plansLoading && !billingDetailsLoading ?
                            <>
                            {this.state.selectedPlanType === 'basic' && (
                                <div className="grid">
                                    {this.state?.plans?.basic.map(p => {
                                        return (
                                            <div className={`plan ${this.getClassName(p.name)}`}>
                                                <div className="header">
                                                    {p?.name}
                                                </div>
                                                <div className="price">
                                                    {p?.amount && <h3><span>$</span> {p?.amount / 100} / month</h3>}
                                                    {p?.amount && <h5>( $ {(p?.amount / (100 * p?.product?.metadata?.credit))?.toFixed(2)} / hour )</h5>}
                                                </div>
                                                <div className="divider"></div>
                                                <div className="description">
                                                    {p?.product?.description}
                                                </div>
                                                <div className="button-wrapper">
                                                        <button className="button" onClick={this.paymentGo.bind(this, p.id)}>Select This Plan</button>
                                                    </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}

                            {this.state.selectedPlanType === 'pro' && (
                                    <div className="grid">
                                        {this.state?.plans?.pro.map(p => {
                                            return (
                                                <div className={`plan ${this.getClassName(p.name)}`}>
                                                    <div className="header">
                                                        {p?.name}
                                                    </div>
                                                    <div className="price">
                                                        {p?.amount && <h3><span>$</span> {p?.amount / 100}</h3>}
                                                        {p?.amount && <h5>( $ {(p?.amount / (100 * p?.product?.metadata?.credit))?.toFixed(2)} / hour )</h5>}
                                                    </div>
                                                    <div className="divider"></div>
                                                    <div className="description">
                                                        {p?.product?.description}
                                                    </div>
                                                    <div className="button-wrapper">
                                                        <button className="button" onClick={this.paymentGo.bind(this, p.id)}>Select This Plan</button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </> : 
                            <div className="col-md-12">
                            <div className="d-flex justify-content-center align-items-center">
                                <p className=" font-weight-bold">Loading...</p>
                            </div>
                        </div>}
                        </div>
                    </div>
                </div>
            </div>

                <div className="modal fade" id="changeSubscription" tabindex="-1" role="dialog" aria-labelledby="changeSubscription" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Change Subscription</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {
                                onSuccess ?
                                <div className="d-flex justify-content-center">
                                    <div className="text-center">
                                        <div className="animation-ctn">
                                            <div className="icon icon--order-success svg mb-5">
                                                <FiCheckCircle color="green" size="120"/>
                                            </div>
                                        </div>
                                        <h2 className="bold mb-2">Subscription Successful!</h2>
                                        <p className="mb-4">Your Subscription was successfully processed. Check your email for your receipt.</p>
                                    </div>
                                </div>
                                :
                                <div className="d-flex justify-content-center">
                                    <div className="text-center">
                                        <div className="animation-ctn">
                                            <div className="icon icon--order-success svg mb-5">
                                                <FiAlertOctagon color="green" size="120"/>
                                            </div>
                                        </div>
                                        <h2 className="bold mb-2">Are you sure?</h2>
                                        <p className="mb-4">Your are changing your current subscription. Please confirm to continue.</p>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="modal-footer">
                            {
                                !onSuccess ? 
                                <button type="button" className="btn btn-primary text-white" disabled={loading} onClick={this.upgrade}>Confirm</button>
                                : <Link to="/"><button type="button" className="btn btn-primary text-white" data-dismiss="modal" onClick={() => this.props.history.push('/')}>Go To Dashboard</button></Link>
                            }
                        </div>
                        </div>
                    </div>
                </div>
        </BlankAuthLayout>
    )
  }
}

export default withRouter(Pricing);