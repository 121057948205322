import axios from "axios";
import { API_END_POINT } from "../config/env.json";
import cookie from "../actions/cookie";

export const findTutor = (filters, page) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/find-tutor?page=${page}`, filters, options)
        .then(res => res)
        .catch(error => error);
};

export const schedule = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/tutor-schedule`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const reSchedule = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/tutor-re-schedule`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const changeStatus = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/schedule-approval`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const findTutorById = (id) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/tutor-details`, {tutor_id: id}, options)
        .then(res => res)
        .catch(error => error);
};


export const scheduleParent = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/parent-student-schedule`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const scheduleStudent = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/student-schedule`, payload, options)
        .then(res => res)
        .catch(error => error);
};