/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import validation from "../../actions/validation";
import { toast } from "react-toastify";
import { occupations, states, racicalCategories } from "../../config/variables";
import DropZone from "../DropZone";
import { FiPhoneForwarded } from "react-icons/fi";

class ParentS1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			gender: "",
			race: "",
			state: "",
			address: "",
			zip_code: "",
			dob: "",
			occupation: "",
			//validation
			isValid: false,
			loading: false,
			//image
			profile_picture: "",
			phone_number: "",
			initialLoad: false,
		};
	}

	onChange = (e) => {
        e.persist();
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            isValid: false
        })
    };

	componentDidUpdate() {
		if(!this.props?.parentData) return;
		if(this.state.initialLoad) return;
		this.setState({
			phone_number: this.props.parentData?.phone_number,
			initialLoad: true,
		})
	}

	
	
	onSubmit = () => {
		const { phone_number, zip_code, address, state, race, gender, number_of_kids, occupation} = this.state;
		this.setState({
            ...this.state,
            loading: true,
            isValid: true,
        }, () => {
			if(validation.text(state)
				&& validation.text(phone_number)
				&& validation.text(address)
				&& validation.text(gender)
				&& validation.text(zip_code)
				&& validation.text(race)
				&& validation.text(occupation)
				&& validation.mobile(phone_number)
			) {
				const data = JSON.parse(JSON.stringify(this.state));
				this.props.getData(_.omit(data, ['isValid', 'loading']));
			}else {
				this.setState({
					...this.state,
					loading: false
				});
				toast.error("Validation Error!")
			}
		});
	}

	getFile = (data) => {
		if(data.resp) {
			this.setState({
				...this.state,
				loading: data.loading,
				profile_picture: data.resp
			});
		}
	}

	render() {
		const { phone_number, zip_code, address, state, race, gender, occupation, isValid, loading} = this.state;

		return(
		<React.Fragment>
			<h3 className='text-center text-dark mb-2'>Parent Information</h3>
			<p className="text-center text-dark mb-4">Help us know you better.</p>
			<div id='signup-form'>
				<div className='form-row'>
					<div className='form-group col-md-6'>
						<label htmlFor='state'>State of residence</label>
						<select className={`custom-select form-control light ${(isValid && (validation.text(state) ? '' : 'error is-invalid')).toString()}`}
								id="state" value={state}
								name="state"
								onChange={this.onChange}>
							<option value="">Choose One</option>
							{
								states.map((state, key) => (
									<option key={key} value={state}>{state}</option>
								))
							}
						</select>
						<div className="invalid-feedback">
							Select your state.
						</div>
					</div>
					<div className='form-group col-md-6'>
                        <label htmlFor='phone_number'>Phone Number</label>
                        <div className='input-group'>
                            <div class='input-group-prepend'>
                                <span class='input-group-text' id='phone_number'>
                                    <FiPhoneForwarded />
                                </span>
                            </div>
                            <input type='text'
                                    name='phone_number'
                                    value={phone_number}
                                    onChange={this.onChange}
                                    className={`form-control light ${(isValid && (validation.mobile(phone_number) ? '' : 'error is-invalid')).toString()}`}
                            />
                            <div className="invalid-feedback">
                                Please enter valid, 10 digits long mobile no.
                            </div>
                        </div>
                    </div>
				</div>
				<div className='form-row'>
					<div className='form-group col-md-6'>
						<label htmlFor='address'>Address</label>
						<input
							type='text'
							name='address'
							id="address"
							value={address}
							onChange={this.onChange}
							className={`form-control light ${(isValid && (validation.text(address) ? '' : 'error is-invalid')).toString()}`}
						/>
						<div className="invalid-feedback">
							Type your address.
						</div>
					</div>
					<div className='form-group col-md-6'>
						<label htmlFor='gender'>Gender</label>
						<select className={`custom-select form-control light ${(isValid && (validation.text(gender) ? '' : 'error is-invalid')).toString()}`}
								id="gender" name="gender" value={gender}
								onChange={this.onChange}>
							<option value="">Choose One</option>
							<option value="Male">Male</option>
							<option value="Female">Female</option>
							<option value="Others">Others</option>
							<option value="I don't prefer to say">I don't prefer to say</option>
						</select>
						<div className="invalid-feedback">
							Select your gender.
						</div>
					</div>
				</div>
				<div className='form-row'>
					<div className='form-group col-md-6'>
						<label htmlFor='zip_code'>Zip Code</label>
						<input
							type='text'
							name='zip_code'
							id="zip_code"
							value={zip_code}
							onChange={this.onChange}
							className={`form-control light ${(isValid && (validation.text(zip_code) ? '' : 'error is-invalid')).toString()}`}
						/>
						<div className="invalid-feedback">
							Select your zip-code.
						</div>
					</div>
					<div className='form-group col-md-6'>
						<label htmlFor='race'>Race</label>
						<select className={`custom-select form-control light ${(isValid && (validation.text(race) ? '' : 'error is-invalid')).toString()}`}
								id="race" name="race" value={race} onChange={this.onChange}>
							<option value="">Choose One</option>
							{
								racicalCategories.map((race, key) => (
									<option key={key} value={race}>{race}</option>
								))
							}
						</select>
						<div className="invalid-feedback">
							Select your race.
						</div>
					</div>
				</div>
				<div className='form-row mb-3'>
					<div className='form-group col-md-6'>
						<label htmlFor='occupation'>Occupation</label>
						<input
							type='text'
							name='occupation'
							id="occupation"
							value={occupation}
							onChange={this.onChange}
							className={`form-control light ${(isValid && (validation.text(occupation) ? '' : 'error is-invalid')).toString()}`}
						/>
						<div className="invalid-feedback">
							Type your occupation.
						</div>
					</div>
				</div>
				<div className="text-center">
					<label className="mb-1">
						Upload clear photograph 
					</label>
					<DropZone 
						maxSize={5} 
						response={this.getFile.bind(this)} 
						prefix={"profile"} 
						mimeTypes="image/png, image/jpeg, image/jpg"
					/>
				</div>
				<div className='d-flex justify-content-center align-items-center my-5'>
					<button
						disabled={loading}
						className='btn btn-primary btn-padding--lg text-white'
						onClick={this.onSubmit}
					>
						Continue
					</button>
				</div>
			</div>
		</React.Fragment>
		);
	}
}

export default ParentS1;