import React, {Component} from 'react';
import validation from "../../actions/validation";
import {toast} from "react-toastify";
import auth from "../../actions/auth";
import {updatePass} from "../../services/Profile";

class ParentStudentSecurity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            old_password: "",
            new_password: "",
            new_confirm_password: "",
            //validation
            isValid: false,
            loading: false
        }
    }

    onChange = (e) => {
        e.persist();
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            isValid: false
        });
    };

    update = () => {
        const { old_password, new_password, new_confirm_password } = this.state;
        let input = {
            new_password: new_password,
            new_confirm_password: new_confirm_password
        }
        this.setState({
            ...this.state,
            isValid: true,
            loading: true
        }, () => {
            if(validation.passwordStrength(old_password, "medium")
                && validation.passwordStrength(new_password, "medium")
                && validation.passwordStrength(new_confirm_password, "medium")
            ) {
                updatePass(auth.check().id, input).then((res) => {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    if(res.status === 200) {
                        toast.success(res.data.message);
                    }else {
                        toast.error(res.data.message);
                    }
                }).catch((err) => {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    toast.error("Something went wrong!");
                })
            }else {
                this.setState({
                    ...this.state,
                    loading: false
                })
                toast.error("Validation Error!");
            }
        })
    }

    render() {
        const { old_password, new_password, new_confirm_password, isValid, loading } = this.state;

        return (
            <div className="profile-security">
                <h5 className='bold serif mb-5'>Password Settings</h5>
                <form className='flexible-width' onSubmit={(e) => {e.preventDefault();}}>
                    <div className="row mb-5">
                        <div className="col-md-6">
                            <div className="form-group mb-4">
                                <label htmlFor="old_password" className='toggle-label bold'>Enter old Password</label>
                                <input type="password"  
                                    className={`form-control ${(isValid && ((validation.passwordStrength(old_password, "medium")) ? '' : 'error is-invalid')).toString()}`} 
                                    name="old_password" 
                                    id="currentpass" 
                                    onChange={this.onChange}
                                />
                                <div className="invalid-feedback">
                                    Entert Old Password.
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="new_password" className='toggle-label bold'>Enter new Password</label>
                                <input type="password" 
                                    className={`form-control ${(isValid && ((validation.passwordStrength(new_password, "medium") && new_password === new_confirm_password) ? '' : 'error is-invalid')).toString()}`} 
                                    name="new_password" 
                                    id="newpass" 
                                    onChange={this.onChange}
                                />
                                <div className="invalid-feedback">
                                    Password miss-matching/Can't be empty.
                                </div>
                            </div>
                            <div className="form-group mb-0">
                                <label htmlFor="new_confirm_password" className='toggle-label bold'>Confirm new Password</label>
                                <input type="password" 
                                    className={`form-control ${(isValid && ((validation.passwordStrength(new_confirm_password, "medium") && new_password === new_confirm_password) ? '' : 'error is-invalid')).toString()}`} 
                                    name="new_confirm_password" 
                                    id="confpass" 
                                    onChange={this.onChange}
                                />
                                <div className="invalid-feedback">
                                    Password miss-matching/Can't be empty.
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 align-self-end">
                            <div className="d-flex justify-content-end mt-5 mt-md-auto">
                                <button type="submit" className="btn btn-primary btn-padding--lg text-white" disabled={loading} onClick={this.update}>Save changes</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default ParentStudentSecurity;