import React, {Component} from 'react';
import { cancelSubscription, cancelSubscriptionParent, subscriptionDetails, subscriptionDetailsParent, upgradeCard } from "../../services/Payment";
import {toast} from "react-toastify";
import auth from "../../actions/auth";
import validation from "../../actions/validation";
import ParentStudentSubscriptionTable from './ParentStudentSubscriptionTable';
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import moment from "moment";
import $ from "jquery";
import guard from "../../actions/guard";
import {get} from "../../services/Profile";

class ParentStudentBilling extends Component {

    constructor(props) {
        super(props);
        this.state = {
            billingDetails: {},
            billingDetailsLoading: true,

            cvc: "",
            name: "",
            number: "",
            month: "",
            year: "",
            focus: "number",
            isValidCard: false,
            //validation
            isValid: false,
            loading: false,
            students: null,
            isCancelled: false,
            role: null,
            isProfileComplete: false
        }
    }

    getProfile = () => {
        get(auth.check().id).then((res) => {
            console.log(res)
            if(res.status === 200) {
                const data = res.data.data;
                const isProfileComplete = this.checkProfileCompletion(data);
                this.setState({
                    ...this.state,
                    isProfileComplete: isProfileComplete
                });
            }else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            toast.error("Something went wrong!");
        })
    }

    checkProfileCompletion(data) {
        if(data.role_id === "4") {
            if(data.first_name
                && data.last_name
                && data.phone_number
                && data.race
                && data.student_details.address
                && data.student_details.current_gpa
                && data.student_details.current_grade
                && data.student_details.current_school
                && data.student_details.disablities
                && data.student_details.dob
                && data.student_details.major
                && data.student_details.special_needs
                && data.student_details.state
                && data.student_details.zip_code) {
                    return true;
                } else {
                    return false;
                }
        } else if(data.role_id === "3") {
            if(data.first_name
                && data.last_name
                && data.phone_number
                && data.race
                && data.parent_details.address
                && data.parent_details.dob
                && data.parent_details.state
                && data.parent_details.zip_code) {
                    return true;
                } else {
                    return false;
                }
        }
    } 

    componentDidMount() {
        console.log('auth.check()',auth.check())
        guard.check(() => {
            if (guard.role() === "parent") {
                this.getDetailsParent();
                this.setState({
                    ...this.state,
                    role: 'parent'
                })
            } else if(guard.role() === "student") {
                this.getDetails();
                this.setState({
                    ...this.state,
                    role: 'student'
                })
            }
            this.getProfile()
        });
        // if(auth.check().subscription_plan_id) {
        //     this.getDetails();
        // }
        // this.getDetails();
        // this.getProfileData();
    }

    onChange = (e) => {
        e.persist();
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            focus: e.target.name,
            isValid: false,
            loading: false
        })
    }

    cancel = (studentId) => {
        if (guard.role() === "parent") {
            cancelSubscriptionParent({student_id: studentId}).then((res) => {
                if(res.status === 200) {
                    toast.success("Subscription canceled successfully.");
                    auth.update("subscription_plan_id", null);
                    auth.update("subscription_id", null);
                    this.setState({
                        ...this.state,
                        isCancelled: true
                    });
                }else {
                    toast.error("Something went wrong!");
                }
            }).catch((err) => {
                toast.error("Something went wrong!");
            })
        } else if(guard.role() === "student") {
            cancelSubscription().then((res) => {
                if(res.status === 200) {
                    toast.success("Subscription canceled successfully.");
                    auth.update("subscription_plan_id", null);
                    auth.update("subscription_id", null);
                    this.setState({
                        ...this.state,
                        isCancelled: true
                    });
                }else {
                    toast.error("Something went wrong!");
                }
            }).catch((err) => {
                toast.error("Something went wrong!");
            })
        }
        
    }

    getDetails = () => {
        subscriptionDetails().then((res) => {
            console.log(res)
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    billingDetails: res.data.data,
                    billingDetailsLoading: false
                });
            }else {
                this.setState({
                    ...this.state,
                    // billingDetailsLoading: false
                });
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            this.setState({
                ...this.state,
                billingDetailsLoading: false,
            });
            toast.error("Something went wrong!");
        })
    }

    getDetailsParent = () => {
        subscriptionDetailsParent().then((res) => {
            console.log('parent',res)
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    billingDetails: res.data.data,
                    billingDetailsLoading: false,
                    students: res.data.data
                });
            }else {
                this.setState({
                    ...this.state,
                    // billingDetailsLoading: false
                });
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            this.setState({
                ...this.state,
                // billingDetailsLoading: false
            });
            toast.error("Something went wrong!");
        })
    }

    onSubmit = () => {
        let input = {
            "number": this.state.number,
            "exp_month": this.state.month,
            "cvc": this.state.cvc,
            "exp_year": this.state.year
        };
        this.setState({
            ...this.state,
            loading: true,
            isValid: true
        }, () => {
            if(this.state.isValidCard
                && validation.text(this.state.month)
                && validation.text(this.state.year)
                && validation.text(this.state.name)
                && validation.positiveNumber(this.state.cvc)
            ) {
                upgradeCard(input).then((res) => {
                    if(res.status === 200) {
                        auth.update("card_id", res.data.data.card_id);
                        toast.success("Card details updated successfully!");
                        $("#changeCardDetails").modal('hide');
                        this.getDetails();
                    }else {
                        throw new Error (res.response.data.message);
                    }
                }).catch((err) => {
                    this.setState({
                        ...this.state,
                        loading: false,
                    });
                    toast.error(err.message);
                })
            }else {
                toast.error("Validation Error!");
            }
        })
    }

    render() {
        // const { billingDetailsLoading, billingDetails } = this.state;
        const { cvc, name, number, focus, month, year, isValid, isValidCard, loading } = this.state;
        console.log('isProfileComplete', this.state.isProfileComplete)
        return (
            <div className="profile-billing">
                <h5 className='bold serif mb-5'>Your Subscription</h5>
                {!this.state.billingDetailsLoading && this.state.isProfileComplete && <ParentStudentSubscriptionTable students={this.state.students} cancelPlan={this.cancel} onSuccess={this.state.isCancelled} role={this.state.role} billingDetails={this.state.billingDetails} />}
                {/* {
                    auth.check().subscription_plan_id ?
                    <section className="subscription-grid">
                        {
                            billingDetailsLoading ? 
                            <p>Loading...</p>
                            :
                            <div>
                                <h6 style={{color: '#3D5154'}}>Subscription Plan</h6>
                                <p>You subscribed to <span>{billingDetails.subscription.plan.name}</span> on {moment.unix(billingDetails.subscription.current_period_start).format("LL")}.  */}
                                {/* Next payment will be made on {moment.unix(billingDetails.subscription.current_period_end).format("LL")} for ${billingDetails.subscription.plan.amount}.  */}
                                {/* </p>
                            </div>
                        }
                        <div className='d-none d-md-block'></div>
                        <div>
                            <h6 style={{color: '#3D5154'}}>Upgrade Subscription</h6>
                            <p>Upgrade your subscription to cover for longer tutoring hours.<br/> ** 1 credit = 1 hour = 1 session</p>
                        </div>
                        <div>
                            <Link to="/pricing">
                                <button className='btn btn-primary text-white w-100 py-2'>Upgrade</button>
                            </Link>
                        </div>
                        <div>
                            <h6 style={{color: '#3D5154'}}>Cancel Subscription</h6>
                            <p>By cancelling your subscription, you will lose access to premium tutoring sessions </p>
                        </div>
                        <div>
                            <button className='btn btn-danger w-100 py-2' onClick={this.cancel}>Cancel Subscription</button>
                        </div>
                    </section>
                    :
                    <section className="subscription-grid">
                        <div>
                            <h6 style={{color: '#3D5154'}}>Subscription Plan</h6>
                            <p>You don't have any active subscription plan. Buy a subscription now!</p>
                        </div>
                        <div>
                            <Link to="/pricing">
                                <button className='btn btn-primary text-white w-100 py-2'>Buy subscription</button>
                            </Link>
                        </div>
                    </section>
                } */}
                <hr className='mb-5'/>
                <h5 className='bold serif mb-5'>Payment Details</h5>
                {/* {
                    auth.check().subscription_plan_id ?
                    <>
                        <div className='row mb-3'>
                            <div className="col-5 col-md-3">
                            Payment Method: 
                            </div>
                            <div className="col-6 col-md-2 bold">Credit Card</div>
                            <div className="col-12 text-wrap col-md-3 text-teal bold pointer" data-toggle="modal" data-target="#changeCardDetails">Change card details</div>
                        </div>
                        <div className='row mb-3'>
                            <div className="col-6 col-md-3">
                            Card number: 
                            </div>
                            {
                                billingDetailsLoading ?
                                <p>Loading...</p>
                                :
                                <div className="col-6 col-md-2 bold">************{billingDetails.card_info?.last4}</div>
                            }
                        </div>
                        <div className='row mb-3'>
                            <div className="col-6 col-md-3">
                            Payment Frequency: 
                            </div>
                            {
                                billingDetailsLoading ?
                                <p>Loading...</p>
                                :
                                <div className="col-6 col-md-2 bold">{billingDetails.subscription.plan.interval}</div>
                            }
                        </div> */}
                        {/* <div className='row'>
                            <div className="col-5 col-md-3">
                            Next Payment:
                            </div>
                            {
                                billingDetailsLoading ?
                                <p>Loading...</p>
                                :
                                <div className="col-6 col-md-2 bold">{moment.unix(billingDetails.subscription.billing_cycle_anchor).format("LL")}</div>
                            }
                        </div> */}
                    {/* </>
                    :
                    <p>No details available</p>
                } */}
                

                <div className="modal fade" id="changeCardDetails" tabindex="-1" role="dialog" aria-labelledby="changeCardDetails" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Change Card Details</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="form-group">
                                        <label htmlFor='endtime' className="txt-14 text-deep">Card number</label>
                                        <input
                                            type='number'
                                            name='number'
                                            value={number}
                                            onChange={this.onChange}
                                            className={`form-control light ${(isValid && (isValidCard ? '' : 'error is-invalid')).toString()}`}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor='expiry' className="txt-14 text-deep">Valid until</label>
                                        <div className="form-row justify-content-between">
                                            <div className="form-group col-md-6">
                                                <select className={`custom-select form-control light ${(isValid && (validation.text(month) ? '' : 'error is-invalid')).toString()}`}
                                                        id="month" name="month" value={month} onChange={this.onChange}>
                                                    <option value="">Choose Month</option>
                                                    {
                                                        [...Array(12)].map((val, key) => (
                                                            <option key={key} value={key+1 > 9 ? key+1 : '0'+(key+1)}>{key+1 > 9 ? key+1 : '0'+(key+1)}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <select className={`custom-select form-control light ${(isValid && (validation.text(year) ? '' : 'error is-invalid')).toString()}`}
                                                        id="year" name="year" value={year} onChange={this.onChange}>
                                                    <option value="">Choose Year</option>
                                                    {
                                                        [...Array(20)].map((val, key) => (
                                                            <option key={key} value={moment().add(key, 'year').format('YYYY')}>{moment().add(key, 'year').format('YYYY')}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor='cardholder' className="txt-14 text-deep">Card holder</label>
                                        <input
                                            type='text'
                                            name='name'
                                            value={name}
                                            onChange={this.onChange}
                                            placeholder='Enter name'
                                            className={`form-control light ${(isValid && (validation.text(name) ? '' : 'error is-invalid')).toString()}`}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor='cvv' className="txt-14 text-deep">CVV</label>
                                        <div className="form-row justify-content-between align-items-center">
                                            <input
                                                type='number'
                                                name='cvc'
                                                value={cvc}
                                                onChange={this.onChange}
                                                className={`form-control light col-md-5 mb-3 mb-md-0 ${(isValid && (validation.positiveNumber(cvc) ? '' : 'error is-invalid')).toString()}`}
                                            />
                                            <p className='col-md-6' style={{fontSize: 11}}>
                                                Check back of card for three digits.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <Cards
                                        cvc={cvc}
                                        expiry={month+year}
                                        focused={focus}
                                        name={name}
                                        number={number}
                                        callback={(type, isValid) => this.setState({...this.state, isValidCard: isValid})}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary text-white"
                                onClick={this.onSubmit}
                                disabled={loading}
                            >Save changes</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ParentStudentBilling;