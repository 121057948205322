/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Calendar from 'react-calendar';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { MdAdd } from 'react-icons/md';
import auth from "../../../actions/auth";
import guard from "../../../actions/guard";
import {get} from "../../../services/Profile";
import {toast} from "react-toastify";
import moment from "moment";
import validation from "../../../actions/validation";
import _ from "lodash";
import './Schedule.style.css';
import { states } from '../../../config/variables';

const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export default class Schedule extends Component {

  constructor(props) {
    super(props);
    this.state = {
        emptyState: false,
        tutor: {},
        recruter: {},
        date: new Date(),
        parent_id: null,
        tutor_id: null,

        student_data: [],
        in_person: "0",
        online: "1",
        address: "",
        state: "",
        recurring_arrangment: "0",

        //validation
        isValid: false,
        loading: false,
        prefered_lesson_type: "online"
    }
  }

  componentDidMount() {
      get(auth.check().id).then((res) => {
        if(res.status === 200) {
          if(guard.role() == "parent") {
            this.setState({
                ...this.state,
                parent_id: this.props.parentId,
                tutor_id: this.props.tutorId,
                student_data: [{
                  "student_id": this.props.studentId,
                    "schedule_date": "",
                    "start_time": "",
                    "end_time": "",
                    "duration": "",
                    "profile_picture": this.props.student_profile,
                    "student_name": this.props.studentName
                }],
                recruter: res.data.data
            });
          }else {
            this.state.student_data.push({
              "student_id": res.data.data.id,
              "schedule_date": "",
              "start_time": "",
              "end_time": "",
              "duration": "",
              "profile_picture": res.data.data.details.profile_picture
            });
            this.setState({
                ...this.state,
                student_data: this.state.student_data,
            });
          }
        }else {
            toast.error("Something went wrong!");
        }
      }).catch((err) => {
          toast.error("Something went wrong!");
      }) 
      get(this.props.tutorId).then((res) => {
        if(res.status === 200) {
            this.setState({
                ...this.state,
                tutor:  res.data.data,
                emptyState: false
            });
        }else {
            toast.error("Something went wrong!");
        }
      }).catch((err) => {
          toast.error("Something went wrong!");
      }) 
  }

  tileDisabled = (e) => {
    const { day_time_mapping } = this.state.tutor;
    let uniqDay = _.uniq(day_time_mapping.map((val) => val.day));
    return _.indexOf(uniqDay, days[moment(e.date).day()]) === -1;
  }

  onChange = (from, e) => {
    if(from !== "") {
      this.state.student_data[0][e.target.name] = e.target.value;
      this.setState({
        ...this.state,
        student_data: this.state.student_data,
        isValid: false
    });
    }else {
      console.log(e.target.name, e.target.value)
      if(e.target.name === 'prefered_lesson_type' && e.target.value === 'online') {
        this.setState({
          ...this.state,
          [e.target.name]: e.target.value,
          online: "1",
          in_person: "0",
          isValid: false
      });
      } else if(e.target.name === 'prefered_lesson_type' && e.target.value === 'in_person') {
        this.setState({
          ...this.state,
          [e.target.name]: e.target.value,
          online: "0",
          in_person: "1",
          isValid: false
      });
      } else {
        this.setState({
          ...this.state,
          [e.target.name]: e.target.value,
          isValid: false
      });
      }
      
    }
  }

  studentMap = (student, e) => {
    if (e.target.checked) {
        this.state.student_data.push({
          "student_id": student.student_user.id,
          "schedule_date": "",
          "start_time": "",
          "end_time": "",
          "duration": "",
          "profile_picture": ''
        });
        this.setState({
            ...this.state,
            student_data: this.state.student_data,
            isValid: false
        });
    }else {
        let i = _.findIndex(this.state.student_data, ['student_id', student.student_user.id]);
        this.state.student_data.splice(i, 1);
        this.setState({
            ...this.state,
            student_data: this.state.student_data,
            isValid: false
        });
    }
  }

  onSubmit = () => {
    const { date, student_data, in_person, online, address, state, recurring_arrangment } = this.state;
    this.setState({
      isValid: true,
      loading: true
    }, () => {
      if(validation.text(in_person)
        && validation.text(online)
        && validation.text(address)
        && validation.text(state)
        && student_data.length > 0
        && validation.text(recurring_arrangment)) {
          student_data.forEach((student) => {
            student.schedule_date = moment(date).format('YYYY-MM-DD');
            student.duration = moment.duration(moment(student.end_time, ["HH.mm"]).diff(moment(student.start_time, ["HH.mm"]))).hours()
          })
          this.props.getData({
            student_data,
            in_person,
            online,
            address,
            state,
            recurring_arrangment
          });
        }else {
          this.setState({
            ...this.state,
            loading: false
          })
          toast.error("Validation Error!");
        }
    })
  }

  render() {
    const { emptyState, tutor, recruter, date, student_data, in_person, online, address, state, recurring_arrangment, isValid, loading } = this.state;
    
    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col-md-7">
            {
              !_.isEmpty(tutor) &&
              <div className="select-date__wrapper">
                <h4 className='bold'>Select Date</h4>
                <div className="form-group col-8 px-0">
                  <label htmlFor="tutor_name" className='col-form-label text-teal bold'>Tutor</label>
                  <input type="text" className='form-control' value={`${tutor.first_name} ${tutor.last_name}`}/>
                </div>
                <Calendar 
                  className='cal-schedule' 
                  calendarType={"US"}
                  onChange={(date) => this.setState({ ...this.state, date })}
                  value={this.state.date}
                  tileDisabled={this.tileDisabled}
                  minDate={new Date()}
                  // maxDate={new Date("2020-07-29")}
                />
              </div>
            }
          </div>
          <div className="col-md-5">
            <div className="schedule-section">
                <h4 className='bold'>Schedule</h4>
                {
                  emptyState ? 
                  <div className='py-6'>
                    <p className='text-center px-3'>Select a date on the calendar to schedule tutoring periods. Options will be displayed here.</p>
                  </div>
                  : 
                  <div className='py-4'>
                    <div className="form-group mb-3">
                      <label htmlFor="" className='col-form-label text-teal bold'>Date Selected:</label>
                      <div className="d-md-flex">
                        <p className='tab text-dark text-left mb-2 mb-md-0 mr-4'>{moment(date).format('LL')}</p>
                        <p className='txt-14 text-danger'>* Click date on calendar to change.</p>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="" className='col-form-label text-teal bold'>Students ({student_data.length})</label>
                      <div className="d-flex align-items-center">
                        <div className='image-group mr-3' style={{width: 'auto'}}>
                          {
                            student_data.length > 0 && student_data.map((student) => {
                              return (
                              <div style={{display: "flex"}}>
                                {student.profile_picture ?
                              <img src={student.profile_picture} style={{marginRight: '10px'}} alt="i-2"/>
                              :
                              <img style={{marginRight: '10px'}} src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png?1592828498" alt="i-2"/>}

                              <h3 style={{fontSize: '20px'}}>{student.student_name}</h3>
                             
                              </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>

                    {
                      student_data.length > 0 && student_data.map((student, key) => (
                        <div className="row my-3" key={key}>
                          <div className="col-4">
                            <div className="form-group mb-0">
                              <label htmlFor="start_time" className='txt-14 bold text-teal'>Start times:</label>
                              <select name="start_time" id="start_time" 
                              className={`custom-select ${(isValid && (validation.text(student.start_time) ? '' : 'error is-invalid')).toString()}`}
                              value={student.start_time}
                              onChange={this.onChange.bind(this, key)}>
                                <option value="">Select Time</option>
                                {
                                  _.filter(tutor.day_time_mapping, ['day', days[moment(date).day()]]).length > 0 && _.filter(tutor.day_time_mapping, ['day', days[moment(date).day()]]).map((time, key) => (
                                    <option value={time.start_time} key={key}>{moment(time.start_time, ["HH.mm"]).format("hh:mm a")}</option>
                                  ))
                                }
                              </select>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-group mb-0">
                              <label htmlFor="end_time" className='txt-14 bold text-teal'>End times:</label>
                              <select name="end_time" id="end_time"
                               className={`custom-select ${(isValid && (validation.text(student.end_time) ? '' : 'error is-invalid')).toString()}`}
                               value={student.end_time}
                               onChange={this.onChange.bind(this, key)}>
                                <option value="">Select Time</option>
                                {
                                  _.filter(tutor.day_time_mapping, ['day', days[moment(date).day()]]).length > 0 && _.filter(tutor.day_time_mapping, ['day', days[moment(date).day()]]).map((time, key) => (
                                    <option value={time.start_time} key={key}>{moment(time.start_time, ["HH.mm"]).format("hh:mm a")}</option>
                                  ))
                                }
                              </select>
                            </div>
                          </div>
                          {
                            student.end_time && student.start_time &&
                            <div className="col-4">
                              <div className="form-group mb-0">
                                <label htmlFor="start_time" className='txt-14 bold text-teal'>Duration(s)</label>
                                <p className='mt-2'>{moment.duration(moment(student.end_time, ["HH.mm"]).diff(moment(student.start_time, ["HH.mm"]))).humanize()}</p>
                              </div>
                            </div>
                          }
                        </div>
                      ))
                    }
                    
                    <div className="form-group mt-5">
                      <label htmlFor="prefer_location" className='txt-14 bold text-teal'>Do you prefer lessons at home or online?</label>
                      <div className="d-flex flex-grow-1">
                        <div className='custom-control custom-radio mr-3 mb-3 mb-md-0'>
                            <input
                                type='radio'
                                className='custom-control-input'
                                id='person'
                                value="in_person"
                                onChange={this.onChange.bind(this, '')}
                                name="prefered_lesson_type"
                                checked={this.state.in_person === "1"}
                            />
                            <label className='custom-control-label error' htmlFor='person'>
                            In-person</label>
                        </div>
                        <div className='custom-control custom-radio'>
                            <input
                                type='radio'
                                value="online"
                                className='custom-control-input'
                                id='online'
                                name='prefered_lesson_type'
                                onChange={this.onChange.bind(this, '')}
                                checked={this.state.online === "1"}
                            />
                            <label className='custom-control-label' htmlFor='online'>
                            Online</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-md-8 px-0 mt-4">
                      <label htmlFor="address" className='txt-14 bold text-teal'>Address</label>
                      <input type="text" 
                        value={address}
                        name="address"
                        onChange={this.onChange.bind(this, '')}
                        className={`form-control ${(isValid && (validation.text(address) ? '' : 'error is-invalid')).toString()}`}
                        id="address" placeholder="5174 Richmond Avenue" 
                      />
                    </div>
                    <div className="form-group col-md-8 px-0">
                      <label htmlFor="state" className='txt-14 bold text-teal'>State</label>
                      <select name="state" id="state" 
                      value={state}
                      onChange={this.onChange.bind(this, '')}
                      className={`custom-select ${(isValid && (validation.text(state) ? '' : 'error is-invalid')).toString()}`}>
                        <option value="">Choose One</option>
                        {
                            states.map((state, key) => (
                                <option key={key} value={state}>{state}</option>
                            ))
                        }
                      </select>
                    </div>
                    <div className="form-group ">
                      <label htmlFor="recurring" className='txt-14 bold text-teal'>Would you like this to be a recurring arrangement?</label>
                      <div className="d-flex flex-grow-1">
                        <div className='custom-control custom-radio mr-3 mb-3 mb-md-0'>
                            <input
                                type='radio'
                                className='custom-control-input'
                                id='yes'
                                value="1"
                                name="recurring_arrangment"
                                onChange={this.onChange.bind(this, '')}
                            />
                            <label className='custom-control-label error' htmlFor='yes'>
                            Yes</label>
                        </div>
                        <div className='custom-control custom-radio'>
                            <input
                                type='radio'
                                value="0"
                                className='custom-control-input'
                                id='no' 
                                name='recurring_arrangment'
                                onChange={this.onChange.bind(this, '')}
                            />
                            <label className='custom-control-label' htmlFor='no'>
                            No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                <div className="d-flex justify-content-end align-items-center">
                  <button
                    disabled={loading}
                    onClick={this.onSubmit}
                    className={`btn btn-padding px-5 text-white btn-primary`}>
                    Continue
                  </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
