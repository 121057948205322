import React, { Component } from 'react'
import Layout from '../components/Layout';
import {InputGroup, FormControl, Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import cookie from "../actions/cookie";
import validation from "../actions/validation";
import { login } from "../services/Auth";
import {toast} from "react-toastify";
import PropTypes from "prop-types";

export default class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            loading: false,
            isValid: false
        };
    }

    componentDidMount() {
        if(!cookie.getCookie("_token")) {

        }else {
            this.props.history.push("/dashboard");
        }
    }

    onSubmit = () => {
        let input = {
            email: this.state.email,
            password: this.state.password,
        };
        this.setState({
            ...this.state,
            loading: true,
            isValid: true
        }, () => {
            if(validation.email(this.state.email)
            && validation.text(this.state.password)) {
                login(input).then((res) => {
                    if(res.status === 200) {
                        cookie.setCookie("_token", res.data.token, 1);
                        cookie.setCookie("_session", btoa(JSON.stringify(res.data.user)), 1);
                        toast.success("Welcome back!");
                        this.setState({
                            ...this.state,
                            loading: false,
                            isValid: false
                        });
                        this.props.history.push("/dashboard");
                    }else {
                        throw new Error (res.response.data.message);
                    }
                }).catch((err) => {
                    this.setState({
                        ...this.state,
                        loading: false,
                    });
                    toast.error(err.message);
                })
            }else {
                toast.error("Validation Error!");
            }
        })
    }

    onChange = (e) => {
        e.persist();
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            isValid: false,
            loading: false
        })
    }

    render() {
        const { password, email, loading, isValid } = this.state;

        return (
            <Layout>
                <div className="layout-container bg-bubble">
                    <section className="padded-intro-section">
                        <div className="intro-content">
                            <h3 className="text-center text-grey mb-4">Sign In</h3>
                            <div className="cta-section">
                                <InputGroup className="mb-4">
                                    <FormControl className={`light ${(isValid && (validation.email(email) ? '' : 'error is-invalid')).toString()}`}
                                                 placeholder="Username"
                                                 aria-label="username"
                                                 aria-describedby="user-name-input"
                                                 name="email"
                                                 value={email}
                                                 onChange={this.onChange}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text id="user-icon">
                                            <img src="/images/userIcon.svg" alt="username" />
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                    <div className="invalid-feedback">
                                        Enter valid email.
                                    </div>
                                </InputGroup>
                                <InputGroup className="mb-4">
                                    <FormControl className={`light ${(isValid && (validation.text(password) ? '' : 'error is-invalid')).toString()}`}
                                                 type="password"
                                                 placeholder="Password"
                                                 aria-label="password"
                                                 aria-describedby="password-input"
                                                 name="password"
                                                 value={password}
                                                 onChange={this.onChange}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text id="lock-icon">
                                            <img src="/images/Password.svg" alt="password" />
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                    <div className="invalid-feedback">
                                        Enter your password.
                                    </div>
                                </InputGroup>
                                <div className="d-flex justify-content-between align-items-center mb-4">
                                    <Link to="/forget" className="text-dark font-weight-bolder">Forgot password?</Link>
                                    <Button variant="primary" className="btn-padding text-white px-5" disabled={loading} onClick={this.onSubmit}>Sign In</Button>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

Login.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};