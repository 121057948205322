/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import DropZone from "../DropZone";
import validation from "../../actions/validation";
import { toast } from "react-toastify";
import moment from "moment";
import { states, racicalCategories, disabilityList } from "../../config/variables";
import { FiPhoneForwarded } from "react-icons/fi";

class StudentS1 extends Component {
	constructor(props) {
        super(props);
		this.state = {
			gender: "",
			race: "",
			state: "",
			address: "",
			zip_code: "",
			phone_number: "",
            disablities: "",
            special_needs: "",
			parent_name: "",
			parent_phone_number: "",
			parent_email: "",
			//validation
			isValid: false,
			loading: false,
			//image
			profile_picture: "",
            initialLoad: false,
		};
    }

    componentDidUpdate() {
		if(!this.props?.studentData) return;
		if(this.state.initialLoad) return;
		this.setState({
			phone_number: this.props.studentData?.phone_number,
			initialLoad: true,
		})
	}

    
    onChange = (e) => {
        e.persist();
        console.log(e.target.value)
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            isValid: false
        })
    };
	
	onSubmit = () => {
		const { zip_code, address, state, race, gender, disablities, phone_number, parent_email, parent_phone_number, parent_name, special_needs } = this.state;
		this.setState({
            ...this.state,
            loading: true,
            isValid: true,
        }, () => {
            if(validation.text(state)
				&& validation.text(address)
				&& validation.text(gender)
				&& validation.text(zip_code)
				&& validation.text(race)
                && validation.text(disablities)
                && validation.text(phone_number)
                // && validation.email(parent_email)
                // && validation.mobile(phone_number)
                // && validation.text(parent_name)
                && validation.text(special_needs)
                ) {
                    const data = JSON.parse(JSON.stringify(this.state));
                    console.log(data)
                    this.props.getData(_.omit(data, ['isValid', 'loading']));
                }else {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    toast.error("Validation Error!")
                }
		});
	}

	getFile = (data) => {
        if(data.resp) {
            this.setState({
                ...this.state,
                loading: data.loading,
                profile_picture: data.resp
            });
        }
	}

	render() {
		const { zip_code, address, state, race, gender, disablities, parent_email, parent_phone_number, parent_name, special_needs, phone_number, isValid, loading} = this.state;
        return(
		<React.Fragment>
            <h3 className='text-center text-dark mb-4'>Your Profile</h3>
            <div id='signup-form'>
                <div className='form-row'>
                    <div className='form-group col-md-6'>
                        <label htmlFor='state'>State of residence</label>
                        <select className={`custom-select form-control light ${(isValid && (validation.text(state) ? '' : 'error is-invalid')).toString()}`}
                                id="state"
                                name="state"
                                value={state} onChange={this.onChange}>
                            <option value="">Choose One</option>
                            {
                                states.map((state, key) => (
                                    <option key={key} value={state}>{state}</option>
                                ))
                            }
                        </select>
                        <div className="invalid-feedback">
                            Select your state.
                        </div>
                    </div>
                    <div className='form-group col-md-6'>
                        <label htmlFor='phone_number'>Phone Number</label>
                        <div className='input-group'>
                            <div class='input-group-prepend'>
                                <span class='input-group-text' id='phone_number'>
                                    <FiPhoneForwarded />
                                </span>
                            </div>
                            <input type='text'
                                    name='phone_number'
                                    value={phone_number}
                                    onChange={this.onChange}
                                    className={`form-control light ${(isValid && (validation.mobile(phone_number) ? '' : 'error is-invalid')).toString()}`}
                            />
                            <div className="invalid-feedback">
                                Please enter valid, 10 digits long mobile no.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='form-group col-md-6'>
                        <label htmlFor='address'>Address</label>
                        <input
                            type='text'
                            name='address'
                            id="address"
                            value={address}
                            onChange={this.onChange}
                            className={`form-control light ${(isValid && (validation.text(address) ? '' : 'error is-invalid')).toString()}`}
                        />
                        <div className="invalid-feedback">
                            Select your address.
                        </div>
                    </div>
                    <div className='form-group col-md-6'>
                        <label htmlFor='gender'>Gender</label>
                        <select className={`custom-select form-control light ${(isValid && (validation.text(gender) ? '' : 'error is-invalid')).toString()}`}
                                id="gender"
                                name="gender"
                                value={gender} onChange={this.onChange}>
                            <option value="">Choose One</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Others">Others</option>
                            <option value="I don't prefer to say">I don't prefer to say</option>
                        </select>
                        <div className="invalid-feedback">
                            Select your gender.
                        </div>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='form-group col-md-6'>
                        <label htmlFor='zip'>Zip Code</label>
                        <input
                            type='text'
                            name='zip_code'
                            id="zip_code"
                            value={zip_code}
                            onChange={this.onChange}
                            className={`form-control light ${(isValid && (validation.text(zip_code) ? '' : 'error is-invalid')).toString()}`}
                        />
                        <div className="invalid-feedback">
                            Type your zip-code.
                        </div>
                    </div>
                    <div className='form-group col-md-6'>
                        <label htmlFor='race'>Race</label>
                        <select className={`custom-select form-control light ${(isValid && (validation.text(race) ? '' : 'error is-invalid')).toString()}`}
                                id="race"
                                name="race"
                                value={race} onChange={this.onChange}>
                            <option value="">Choose One</option>
                            {
                                racicalCategories.map((race, key) => (
                                    <option key={key} value={race}>{race}</option>
                                ))
                            }
                        </select>
                        <div className="invalid-feedback">
                            Select your race.
                        </div>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='form-group col-md-6'>
                        <label htmlFor='parent_name'>Parent Name (optional)</label>
                        <input
                            type='text'
                            name='parent_name'
                            id="parent_name"
                            value={parent_name}
                            onChange={this.onChange}
                            className={`form-control light `}
                            // className={`form-control light ${(isValid && (validation.text(parent_name) ? '' : 'error is-invalid')).toString()}`}
                        />
                        <div className="invalid-feedback">
                            Type your parent name.
                        </div>
                    </div>
                    <div className='form-group col-md-6'>
                        <label htmlFor='parent_phone_number'>Parent Phone Number</label>
                        <input
                            type='text'
                            name='parent_phone_number'
                            id="parent_phone_number"
                            value={parent_phone_number}
                            onChange={this.onChange}
                            className={`form-control light `}
                            // className={`form-control light ${(isValid && (validation.mobile(parent_phone_number) ? '' : 'error is-invalid')).toString()}`}
                        />
                        <div className="invalid-feedback">
                            Type your parent's Ph number.
                        </div>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='form-group col-md-6'>
                        <label htmlFor='parent_email'>Parent Email</label>
                        <input
                            type='email'
                            name='parent_email'
                            id="parent_email"
                            value={parent_email}
                            onChange={this.onChange}
                            // className={`form-control light ${(isValid && (validation.email(parent_email) ? '' : 'error is-invalid')).toString()}`}
                            className={`form-control light `}
                        />
                        <div className="invalid-feedback">
                            Type your parent email.
                        </div>
                    </div>
                    <div className='form-group col-md-6'>
                        <label htmlFor='disablities'>Disabilities</label>
                        <select className={`custom-select form-control light ${(isValid && (validation.text(disablities) ? '' : 'error is-invalid')).toString()}`}
                                id="disablities"
                                name="disablities"
                                value={disablities} onChange={this.onChange}>
                            <option value="">Choose One</option>
                            {
                                disabilityList.map((disability, key) => (
                                    <option key={key} value={disability}>{disability}</option>
                                ))
                            }
                        </select>
                        <div className="invalid-feedback">
                            If you don't have any, select none.
                        </div>
                    </div>
                </div>
                <div className='form-row'>
                <div className='form-group col-md-6'>
                        <label htmlFor='special_needs'>Do you have any special needs?</label>
                        <input
                            type='text'
                            name='special_needs'
                            id="special_needs"
                            value={special_needs}
                            onChange={this.onChange}
                            className={`form-control light ${(isValid && (validation.text(special_needs) ? '' : 'error is-invalid')).toString()}`}
                        />
                        <div className="invalid-feedback">
                            Type your need (if any) or none.
                        </div>
                    </div>
                </div>
                <div className="text-center">
					<label className="mb-1">
						Upload clear photograph 
					</label>
					<DropZone 
						maxSize={5} 
						response={this.getFile.bind(this)} 
						prefix={"profile"} 
						mimeTypes="image/png, image/jpeg, image/jpg"
					/>
				</div>
				<div className='d-flex justify-content-center align-items-center my-5'>
					<button
						disabled={loading}
						className='btn btn-primary btn-padding--lg text-white'
						onClick={this.onSubmit}
					>
						Continue
					</button>
				</div>
            </div>
        </React.Fragment>
        );
	}
}

export default StudentS1;