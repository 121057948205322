/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import validation from "../../actions/validation";
import { toast } from "react-toastify";
import { subjectList } from "../../services/Common";
import { dayList } from "../../config/variables";

class TutorS4 extends Component {
	constructor(props) {
		super(props);
		this.state = {
            subjects: [],
            subjectList: [],
			//validation
			isValid: false,
			loading: false
		};
    }
    
    componentDidMount() {
        this.fetchSubList();
    }

    fetchSubList = () => {
        subjectList().then((res) => {
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    subjectList: Object.values(res.data.subjects)
                })
            }
        }).catch((err) => {
            console.log(err);
        })
    }

	onChange = (e) => {
        e.persist();
        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                this.state[e.target.name].push(e.target.value);
                this.setState({
                    ...this.state,
                    [e.target.name]: this.state[e.target.name],
                    isValid: false
                })
            } else {
                let index = this.state[e.target.name].indexOf(e.target.value);
                this.state[e.target.name].splice(index, 1);
                this.setState({
                    ...this.state,
                    [e.target.name]: this.state[e.target.name],
                    isValid: false
                })
            }
        }
    };
	
	onSubmit = () => {
		const { subjects } = this.state;
		this.setState({
            ...this.state,
            loading: true,
            isValid: true,
        }, () => {
			if(subjects.length > 0) {
				const data = JSON.parse(JSON.stringify(this.state));
				this.props.getData(_.omit(data, ['isValid', 'loading', 'subjectList']));
			}else {
				this.setState({
					...this.state,
					loading: false
				});
				toast.error("Validation Error!")
			}
		});
	}

	render() {
		const {  subjectList, subjects, isValid, loading} = this.state;

		return(
		<React.Fragment>
                <div id='signup-form'>
                    <p className="mb-5">Select subject you would like to tutor in</p>
                    {
                        subjectList.length > 0 && subjectList.map((subSubjects, index) => (
                            <div className='form-group mb-3 subject-item mb-5' key={index}>
                            <label htmlFor='subjects' className='txt-18 bold text-dark text-capitalize mb-4'>{subSubjects[0].subject_group_name}</label>
                            <div className='select-subject-grid'>
                                    {
                                    subSubjects.length > 0 && subSubjects.map((subject, key) => (
                                        <div className='custom-control custom-checkbox' key={key}>
                                            <input
                                                type='checkbox'
                                                className='custom-control-input'
                                                id={subject.id}
                                                value={subject.id}
                                                name="subjects"
                                                onChange={this.onChange}
                                            />
                                            <label className={`custom-control-label checkbox-2 ${(isValid && (subjects.length > 0 ? '' : 'error is-invalid')).toString()}`} htmlFor={subject.id}>
                                                {subject.sub_subject_name}</label>
                                            <div className="invalid-feedback">
                                                Select subjects.
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            </div>
                        )) 
                    }

                    <div className='d-flex justify-content-center align-items-center my-5'>
                        <button
                            disabled={loading}
                            className='btn btn-primary btn-padding--lg text-white'
                            onClick={this.onSubmit}
                        >
                            Continue
                        </button>
                    </div>
                </div>
		</React.Fragment>
		);
	}
}

export default TutorS4;