/* eslint-disable */
import React, {Component} from 'react';
import _ from "lodash";
import ProfileImgUpload from '../../components/ProfileImgUpload';
import validation from "../../actions/validation";
import { FiCheckCircle } from "react-icons/fi";
import auth from "../../actions/auth";
import {toast} from "react-toastify";
import {get, put} from "../../services/Profile";
import guard from "../../actions/guard";

class ParentStudentProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            student_mapping: [],
            //validation
            isValid: false,
            loading: false,
            //image
            profile_picture: "https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png?1592828498",
        }
    }

    componentDidMount() {
        this.getProfile();
    }

    getProfile = () => {
        get(auth.check().id).then((res) => {
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    first_name: res.data.data.first_name,
                    last_name: res.data.data.last_name,
                    email: res.data.data.email,
                    phone_number: res.data.data.phone_number,
                    student_mapping: res.data.data.student_mapping,
                    profile_picture: res.data.data.details.profile_picture
                });
            }else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            toast.error("Something went wrong!");
        })
    }

    update = () => {
        const { first_name, last_name, email, phone_number, profile_picture, student_mapping } = this.state;
        let input = {
            basic: {
                first_name: first_name,
                last_name: last_name,
                email: email,
                phone_number: phone_number,
            },
            details: {
                profile_picture
            },
            student: []
        }
        this.setState({
            ...this.state,
            isValid: true,
            loading: true
        }, () => {
            if(validation.text(first_name)
                && validation.text(last_name)
                && validation.email(email)
                && validation.mobile(phone_number)
            ) {
                let flag = false;
                if(student_mapping.length === 0) {
                    flag = true;
                }
                for(let i = 0; i < student_mapping.length; i++) {
                    if(validation.text(student_mapping[i].student_user.first_name)
                        && validation.text(student_mapping[i].student_user.last_name)
                        && validation.email(student_mapping[i].student_user.email)
                        && validation.mobile(student_mapping[i].student_user.phone_number)
                    ) {
                        input.student.push({
                            id: student_mapping[i].student_user.id,
                            first_name: student_mapping[i].student_user.first_name,
                            last_name: student_mapping[i].student_user.last_name,
                            phone_number: student_mapping[i].student_user.phone_number,
                            details: {
                                profile_picture: student_mapping[i].profile_picture
                            }
                        });
                        flag = true;
                    }else {
                        flag = false;
                        break;
                    }
                }
                if(flag) {
                    put(auth.check().id, input).then((res) => {
                        this.setState({
                            ...this.state,
                            loading: false
                        })
                        if(res.status === 200) {
                            toast.success(res.data.message);
                            auth.update("first_name", input.basic.first_name);
                            auth.update("last_name", input.basic.last_name);
                        }else {
                            toast.error(res.data.message);
                        }
                    }).catch((err) => {
                        this.setState({
                            ...this.state,
                            loading: false
                        })
                        toast.error("Something went wrong!");
                    });
                }else {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    toast.error("Validation Error!");
                }
            }else {
                this.setState({
                    ...this.state,
                    loading: false
                })
                toast.error("Validation Error!");
            }
        })
    }

    onChange = (from, e) => {
        e.persist();
        if(from === "") {
            this.setState({
                ...this.state,
                [e.target.name]: e.target.value,
                isValid: false
            });
        }else {
            this.state.student_mapping[parseInt(from)].student_user[e.target.name] = e.target.value;
            this.setState({
                ...this.state,
                student_mapping: this.state.student_mapping,
                isValid: false
            });
        }
    };

    getFile = (data) => {
        if(data.status === "success") {
            if(data.prefix === "profile") {
                this.setState({
                    ...this.state,
                    loading: data.loading,
                    profile_picture: data.resp
                }, () => {
                    toast[data.status](data.msg);
                })
            }else {
                this.state.student_mapping[parseInt(data.prefix)].profile_picture = data.resp;
                this.setState({
                    ...this.state,
                    loading: data.loading,
                    student_mapping: this.state.student_mapping
                }, () => {
                    toast[data.status](data.msg);
                })
            }
        }else {
            toast[data.status](data.msg);
        }
	}

    render() {
        const { first_name, last_name, phone_number, email, profile_picture, student_mapping, isValid, loading } = this.state;
        
        return (
            <div className="profile-tab">
                <h5 className='bold serif mb-4'>Profile Settings</h5>
                <hr/>
                <div className="row">
                    <section className="col-md-2 mb-4">
                        <ProfileImgUpload
                            maxSize={5} 
                            response={this.getFile.bind(this)} 
                            prefix={"profile"} 
                            mimeTypes={['image/png', 'image/jpeg', 'image/jpg']} 
                            existingFile={profile_picture}
                        />
                    </section>
                    <section className="col-md-6 offset-md-1 px-md-4 py-md-3">
                        <label className="col-form-label text-teal bold mb-4 text-capitalize">{guard.role()} Details</label>
                        <div className='row'>
                            <div className='form-group col-md-6'>
                                <label className="txt-14" htmlFor='first_name'>First Name</label>
                                <div className=''>
                                    <input
                                        type='text'
                                        name='first_name'
                                        value={first_name}
                                        placeholder="Julian"
                                        onChange={this.onChange.bind(this, '')}
                                        className={`form-control light ${(isValid && (validation.text(first_name) ? '' : 'error is-invalid')).toString()}`}
                                    />
                                    <div className="invalid-feedback">
                                        Please enter your first name.
                                    </div>
                                </div>
                            </div>
                            <div className='form-group col-md-6'>
                                <label className="txt-14" htmlFor='last_name'>Last Name</label>
                                <div className=''>
                                    <input
                                        type='text'
                                        name='last_name'
                                        placeholder="Moore"
                                        value={last_name}
                                        onChange={this.onChange.bind(this, '')}
                                        className={`form-control light ${(isValid && (validation.text(last_name) ? '' : 'error is-invalid')).toString()}`}
                                    />
                                    <div className="invalid-feedback">
                                        Please enter your last name.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='form-group col-md-6'>
                                <label className="txt-14" htmlFor='first_name'>Email</label>
                                <div className='input-group'>
                                    <input
                                        type='email'
                                        name='first_name'
                                        className={`form-control light`}
                                        placeholder="julianmoore@gmail.com"
                                        disabled
                                        value={email}
                                    />
                                    <div className="invalid-feedback">
                                        Please enter your first name.
                                    </div>
                                    <div className='input-group-append'>
                                        <span className='input-group-text' id='last_name'>
                                            <FiCheckCircle className="text-primary"/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group col-md-6'>
                                <label className="txt-14" htmlFor='last_name'>Mobile Number</label>
                                <div className=''>
                                    <input type='text'
                                        name='phone_number'
                                        placeholder="512-408-6025"
                                        value={phone_number}
                                        onChange={this.onChange.bind(this, '')}
                                        className={`form-control light ${(isValid && (validation.mobile(phone_number) ? '' : 'error is-invalid')).toString()}`}
                                    />
                                    <div className="invalid-feedback">
                                        Please enter valid, 10 digits long mobile no.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className='profile-separate'/>
                    </section>
                </div>

                {/* student details */}
                {
                    student_mapping.length > 0 && student_mapping.map((student, key) => (
                        <div className="row" key={key}>
                            <section className="col-md-2 mb-4">
                                <ProfileImgUpload
                                    maxSize={5} 
                                    response={this.getFile.bind(this)} 
                                    prefix={key} 
                                    mimeTypes={['image/png', 'image/jpeg', 'image/jpg']} 
                                    existingFile={student.profile_picture}
                                />
                            </section>
                            <section className="col-md-6 offset-md-1 px-md-4 py-md-3">
                            <label className="col-form-label text-teal bold mb-4 text-capitalize">Student Details: {key + 1}</label>
                                <div className='row'>
                                    <div className='form-group col-md-6'>
                                        <label className="txt-14" htmlFor='first_name'>First Name</label>
                                        <div className=''>
                                            <input
                                                type='text'
                                                name='first_name'
                                                value={student.student_user.first_name}
                                                placeholder="Julian"
                                                onChange={this.onChange.bind(this, key)}
                                                className={`form-control light ${(isValid && (validation.text(student.student_user.first_name) ? '' : 'error is-invalid')).toString()}`}
                                            />
                                            <div className="invalid-feedback">
                                                Please enter your first name.
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <label className="txt-14" htmlFor='last_name'>Last Name</label>
                                        <div className=''>
                                            <input
                                                type='text'
                                                name='last_name'
                                                placeholder="Moore"
                                                value={student.student_user.last_name}
                                                onChange={this.onChange.bind(this, key)}
                                                className={`form-control light ${(isValid && (validation.text(student.student_user.last_name) ? '' : 'error is-invalid')).toString()}`}
                                            />
                                            <div className="invalid-feedback">
                                                Please enter your last name.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='form-group col-md-6'>
                                        <label className="txt-14" htmlFor='first_name'>Email</label>
                                        <div className='input-group'>
                                            <input
                                                type='email'
                                                name='first_name'
                                                className={`form-control light`}
                                                placeholder="julianmoore@gmail.com"
                                                disabled
                                                value={student.student_user.email}
                                            />
                                            <div className="invalid-feedback">
                                                Please enter your first name.
                                            </div>
                                            <div className='input-group-append'>
                                                <span className='input-group-text' id='last_name'>
                                                    <FiCheckCircle className="text-primary"/>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <label className="txt-14" htmlFor='last_name'>Mobile Number</label>
                                        <div className=''>
                                            <input type='text'
                                                name='phone_number'
                                                placeholder="512-408-6025"
                                                value={student.student_user.phone_number}
                                                onChange={this.onChange.bind(this, key)}
                                                className={`form-control light ${(isValid && (validation.mobile(student.student_user.phone_number) ? '' : 'error is-invalid')).toString()}`}
                                            />
                                            <div className="invalid-feedback">
                                                Please enter valid, 10 digits long mobile no.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className='profile-separate'/>
                            </section>
                        </div>
                    ))
                }
                
                <div className="d-flex justify-content-end profile-cta">
                    <button
                        className="btn btn-primary btn-padding--lg text-white text-uppercase"
                        onClick={this.update}
                        disabled={loading}>
                        Update
                    </button>
                </div>
            </div>
        );
    }
}

export default ParentStudentProfile;