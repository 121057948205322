/* eslint-disable */
import React, { Component } from 'react'
import Layout from "../components/Layout";
import TutorS1 from "../components/SignUp/TutorS1";
import { registration } from "../services/Auth";
import cookie from "../actions/cookie";
import {toast} from "react-toastify";
import _ from "lodash";
import PropTypes from "prop-types";

export default class SignupTutor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stepTitles: ['Residential Information', 'Academic & Work Qualification', 'Subjects', 'Additional Details', 'Upload Details', 'Confirmation'],
            signupStep: 0,
            payload: {},
            //validation
            loading: false,
        };
    }

    componentDidMount() {
        if(!cookie.getCookie("_token")) {
            let user = new URLSearchParams(this.props.location.search).get('user');
            if(user) {
                this.setState({
                    ...this.state,
                    email: user ? user : ""
                });
            }
        }else {
            this.props.history.push("/dashboard");
        }
    }

    getData = (data) => {
        const { signupStep } = this.state;
        this.setState({
            ...this.state,
            payload: {
                ...this.state.payload,
                ...data
            }
        }, () => {
            this.tutorRegistration();
        });
    }

    tutorRegistration = () => {
        const { payload } = this.state;
        const {history} = this.props;
        registration(payload).then((res) => {
            if(res.status === 201) {
                this.setState({
                    ...this.state,
                    loading: false,
                    signupStep: 6
                });
                history.push('/confirmation');
                toast.success("Registered Successfully! Check your mailbox, verify your email to login.");
            }else {
                throw new Error (Object.values(res.response.data).reduce((init, current) => (init + " " + current.toString()), ""));
            }
        }).catch((err) => {
            this.setState({
                ...this.state,
                loading: false
            });
            toast.error(err.message)
        });
    };

    render() {
        const { signupStep, stepTitles, payload, loading } = this.state;
        
        return (
            <Layout>
                <div className='layout-container'>
                    <div className="bg-img-block"></div>
                    <section className={`container signup-grid --tutor`}>
                        <div className='heading-content'>
                            <p className='small-title text-white mb-2'>BE A TUTOR</p>
                            <h2 className='mb-5 text-white'>
                                Make money by putting your academic skills to good use. Sign up as a tutor.
                            </h2>
                            <img src='/images/right-arrow.white.svg' alt='ArrowRight' className='flex-end' />
                        </div>
                        <div className='form-content'>
                            <TutorS1 getData={this.getData} payload={payload}/>
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

SignupTutor.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};
