/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import validation from "../../actions/validation";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
import { disabilityList, majors, states, racicalCategories } from "../../config/variables";
import DropZone from "../DropZone";

class ParentS2 extends Component {
	constructor(props) {
		const hobbyList = [
			{id: "Reading", name: "Reading"},
			{id: "Watching movies", name: "Watching movies"},
			{id: "Swimming", name: "Swimming"},
			{id: "Dancing", name: "Dancing"},
			{id: "Travelling", name: "Travelling"},
			{id: "Playing sports", name: "Playing sports"},
			{id: "Musical instruments", name: "Musical instruments"},
			{id: "Chess", name: "Chess"},
			{id: "Writing", name: "Writing"},
			{id: "Acting", name: "Acting"},
			{id: "Others", name: "Others"},
		]
		super(props);
		this.state = {
			students: [{
				first_name: "",
				last_name: "",
				email: "",
				phone_number: "",
				password: "",
				password_confirmation: "",
				role: "Student",
				gender: "",
				race: "",
				state: "",
				address: "",
				zip_code: "",
				dob: "",
				disablities: "",
				current_school: "",
				major: "",
				//image
				profile_picture: "",
				special_needs: "",
				current_grade: "",
				current_gpa: "",
				hobbies: [],
			}],
			//validation
			isValid: false,
			loading: false,
			hobbyList: hobbyList,
		};
	}

	componentDidUpdate(prevProps) {
        if(this.props.loading != prevProps.loading) {
            this.setState({
                ...this.state,
                loading: this.props.loading,
            });
        }
    }

	onChange = (i, e) => {
		e.persist();
		if (e.target.type === "checkbox") {
            if (e.target.checked) {
                this.state.students[i][e.target.name].push(e.target.value);
                this.setState({
                    ...this.state,
                    // [e.target.name]: this.state[e.target.name],
                    isValid: false
                })
            } else {
                let index = this.state.students[i][e.target.name].indexOf(e.target.value);
                this.state.students[i][e.target.name].splice(index, 1);
                this.setState({
                    ...this.state,
                    // [e.target.name]: this.state[e.target.name],
                    isValid: false
                })
            }
        } else {
			this.state.students[i][e.target.name] = e.target.value;
			this.setState({
				...this.state,
				isValid: false
			})
		}
    };
	
	onSubmit = () => {
		const { students } = this.state;
		let flag = false;
		this.setState({
            ...this.state,
            loading: true,
            isValid: true,
        }, () => {
			for(let i = 0; i < students.length; i++) {
				if(validation.text(students[i].first_name)
					&& validation.text(students[i].last_name)
					&& validation.mobile(students[i].phone_number)
					&& validation.email(students[i].email)
					&& validation.passwordStrength(students[i].password, "medium")
					&& validation.passwordStrength(students[i].password_confirmation, "medium")
					&& validation.text(students[i].role)
					&& students[i].password === students[i].password_confirmation
					&& validation.text(students[i].state)
					&& validation.text(students[i].dob)
					&& validation.text(students[i].address)
					&& validation.text(students[i].gender)
					&& validation.text(students[i].zip_code)
					&& validation.text(students[i].race)
					&& validation.text(students[i].disablities)
					&& validation.text(students[i].current_school)
					&& validation.text(students[i].major)
					&& validation.text(students[i].special_needs)
					&& validation.text(students[i].current_grade)
					&& validation.text(students[i].current_gpa)
				) {
					flag = true;
				}else {
					flag = false;
					break;
				}
			}
			if(flag) {
				this.props.getData(students);
			}else {
				this.setState({
					...this.state,
					loading: false
				});
				toast.error("Validation Error!");
			}
		});
	}

	getFile = (data) => {
		if(data.resp) {
			this.state.students[data.prefix].profile_picture = data.resp;
			this.setState({
				...this.state,
				loading: data.loading
			});
		}
	}

	addStudent = () => {
		let data = {
			first_name: "",
			last_name: "",
			email: "",
			phone_number: "",
			password: "",
			password_confirmation: "",
			role: "Student",
			gender: "",
			race: "",
			state: "",
			address: "",
			zip_code: "",
			dob: "",
			disablities: "",
			current_school: "",
			major: "",
			//image
			profile_picture: "",
			special_needs: "",
			current_grade: "",
            current_gpa: "",
			hobbies: []
		};
		this.setState({
            ...this.state,
			students: _.concat(this.state.students, data)
        });
	}

	render() {
		const { students, isValid, loading } = this.state;
		return(
		<React.Fragment>
			<h3 className='text-center text-dark mb-2'>Student Information</h3>
			<div id='signup-form'>
				{
					students.map((student, index) => (
						<section key={index}>
						<div className="mb-3"><span className="font-weight-bold">Student {index + 1}</span></div>
						<div className='form-row'>
							<div className='form-group col-md-6'>
								<label htmlFor='first_name'>First Name</label>
								<div className='input-group'>
									<input
										type='text'
										name='first_name'
										value={student.first_name}
										onChange={this.onChange.bind(this, index)}
										className={`form-control light ${(isValid && (validation.text(student.first_name) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										Please enter your first name.
									</div>
								</div>
							</div>
							<div className='form-group col-md-6'>
								<label htmlFor='last_name'>Last Name</label>
								<div className='input-group'>
									<input
										type='text'
										name='last_name'
										value={student.last_name}
										onChange={this.onChange.bind(this, index)}
										className={`form-control light ${(isValid && (validation.text(student.last_name) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										Please enter your last name.
									</div>
								</div>
							</div>
						</div>
						<div className='form-row'>
							<div className='form-group col-md-6'>
								<label htmlFor='email'>Email Address</label>
								<div className='input-group'>
									<input type='email'
											name='email'
											value={student.email}
											onChange={this.onChange.bind(this, index)}
											className={`form-control light ${(isValid && (validation.email(student.email) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										Please enter a valid email.
									</div>
								</div>
							</div>
							<div className='form-group col-md-6'>
								<label htmlFor='phone_number'>Phone Number</label>
								<div className='input-group'>
									<input type='text'
											name='phone_number'
											value={student.phone_number}
											onChange={this.onChange.bind(this, index)}
											className={`form-control light ${(isValid && (validation.mobile(student.phone_number) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										Please enter valid, 10 digits long mobile no.
									</div>
								</div>
							</div>
						</div>
						<div className='form-row'>
							<div className='form-group col-md-6'>
								<label htmlFor='password'>Password</label>
								<div className='input-group'>
									<input
										type='password'
										name='password'
										value={student.password}
										onChange={this.onChange.bind(this, index)}
										className={`form-control light ${(isValid && ((validation.passwordStrength(student.password, "medium") && student.password === student.password_confirmation) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										Password miss-matching.
									</div>
								</div>
							</div>
							<div className='form-group col-md-6'>
								<label htmlFor='password_confirmation'>Confirm Password</label>
								<div className='input-group'>
									<input
										type='password'
										name='password_confirmation'
										value={student.password_confirmation}
										onChange={this.onChange.bind(this, index)}
										className={`form-control light ${(isValid && ((validation.passwordStrength(student.password_confirmation, "medium") && student.password === student.password_confirmation) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										Password miss-matching.
									</div>
								</div>
							</div>
						</div>
							<div className='form-row'>
								<div className='form-group col-md-6'>
									<label htmlFor='state'>State of residence</label>
									<select className={`custom-select form-control light ${(isValid && (validation.text(student.state) ? '' : 'error is-invalid')).toString()}`}
											id="state" value={student.state}
											name="state"
											onChange={this.onChange.bind(this, index)}>
										<option value="">Choose One</option>
										{
											states.map((state, key) => (
												<option key={key} value={state}>{state}</option>
											))
										}
									</select>
									<div className="invalid-feedback">
										Select your state.
									</div>
								</div>
								<div className='form-group col-md-6'>
									<label htmlFor='dob'>Date of Birth</label>
									<input
										type='date'
										name='dob'
										id="dob"
										value={student.dob}
										onChange={this.onChange.bind(this, index)}
										className={`form-control light ${(isValid && (validation.text(student.dob) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										Select your dob.
									</div>
								</div>
							</div>
							<div className='form-row'>
								<div className='form-group col-md-6'>
									<label htmlFor='address'>Address</label>
									<input
										type='text'
										name='address'
										id="address"
										value={student.address}
										onChange={this.onChange.bind(this, index)}
										className={`form-control light ${(isValid && (validation.text(student.address) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										Type your address.
									</div>
								</div>
								<div className='form-group col-md-6'>
									<label htmlFor='gender'>Gender</label>
									<select className={`custom-select form-control light ${(isValid && (validation.text(student.gender) ? '' : 'error is-invalid')).toString()}`}
											id="gender" name="gender" value={student.gender}
											onChange={this.onChange.bind(this, index)}>
										<option value="">Choose One</option>
										<option value="Male">Male</option>
										<option value="Female">Female</option>
										<option value="Others">Others</option>
										<option value="I don't prefer to say">I don't prefer to say</option>
									</select>
									<div className="invalid-feedback">
										Select your gender.
									</div>
								</div>
							</div>
							<div className='form-row'>
								<div className='form-group col-md-6'>
									<label htmlFor='zip_code'>Zip Code</label>
									<input
										type='text'
										name='zip_code'
										id="zip_code"
										value={student.zip_code}
										onChange={this.onChange.bind(this, index)}
										className={`form-control light ${(isValid && (validation.text(student.zip_code) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										Select your zip-code.
									</div>
								</div>
								<div className='form-group col-md-6'>
									<label htmlFor='race'>Race</label>
									<select className={`custom-select form-control light ${(isValid && (validation.text(student.race) ? '' : 'error is-invalid')).toString()}`}
											id="race" name="race" value={student.race} onChange={this.onChange.bind(this, index)}>
										<option value="">Choose One</option>
										{
											racicalCategories.map((race, key) => (
												<option key={key} value={race}>{race}</option>
											))
										}
									</select>
									<div className="invalid-feedback">
										Select your race.
									</div>
								</div>
							</div>
							<div className='form-row mb-3'>
								<div className='form-group col-md-6'>
									<label htmlFor='disablities'>Disabilities</label>
									<select className={`custom-select form-control light ${(isValid && (validation.text(student.disablities) ? '' : 'error is-invalid')).toString()}`}
											id="disablities"
											name="disablities"
											value={student.disablities} onChange={this.onChange.bind(this, index)}>
										<option value="">Choose One</option>
										{
											disabilityList.map((disability, key) => (
												<option key={key} value={disability}>{disability}</option>
											))
										}
									</select>
									<div className="invalid-feedback">
										If you don't have any, select none.
									</div>
								</div>
								<div className='form-group col-md-6'>
									<label htmlFor='current_school'>Current School</label>
									<input
										type='text'
										name='current_school'
										id="current_school"
										value={student.current_school}
										onChange={this.onChange.bind(this, index)}
										className={`form-control light ${(isValid && (validation.text(student.current_school) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										Type your school name.
									</div>
								</div>
							</div>
							<div className='form-row'>
								<div className='form-group col-md-6'>
									<label htmlFor='major'>Major</label>
									<input
										type='text'
										name='major'
										id="major"
										value={student.major}
										onChange={this.onChange.bind(this, index)}
										className={`form-control light ${(isValid && (validation.text(student.major) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										Type your major.
									</div>
								</div>
								<div className='form-group col-md-6'>
									<label htmlFor='current_grade'>Current Grade</label>
									<input
										type='text'
										name='current_grade'
										id="current_grade"
										value={student.current_grade}
										onChange={this.onChange.bind(this, index)}
										className={`form-control light ${(isValid && (validation.text(student.current_grade) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										Grade is required.
									</div>
								</div>
							</div>
							<div className='form-row'>
							<div className='form-group col-md-6'>
								<label htmlFor='current_gpa'>Current GPA (Prior to start of session)</label>
								<input
									type='number'
									name='current_gpa'
									id="current_gpa"
									value={student.current_gpa}
									onChange={this.onChange.bind(this, index)}
									className={`form-control light ${(isValid && (validation.text(student.current_gpa) ? '' : 'error is-invalid')).toString()}`}
								/>
								<div className="invalid-feedback">
									GPA is required.
								</div>
							</div>
								<div className='form-group col-md-6'>
									<label htmlFor='special_needs'>Do you have any special needs?</label>
									<input
										type='text'
										name='special_needs'
										id="special_needs"
										value={student.special_needs}
										onChange={this.onChange.bind(this, index)}
										className={`form-control light ${(isValid && (validation.text(student.special_needs) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										Type your need (if any) or none.
									</div>
								</div>
							</div>
							<div className='form-row'>
								<div className='form-group col-md-12' style={{paddingTop: '30px', paddingBottom: '30px'}}>
									<label htmlFor='hobbies'>Choose hobbies and interests</label>
									<div className="checkbox-grid-hobbies">
										{this.state.hobbyList.map((hobby, key) => {
											return (
												<div className='custom-control custom-checkbox' key={key}>
													<input
														type='checkbox'
														className='custom-control-input'
														id={hobby.id}
														value={hobby.id}
														name="hobbies"
														onChange={this.onChange.bind(this, index)}
													/>
													<label className={`custom-control-label checkbox-2`} htmlFor={hobby.id}>
														{hobby.name}
													</label>
												</div>
											)
										})}
									</div>
								</div>
							</div>
							<div className="text-center">
								<label className="mb-1">
									Upload clear photograph 
								</label>
								<DropZone 
									maxSize={5} 
									response={this.getFile.bind(this)} 
									prefix={index} 
									mimeTypes="image/png, image/jpeg, image/jpg"
								/>
							</div>
						</section>
					))
				}
				<div className='d-flex justify-content-center align-items-center mt-5'>
					<button
						disabled={loading}
						className='btn btn-link btn-padding--lg text-primary'
						onClick={this.addStudent}
					>
						<FaPlus size={24}/> ADD STUDENT
					</button>
				</div>
				<div className='d-flex justify-content-center align-items-center mb-5'>
					<button
						disabled={loading}
						className='btn btn-primary btn-padding--lg text-white'
						onClick={this.onSubmit}
					>
						Continue
					</button>
				</div>
			</div>
		</React.Fragment>
		);
	}
}

export default ParentS2;