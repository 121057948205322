/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Button } from 'react-bootstrap';
import './TutorListing.style.css'
import { MdStar, MdArrowDropDown, MdArrowDropUp, MdNavigateBefore, MdNavigateNext } from 'react-icons/md'
import { Link } from 'react-router-dom';
import Select from 'react-select';
import _ from "lodash";
import { subjectList } from "../../../services/Common";
import { findTutor } from "../../../services/Tutor";
import auth from "../../../actions/auth";
import { states, dayList, occupations } from "../../../config/variables";
import {get} from "../../../services/Profile";
import SelectStudent from '../../Modal/SelectStudent';
import { FaLevelUpAlt } from 'react-icons/fa';

const LIMIT = 5;

export default class TutorListing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      subjectList: [],
      tutors: [],
      tutorLoading: true,
      students: null,
      filter: {
        location: "",
        subject: [],
        gender: "",
        zip: "",
        availability: [],
        qualification: [],
        limit: LIMIT,
        sort: {
          first_name: "ASC"
        }
      },
      pages: [],
      currentPage: 1,
      set: 0,
      openSelectStudentModal: false,
      role: null,
      parent_id: null,
      tutor_id: null
    };
  }

  closeSelectStudentModal = () => {
    this.setState({
      openSelectStudentModal: false
    })
  }

  componentDidMount() {
      this.fetchSubList();
      this.getList();
      console.log(auth.check().role_id)
      if(auth.check().role_id == 3) {
        this.getProfile();
        this.setState({
          role: 3
        })
      } else if(auth.check().role_id == 4) {
        this.setState({
          role: 4
        })
      }
      
  }

  getProfile = () => {
    get(auth.check().id).then((res) => {
        console.log(res)
        if(res.status === 200) {
            const data = res.data.data;
            this.setState({
                ...this.state,
                students: res.data?.data?.student_mapping,
            });
        }else {
            toast.error("Something went wrong!");
        }
    }).catch((err) => {
        toast.error("Something went wrong!");
    })
}

  viewDetail = (id) => {
    this.props.history.push('/tutor/detail/'+id);
  }

  fetchSubList = () => {
    subjectList().then((res) => {
      if (res.status === 200) {
        this.setState({
          ...this.state,
          subjectList: Object.values(res.data.subjects).map((sub) => (
              {
                label: sub[0].subject_group_name,
                options: sub.map((inner) => (
                  { value: inner.id, label: inner.sub_subject_name }
                ))
              }
            ))
        })
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  onChange = (form, e) => {
      e.persist();
      if(form === "") {
        if (e.target.type === "checkbox") {
          if (e.target.checked) {
              this.state.filter[e.target.name].push(e.target.value);
              this.setState({
                  ...this.state,
                  filter: {
                    ...this.state.filter,
                    [e.target.name]: this.state.filter[e.target.name]
                  }
              })
          } else {
              let index = this.state.filter[e.target.name].indexOf(e.target.value);
              this.state.filter[e.target.name].splice(index, 1);
              this.setState({
                  ...this.state,
                  filter: {
                    ...this.state.filter,
                    [e.target.name]: this.state.filter[e.target.name]
                  }
              })
          }
        }else {
          this.setState({
            ...this.state,
            filter: {
              ...this.state.filter,
              [e.target.name]: e.target.value
            }
          });
        }
      }else {
        this.setState({
          ...this.state,
          filter: {
            ...this.state.filter,
            [form]: {
              ...this.state.filter[form],
              [e.target.name]: this.state.filter[form][e.target.name] === "ASC" ? "DESC" : "ASC"
            }
          }
        }, () => {
          this.onFilter("apply");
        });
      }
  };

  getList = () => {
    this.setState({
      ...this.state,
      tutorLoading: true
    }, () => {
      findTutor({...this.state.filter, qualification: this.state.filter.qualification.toString()}, this.state.currentPage).then((res) => {
        if (res.status === 200) {
          this.setState({
            ...this.state,
            tutorLoading: false,
            tutors: res.data.data,
            pages: _.chunk([...Array(Math.ceil(res.data.total / LIMIT))].map((el, key) => key + 1), LIMIT)
          });
        }
      }).catch((err) => {
        console.log(err);
      })
    });
  };

  paginator = (type) => {
    if (_.isInteger(type)) {
      this.setState({
        ...this.state,
        currentPage: type
      }, () => {
        this.getList();
      });
    } else {
      if (type === "next" && this.state.set < this.state.pages.length - 1) {
        this.setState({
          ...this.state,
          set: this.state.set + 1
        });
      } else if (type === "prev" && this.state.set > 0) {
        this.setState({
          ...this.state,
          set: this.state.set - 1
        });
      }
    }
  }

  onFilter = (type = "apply") => {
    if (type === "apply") {
      this.getList();
    } else {
      this.setState({
        ...this.state,
        currentPage: 1,
        offset: 0,
        filter: {
          ...this.state.filter,
          location: "",
          subject: [],
          gender: "",
          zip: "",
          availability: [],
          qualification: [],
          limit: LIMIT,
          sort: {
            first_name: "ASC"
          }
        }
      }, () => {
        this.getList();
      });
    }
  };

  onSubmit = (id) => {
    if(this.state.role == 3) {
      this.setState({
        openSelectStudentModal: true,
        tutor_id: id,
        parent_id: auth.check().id
      })
    } else {
      this.props.getData({
        tutor_id: id,
        parent_id: auth.check().id
      });
    }
    
  }

  bookTutor = (studentId, student_name, student_profile) => {
    console.log('Book Tutor', studentId)
    this.props.getData({
      tutor_id: this.state.tutor_id,
      parent_id: this.state.parent_id,
      student_id: studentId,
      student_name: student_name,
      student_profile: student_profile
    });
  }

  render() {
    const { subjectList, filter, tutors, pages, set, tutorLoading, currentPage } = this.state;
    
    return (
      <React.Fragment>
        <section className='menu-bar__filter'>
          <label className='bold mb-0 mr-2 d-md-none'>Sort by:</label>
          <div className='d-flex align-items-center'>
            <label className='bold mb-0 mr-2 d-none d-md-block'>Sort by:</label>
            <div className="btn-group mr-2">
              <button className="btn custom-btn-dropdown text-teal btn-sm" type="button" name="first_name" onClick={this.onChange.bind(this, "sort")}>
                Name 
                {
                  filter.sort.first_name === "ASC" ?
                  <MdArrowDropUp size={24}/>
                  :
                  <MdArrowDropDown size={24}/>
                }
              </button>
            </div>
            <div className="btn-group mr-2">
              <button className="btn custom-btn-dropdown text-teal btn-sm" type="button">
                Location 
                {
                  filter.sort.location === "ASC" ?
                  <MdArrowDropUp size={24}/>
                  :
                  <MdArrowDropDown size={24}/>
                }
              </button>
            </div>
            <div className="btn-group">
              <button className="btn custom-btn-dropdown text-teal btn-sm" type="button">
                Rating 
                {
                  filter.sort.rating === "ASC" ?
                  <MdArrowDropUp size={24}/>
                  :
                  <MdArrowDropDown size={24}/>
                }
              </button>
            </div>
          </div>
          <div className='d-flex align-items-center'>
            <label className='bold mb-0 mr-2'>Custom filter:</label>
            <Dropdown>
              <Dropdown.Toggle variant="btn custom-btn-dropdown text-teal" id="dropdown-filter">
                Filter
              </Dropdown.Toggle>
              <Dropdown.Menu className='dropdown-filter'>
                <h3 className='role'>Filter</h3>
                <div className="filter-dropdown__inner-grid">
                  <div className='left-side'>
                    <div className="form-group">
                      <label htmlFor="subject" className='txt-14 bold text-teal'>Subject</label>
                      <Select id='subject' className={`basic-multi-select`} isMulti
                        options={subjectList} onChange={(e) => this.setState({...this.state, filter: { ...this.state.filter, subject: e.map((sub) => sub.value) ? e.map((sub) => sub.value) : [] }})}/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="gender" className='txt-14 bold text-teal'>Gender</label>
                      <select name="gender" id="gender" className='custom-select' value={filter.gender} onChange={this.onChange.bind(this, "")}>
                        <option value="">Choose One</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Others">Others</option>
                        <option value="I don't prefer to say">I don't prefer to say</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="location" className='txt-14 bold text-teal'>Location</label>
                      <select className={`custom-select`}
                            id="location" value={filter.location}
                            name="location"
                            onChange={this.onChange.bind(this, "")}>
                        <option value="">Choose One</option>
                        {
                            states.map((state, key) => (
                                <option key={key} value={state}>{state}</option>
                            ))
                        }
                    </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="zip" className='txt-14 bold text-teal'>Zip Code</label>
                      <input type="number" className="form-control" id="zip" name="zip" placeholder="76094" value={filter.zip} onChange={this.onChange.bind(this, "")}/>
                    </div>
                  </div>
                  <div className='right-side'>
                    <div className='form-group mb-3'>
                      <label htmlFor='dayweek' className='txt-14 bold text-teal text-capitalize'>days of the week</label>
                      <div className='select-day-grid'>
                            {
                              dayList.length > 0 && dayList.map((day, index) => (
                                  <div className='custom-control custom-checkbox' key={index}>
                                      <input
                                          type='checkbox'
                                          className='custom-control-input'
                                          id={day}
                                          value={day}
                                          name="availability"
                                          onChange={this.onChange.bind(this, "")}
                                      />
                                      <label className={`custom-control-label checkbox-2`} htmlFor={day}>
                                          {day}</label>
                                  </div>
                              ))
                          }
                      </div>
                    </div>
                    <div className='form-group mb-5'>
                    <label htmlFor='qualification' className='txt-14 bold text-teal text-capitalize'>Qualification of tutor</label>
                      {
                        occupations.map((val, key) => (
                          <div className='custom-control custom-checkbox mb-2' key={key}>
                              <input
                                type='checkbox'
                                className='custom-control-input'
                                id={val}
                                value={val}
                                name="qualification"
                                onChange={this.onChange.bind(this, "")}
                              />
                              <label className={`custom-control-label checkbox-2 `} htmlFor={val}>
                                {val}
                              </label>
                            </div>
                        ))
                      }
                    </div>
                    <button className="btn btn-primary-darker btn-padding--md text-white mr-1" onClick={this.onFilter.bind(this, "apply")}> Done</button>
                    <button className="btn btn-outline-primary btn-padding--md" onClick={this.onFilter.bind(this, "reset")}> Reset</button>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </section>
        <div className="text-container pt-5">
          <h3 className='role text-center'> Results({tutors.length})</h3>
          {
            tutors.length > 0 && tutors.map((tutor, index) => (
              <article className='tutor-article' key={index}>
                {
                    tutor.profile_picture === "" ?
                        <img src="/images/tutor-1.png" alt="tutor-1" className='mr-md-3'/>
                        :
                        <img src={tutor.profile_picture} alt="tutor-1" className='mr-md-3'/>
                }
                <div className="tutor-details">
                  <div className="top-section mb-2">
                    <div className="row align-items-center no-gutters">
                      <div className="d-flex align-items-center col-md-8">
                        <h3 className='mr-3 mb-0 text-capitalize'>{tutor.first_name} {tutor.last_name}</h3>
                        <div className='online-badge' />
                      </div>
                      <div className="flex-row mb-2">
                        <MdStar fontSize="24px" className='text-warning' />
                        <MdStar fontSize="24px" className='text-warning' />
                        <MdStar fontSize="24px" className='text-warning' />
                        <MdStar fontSize="24px" className='text-warning' />
                        <MdStar fontSize="24px" className='text-warning mr-3' />
                        <span className='mt-1' style={{ color: '#667C8A' }}>20 reviews</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <p style={{ color: '#435D6B' }}>“I was born and raised in Houston, Texas and I am a recent graduate of Texas Tech University. I majored in Mathematics with a minor in Economics”.</p>
                      </div>
                    </div>
                  </div>
                  <div className="bottom-section">
                    <div className="row no-gutters">
                      <div className="col-md-7 flex-column mb-4 mb-lg-0">
                        <div className="flex-row mb-2">
                          <svg width="16" height="16" className='mr-2' viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5714 12.8548V1.42871L14.1355 1.46526C12.387 1.60979 10.672 2.02776 9.05316 2.7041L8.28572 3.02274V14.4036L8.84459 14.1713C10.5198 13.4743 12.2935 13.0431 14.1017 12.893L14.5714 12.8548Z" fill="#009BA7" />
                            <path d="M16 3.11768H15.1429V13.1177C15.1434 13.2667 15.0292 13.3911 14.8806 13.4034L14.1489 13.4634C13.9376 13.4808 13.727 13.5024 13.5172 13.5283C13.4491 13.5365 13.3814 13.5474 13.3138 13.5569C13.1723 13.5757 13.0311 13.5948 12.8903 13.6171C12.8083 13.6302 12.7268 13.6456 12.6451 13.6599C12.5191 13.682 12.3929 13.7039 12.2672 13.7287C12.1814 13.7459 12.0957 13.7651 12.0082 13.7837C11.8885 13.8091 11.7688 13.835 11.65 13.8634C11.5608 13.8846 11.472 13.907 11.3834 13.9296C11.2677 13.9582 11.1526 13.989 11.0377 14.0211C10.9482 14.0457 10.8588 14.0716 10.7697 14.0979C10.6554 14.1316 10.5428 14.1659 10.43 14.2019C10.3417 14.2305 10.2532 14.2591 10.1652 14.2876C10.0522 14.3257 9.93973 14.365 9.8277 14.4056C9.74204 14.4367 9.65625 14.468 9.56863 14.5008C9.52943 14.5157 9.49051 14.5316 9.45145 14.5468H16V3.11768Z" fill="#009BA7" />
                            <path d="M1.86454 1.46526L1.42857 1.42871V12.8556L1.93569 12.8987C3.74275 13.0498 5.51493 13.4837 7.18736 14.1844L7.71429 14.4036V3.02274L6.94769 2.70438C5.32869 2.0279 3.61342 1.60979 1.86454 1.46526Z" fill="#009BA7" />
                            <path d="M0 3.11768V14.5462H6.55315C6.518 14.5324 6.48284 14.5176 6.44741 14.5045C6.36565 14.4734 6.2832 14.4437 6.20089 14.4137C6.08482 14.3716 5.96889 14.3306 5.85198 14.2914C5.76744 14.2628 5.68262 14.235 5.59766 14.2079C5.48172 14.1699 5.36537 14.134 5.24888 14.1005C5.16309 14.0754 5.07743 14.0502 4.99177 14.0262C4.8743 13.9937 4.75656 13.9628 4.63853 13.9325C4.55287 13.9105 4.46708 13.8885 4.37946 13.868C4.25977 13.8394 4.13937 13.8131 4.01911 13.7873C3.93345 13.7688 3.84766 13.7499 3.762 13.7328C3.63742 13.7082 3.51228 13.6862 3.38714 13.6643C3.30511 13.6499 3.22307 13.6357 3.1409 13.621C3.00279 13.5991 2.86426 13.5806 2.72573 13.5619C2.65569 13.5525 2.58622 13.5414 2.51632 13.5334C2.30678 13.508 2.09724 13.4864 1.8877 13.4685L1.11858 13.4034C0.970285 13.3907 0.856585 13.2664 0.857143 13.1177V3.11768H0Z" fill="#009BA7" />
                          </svg>

                          <span style={{ color: '#435D6B' }}>{tutor.sub_subject_name}</span>
                        </div>
                        <div className="flex-row mb-2">
                          <svg width="17" height="16" className='mr-2' viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.89145 6C2.87631 5.83525 2.86861 5.66875 2.86861 5.5C2.86887 2.4625 5.39018 0 8.50012 0C11.6101 0 14.1314 2.4625 14.1314 5.5C14.1314 5.6685 14.1237 5.83525 14.1085 6H14.1314C14.1314 9.00575 8.50012 16 8.50012 16C8.50012 16 2.86887 9.02225 2.86887 6H2.89145ZM8.50012 7C9.70845 7.032 10.7162 6.13725 10.7526 5C10.7162 3.86275 9.70845 2.968 8.50012 3C7.29179 2.968 6.28401 3.86275 6.24762 5C6.28401 6.13725 7.29179 7.032 8.50012 7Z" fill="#009BA7" />
                          </svg>

                          <span style={{ color: '#435D6B' }}>{tutor.address}, {tutor.state}</span>
                        </div>
                      </div>
                      <div className="col-md-4 offset-md-1 d-flex justify-content-end align-self-center flex-column">
                        <button className="btn btn-primary-darker btn-padding--md text-white mb-3 mx-auto mx-md-0 text-capitalize" onClick={this.onSubmit.bind(this, tutor.id)}>Book {tutor.first_name}</button>
                        <Link className="btn btn-outline-primary-darker btn-padding--md mb-3 mx-auto mx-md-0 text-capitalize" to={'/tutor/detail/'+tutor.id}>View Detail</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            ))
          }
        </div>
        <nav aria-label="Page navigation">
          <ul className='pagination'>
            {
              !tutorLoading && tutors.length > 0 && set > 0 &&
              <li className="page-item active" onClick={this.paginator.bind(this, "prev")}>
                <MdNavigateBefore className="pointer" size={40} />
              </li>
            }
            {
              !tutorLoading && tutors.length > 0 && pages[set].length > 0 && pages[set].map((page, index) => (
                <li className={`page-item ${currentPage === page ? "active" : ""}`} key={index} onClick={this.paginator.bind(this, page)}>
                  <button className="page-link">{page}</button>
                </li>
              ))
            }
            {
              !tutorLoading && tutors.length > 0 && set < pages.length - 1 &&
              <li className="page-item active" onClick={this.paginator.bind(this, "next")}>
                <MdNavigateNext className="pointer" size={40} />
              </li>
            }
          </ul>
        </nav>
        {this.state.role == 3 && <SelectStudent open={this.state.openSelectStudentModal} handleClose={this.closeSelectStudentModal} students={this.state.students} bookTutor={this.bookTutor} />}
      </React.Fragment>
    )
  }
}
