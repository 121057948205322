import React from 'react';
import Rating from '@material-ui/lab/Rating';

const WhatRatingMeans = () => {
    return (
        <div className="rating-chart">
            <h4 className="title">What ratings mean</h4>
            <div>
                <div>
                    <h5>Very good</h5>
                    <Rating defaultValue="5" />
                </div>
                <div>
                    <h5>Good</h5>
                    <Rating defaultValue="4" max={4} />
                </div>
                <div>
                    <h5>Average</h5>
                    <Rating defaultValue="3" max={3} />
                </div>
                <div>
                    <h5>Poor</h5>
                    <Rating defaultValue="2" max={2} />
                </div>
                <div>
                    <h5>Very Poor</h5>
                    <Rating defaultValue="1" max={1} />
                </div>
            </div>
        </div>
    )
}

export default WhatRatingMeans;