/* eslint-disable */
import React, {useCallback, useState, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import { putFile } from "../services/Common";
import { toast } from "react-toastify";
 
function DropZone({prefix, maxSize, mimeTypes, response, existingFile}) {
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState("");

	const onDrop = useCallback(files => {
		if (files.length > 0) {
			setLoading(true);
			setName("");
			response({prefix: prefix, loading: true, status: "success", msg: "Uploading...", resp: ""});
			const avatar = files[0];
            putFile(avatar).then(res => {
				if(res.status === 200) {
					setLoading(false);
					setName(avatar.name);
					response({prefix: prefix, loading: false, status: "success", msg: res.data.message, resp: res.data.upload_path});
				}else {
					setLoading(false);
					response({prefix: prefix, loading: false, status: "error", msg: res.data.message, resp: ""});
				}
			}).catch(err => {
				toast.error(err.message)
			});
        }
	}, []);

	const {fileRejections, getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: mimeTypes, multiple: false, minSize: 0, maxSize: maxSize * 1048576});
	
	useEffect(() => {
		if(fileRejections.length > 0) {
			response({prefix: prefix, loading: false, status: "error", msg: fileRejections[0].errors[0].message, resp: null});
		}
	}, [fileRejections]);

	useEffect(() => {
		if(existingFile && existingFile !== "") {
			setName(existingFile.split("/")[existingFile.split("/").length - 1]);
		}
	}, [existingFile]);

	return (
		<div {...getRootProps({className: "dropzone file-upload-component mt-0"})}>
		<input {...getInputProps()} />
			{
				loading ?
				<h5 className="mb-1">File Uploadig...</h5>
				:
				name === "" ?
					<>
						{
							isDragActive ?
							<h5 className="mb-1">Drop the file here...</h5>
							:
							<>
								<h5 className="mb-1">Drop file here or <span>browse</span> for file to upload</h5>
								<p className="text-center">(Formats: {mimeTypes} File should not be more than {maxSize}mb).</p>
							</>
						}
					</>
					:
					<>
						<h5 className="mb-1"><span>Successfully</span> Uploaded.</h5>
						<p>{name}</p>
					</>
			}
		</div>
	)
}

export default DropZone;