/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import validation from "../../actions/validation";
import { toast } from "react-toastify";
import { majors } from "../../config/variables";

class StudentS2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
            current_grade: "",
            current_gpa: "",
			current_school: "",
			major: "",
			//validation
			isValid: false,
			loading: false,
		};
    }

    componentDidUpdate(prevProps) {
        if(this.props.loading != prevProps.loading) {
            this.setState({
                ...this.state,
                loading: this.props.loading,
            });
        }
    }
    
    onChange = (e) => {
        e.persist();
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            isValid: false
        })
    };
	
	onSubmit = () => {
		const { major, current_school, current_grade, current_gpa } = this.state;
		this.setState({
            ...this.state,
            loading: true,
            isValid: true,
        }, () => {
            if(validation.text(major)
				&& validation.text(current_school)
				&& validation.text(current_grade)
				&& validation.text(current_gpa)
                ) {
                const data = JSON.parse(JSON.stringify(this.state));
                this.props.getData(_.omit(data, ['isValid', 'loading']));
            }else {
                this.setState({
                    ...this.state,
                    loading: false
                });
                toast.error("Validation Error!")
            }
		});
	}

	render() {
		const { major, current_school, current_grade, current_gpa, isValid, loading} = this.state;

        return(
		<React.Fragment>
            <h3 className='text-center text-dark mb-4'>Academic Details</h3>
            <div id='signup-form'>
                <div className='form-row'>
                <div className='form-group col-md-6'>
                        <label htmlFor='current_school'>Current School</label>
                        <input
                            type='text'
                            name='current_school'
                            id="current_school"
                            value={current_school}
                            onChange={this.onChange}
                            className={`form-control light ${(isValid && (validation.text(current_school) ? '' : 'error is-invalid')).toString()}`}
                        />
                        <div className="invalid-feedback">
                            Type your school name.
                        </div>
                    </div>
                    <div className='form-group col-md-6'>
                        <label htmlFor='major'>Major</label>
                        <input
                            type='text'
                            name='major'
                            id="major"
                            value={major}
                            onChange={this.onChange}
                            className={`form-control light ${(isValid && (validation.text(major) ? '' : 'error is-invalid')).toString()}`}
                        />
                        <div className="invalid-feedback">
                            Select your major.
                        </div>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='form-group col-md-6'>
                        <label htmlFor='current_grade'>Current Grade</label>
                        <input
                            type='text'
                            name='current_grade'
                            id="current_grade"
                            value={current_grade}
                            onChange={this.onChange}
                            className={`form-control light ${(isValid && (validation.text(current_grade) ? '' : 'error is-invalid')).toString()}`}
                        />
                        <div className="invalid-feedback">
                            Grade is required.
                        </div>
                    </div>
                    <div className='form-group col-md-6'>
                        <label htmlFor='current_gpa'>Current GPA (Prior to start of session)</label>
                        <input
                            type='number'
                            name='current_gpa'
                            id="current_gpa"
                            value={current_gpa}
                            onChange={this.onChange}
                            className={`form-control light ${(isValid && (validation.text(current_gpa) ? '' : 'error is-invalid')).toString()}`}
                        />
                        <div className="invalid-feedback">
                            GPA is required.
                        </div>
                    </div>
                </div>

				<div className='d-flex justify-content-center align-items-center my-5'>
					<button
						disabled={loading}
						className='btn btn-primary btn-padding--lg text-white'
						onClick={this.onSubmit}
					>
						Continue
					</button>
				</div>
            </div>
        </React.Fragment>
        );
	}
}

export default StudentS2;