import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import Sidebar from './Sidebar';
import { MdMenu, MdClose, MdPowerSettingsNew } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import cookie from '../../actions/cookie';
import auth from '../../actions/auth';
import './Navbar.css';

export default function AuthNav({ path }) {

  const history = useHistory();

  const navFunc = () => {
    const showNav = () => {
      document.querySelector(".mobile-nav").classList.add("show");
      document.querySelector(".mobile-header--overlay").classList.add("show");
    };
    const hideNav = () => {
      document.querySelector(".mobile-nav").classList.remove("show");
      document
        .querySelector(".mobile-header--overlay")
        .classList.remove("show");
    };
    document.querySelector(
      ".mobile-header--icon"
    ).onclick = function() {
      showNav();
    };
    document.querySelector(".mobile-nav--close").onclick = function() {
      hideNav();
    };
    document.querySelector(
      ".mobile-header--overlay"
    ).onclick = function() {
      hideNav();
    };
    document.querySelector(
      "aside .sidebar-nav-item"
    ).onclick = function() {
      hideNav();
    };
  };

  useEffect(() => {
    // calling navFunc
    navFunc();
  }, []);

  const logout = () => {
    cookie.deleteCookie("_token");
    cookie.deleteCookie("_session");
    history.push("/");
  }

  return (
    <React.Fragment>
      <header className="fixed-header bx-small desktop-header">
        <div className="container-fluid px-5 py-2">
          <div className="d-flex justify-content-between align-items-center">
            <div className="logo-wrapper">
              <Link to="/">
                <img src="/images/logo.svg" alt="site-logo"/>
              </Link>
            </div>
            <nav className="nav-wrapper" />
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center pointer" onClick={logout}>
                <MdPowerSettingsNew className="mr-1 text-grey" fontSize="22px"/>
                <div className="txt-14 font-weight-bold text-uppercase">Logout</div>
              </div>
              <div className="vl"></div>
              <Link to='profile'>
                <div className="icn-circle">
                  <img src={`https://ui-avatars.com/api/?name=${auth.check().first_name}`} alt="user" />
                </div>
              </Link>
            </div>
              
          </div>
        </div>
      </header>
      <div className='desktop-header'>
        <Sidebar path={path} mode="sidebar" /> 
      </div>
      <div className="mobile-header">
        <header aria-label="mobile-header" className="fixed-header">
          <div className="d-flex align-items-center h-100 position-relative">
            <div className="mobile-header--icon pointer">
              <MdMenu fontSize="24px" />
            </div>
            <div className="logo-wrapper center-hr">
              <Link to="/">
                <img src="/images/logo.svg" alt="site-logo"/>
              </Link>
            </div>
          </div>
        </header>

        <div className="mobile-header--overlay" />

        <aside className="mobile-nav">
          <div className="mobile-nav--close pointer">
            <div className="middle">
              <Link to="/">
                <img src="/images/logo.svg" alt="site-logo"/>
              </Link>
            </div>
            <div className="rounded-circle border bg-light p-2 mr-3">
              <MdClose fontSize="22px" />
            </div>
          </div>
          <div className="nav-wrapper">
            <Sidebar path={path} mode="list" /> 
          </div>
        </aside>
      </div>
    </React.Fragment>
  )
}

AuthNav.propTypes = {
  path: PropTypes.string
}