export const states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
];

export const racicalCategories = [
    'American Indian or Alaska Native',
    'Asian',
    'Black or African American',
    'Hispanic or Latino',
    'Native Hawaiian or Other Pacific Islander',
    'White',
    'I don\'t prefer to say'
];

export const occupations = [
    'Doctor',
    'Engineer',
    'Consultant',
    'Educator',
    'Home Maker'
];

export const disabilityList = [
    "None",
    "Autism",
    "Chronic Illness",
    "Hearing Loss and Deafness",
    "Intellectual Disability",
    "Learning Disability",
    "Memory Loss",
    "Mental Health",
    "Physical Disabilities",
    "Speech and Language Disorders",
    "Vision Loss and Blindness"
];

export const majors = [
    'Science',
    'Commerce',
    'Arts'
];

export const dayList = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
];

export const educations = [
    'High School',
    'Some College',
    "Bachelor's degree",
    'Master Degree',
    'Doctoral Degree',
];


