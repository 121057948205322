/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import validation from "../../actions/validation";
import { toast } from "react-toastify";
import { FiUser, FiCheck, FiLock, FiPhoneForwarded } from "react-icons/fi";
import { racicalCategories } from "../../config/variables";

class TutorS5 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			work_in_usa: "",
            criminal_records: "",
            student_with_disability: "",
            reliable_transportation: "",
            group_tutoring: "",
            home_school_help: "",
            required_hours: "",
            languages: "",
            other_informations: "",
			//validation
			isValid: false,
			loading: false
		};
	}

	onChange = (e) => {
        e.persist();
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            isValid: false
        })
    };
	
	onSubmit = () => {
		const { work_in_usa, criminal_records, student_with_disability, reliable_transportation, group_tutoring, home_school_help, required_hours, languages, other_informations } = this.state;
		this.setState({
            ...this.state,
            loading: true,
            isValid: true,
        }, () => {
			if(validation.text(work_in_usa)
                && validation.text(criminal_records)
                && validation.text(student_with_disability)
                && validation.text(reliable_transportation)
                && validation.text(group_tutoring)
                && validation.text(home_school_help)
                && validation.text(required_hours)
                && validation.text(languages)
                && validation.text(other_informations)
			) {
				const data = JSON.parse(JSON.stringify(this.state));
				this.props.getData(_.omit(data, ['isValid', 'loading']));
			}else {
				this.setState({
					...this.state,
					loading: false
				});
				toast.error("Validation Error!")
			}
		});
	}

	render() {
		const { work_in_usa, criminal_records, student_with_disability, reliable_transportation, group_tutoring, home_school_help, required_hours, languages, other_informations, isValid, loading} = this.state;

		return(
		<React.Fragment>
                <div id='signup-form'>
                    <div className='form-row'>
                        <div className='form-group col-md-6'>
                            <label htmlFor='work_in_usa' style={{marginBottom: '2rem'}}>Are you elligible to work in the USA?</label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(work_in_usa) ? '' : 'error is-invalid')).toString()}`}
                                    id="work_in_usa" name="work_in_usa" value={work_in_usa}
                                    onChange={this.onChange}>
                                <option value="">Choose One</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            <div className="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                            <label htmlFor='criminal_records'>Do you have any previous criminal records?</label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(criminal_records) ? '' : 'error is-invalid')).toString()}`}
                                    id="criminal_records" name="criminal_records" value={criminal_records} onChange={this.onChange}>
                                <option value="">Choose One</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            <div className="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-group col-md-6'>
                            <label htmlFor='student_with_disability'>Have you worked with students with disabilities?</label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(student_with_disability) ? '' : 'error is-invalid')).toString()}`}
                                    id="student_with_disability" name="student_with_disability" value={student_with_disability}
                                    onChange={this.onChange}>
                                <option value="">Choose One</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            <div className="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                            <label htmlFor='reliable_transportation' style={{marginBottom: '2rem'}}>Do you have reliable transportation?</label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(reliable_transportation) ? '' : 'error is-invalid')).toString()}`}
                                    id="reliable_transportation" name="reliable_transportation" value={reliable_transportation}
                                    onChange={this.onChange}>
                                <option value="">Choose One</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            <div className="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-group col-md-6'>
                            <label htmlFor='group_tutoring' style={{marginBottom: '2rem'}}>Are you interested in group tutoring?</label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(group_tutoring) ? '' : 'error is-invalid')).toString()}`}
                                    id="group_tutoring" name="group_tutoring" value={group_tutoring}
                                    onChange={this.onChange}>
                                <option value="">Choose One</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            <div className="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                            <label htmlFor='home_school_help'>Are you interested in home schooling help?</label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(home_school_help) ? '' : 'error is-invalid')).toString()}`}
                                    id="home_school_help" name="home_school_help" value={home_school_help}
                                    onChange={this.onChange}>
                                <option value="">Choose One</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            <div className="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-group col-md-6'>
                            <label htmlFor='required_hours'>Required number of hours per week</label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(required_hours) ? '' : 'error is-invalid')).toString()}`}
                                    id="required_hours" name="required_hours" value={required_hours}
                                    onChange={this.onChange}>
                                <option value="">Choose One</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                            <div className="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                            <label htmlFor='languages'>What languages do you speak?</label>
                            <input
                                type='text'
                                name='languages'
                                id="languages"
                                value={languages}
                                onChange={this.onChange}
                                className={`form-control light ${(isValid && (validation.text(languages) ? '' : 'error is-invalid')).toString()}`}
                            />
                            <div className="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                    </div>
                    <div className='form-row'>
                    <div className='form-group col-md-6'>
                            <label htmlFor='other_informations'>Any other information we should know?</label>
                            <input
                                type='text'
                                name='other_informations'
                                id="other_informations"
                                value={other_informations}
                                onChange={this.onChange}
                                className={`form-control light ${(isValid && (validation.text(other_informations) ? '' : 'error is-invalid')).toString()}`}
                            />
                            <div className="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                    </div>

                    <div className='d-flex justify-content-center align-items-center my-5'>
                        <button
                            disabled={loading}
                            className='btn btn-primary btn-padding--lg text-white'
                            onClick={this.onSubmit}
                        >
                            Continue
                        </button>
                    </div>
                </div>
		</React.Fragment>
		);
	}
}

export default TutorS5;