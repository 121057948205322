import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom';
import Navbar from './Tutor/Header/Navbar';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Menubar from './Tutor/Header/Menubar';

class TutorLayout extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <main className="position-relative">
        <Navbar path={this.props.location.pathname} />
        <div className='d-none d-md-block'>
          <Menubar path={this.props.location.pathname} />
        </div>
        {this.props.children}
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          transition={Zoom}
          toastClassName="txt-16--body text-white px-3"
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover={false}
        />
      </main>
    );
  }
}

TutorLayout.propTypes = {
  children: PropTypes.node
};

export default withRouter(TutorLayout);

