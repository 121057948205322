import React from 'react';
import './TutorInfo.css';
import Rating from '@material-ui/lab/Rating';
import moment from 'moment';

const TutorInfo = ({value, tutorDetail}) => {
    const currentYear = moment().format('YYYY');
    return <>
        <div className="tutor-detail">
                    <div className="profile-info">
                        <div className="profile-image-wrapper">
                            <img src={tutorDetail?.details?.profile_picture} alt="tutor-1" className='mr-md-3'/>
                        </div>
                        <div className="info">
                            <h3 className="tutor-name">{`${tutorDetail?.first_name} ${tutorDetail?.last_name}`}</h3>
                            <div className="rating">
                                <Rating name="read-only" value={value} readOnly />
                                <p>(32 reviews)</p>
                            </div>
                            
                            <div className="subjects">
                                <img src="/images/book.svg" alt="" />
                                <p>Maths, Algebra, Calculus, Statistics</p>
                            </div>
                            <div className="location">
                                <img src="/images/Vector.svg" alt="" />
                                <p>{`${tutorDetail?.details?.address}, ${tutorDetail?.details?.city}, ${tutorDetail?.details?.state}, ${tutorDetail?.details?.zip_code}`}</p>
                            </div>
                            <div className="bio-detail">
                                <h4 className="title">Bio</h4>
                                <p>{tutorDetail?.details?.bio}</p>
                            </div>
                            <button type="button" className="btn">{`Book ${tutorDetail?.first_name}`}</button>
                        </div>
                        <div className="about-tutor">
                            <h4 className="title">
                            About this tutor
                            </h4>
                            <div>
                                <div className="row">
                                    <div className="col">
                                        <h5>Practicing since:</h5>
                                        <p>{+currentYear - +tutorDetail?.details?.years_of_tutoring_exp}</p>
                                    </div>
                                    <div className="col">
                                        <h5>School:</h5>
                                        <p>{tutorDetail?.details?.school}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <h5>Qualifications:</h5>
                                        <p>{tutorDetail?.details?.highest_level_education}</p>
                                    </div>
                                    <div className="col">
                                        <h5>Special Achievements:</h5>
                                        <p>{tutorDetail?.details?.achievements}</p>
                                    </div>
                                </div>
                            </div>
                            <h4 className="title">Interested in:</h4>
                            <div>
                                <div className="row">
                                    <div className="col">
                                        <p>Hobbies 1</p>
                                    </div>
                                    <div className="col">
                                        <p>Hobbies 2</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <p>Hobbies 3</p>
                                    </div>
                                    <div className="col">
                                        <p>Hobbies 4</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </>
}

export default TutorInfo;