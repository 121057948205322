import React, {Component} from 'react';

class ParentStudentNotification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            //validation
            isValid: false,
            loading: false,
            //image
            profile_picture: "",
        }
    }

    render() {
        return (
            <div className="profile-notification">
                <h5 className='bold serif mb-5'>Notification Settings</h5>
                <form className='flexible-width' onSubmit={(e) => {e.preventDefault();}}>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <p htmlFor="switch-1" className='toggle-label'>Would you like to receive messages on your dashboard?</p>
                        <div className="toggle-switch">
                            <input aria-label="status" type="checkbox" id="switch-1" name="noti_1" />
                            <label htmlFor="switch-1">Status</label>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <p htmlFor="switch-2" className='toggle-label'>Would you like to receive push notifications on your phone?</p>
                        <div className="toggle-switch">
                            <input aria-label="status" type="checkbox" id="switch-2" name="noti_2" />
                            <label htmlFor="switch-2">Status</label>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end padded-intro-section">
                        <button type="submit" className="btn btn-primary btn-padding--lg text-white">Save changes</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default ParentStudentNotification;