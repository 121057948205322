import React from 'react'
import './Testimonial.style.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PropTypes from 'prop-types';

export default function Testimonial({heading}) {
  const settings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <section className="py-5 bg-white">
      <div className="text-center intro-content mb-4">
        <h2>{heading}</h2>
      </div>
      <section className="caraosel-testimonial">
      <Slider {...settings}>
        {
          [...Array(3)].map((val, i) => (
            <div className="padding-2x">
              <div className="t-card d-md-flex">
                <div className="left-image">
                  <img src="/images/testimonial-1.png" alt="testimonial-1"/>
                </div>
                <div className="right-content">
                    <h3 className="member">—  Daenerys Targeryen</h3>
                    <p className="role ml-3">(Parent)</p>
                    <p className="comment">
                    I love how Sistem Tutoring Agency helped me find the best fit for my child in terms of a tutor. Their timely response is top notch. The tutorials have made a positive impact to my child’s academic performance. - without Sistem, it was a different case entirely.
                    </p>
                </div>
                <div className="quote-image">
                  <img src="/images/Badge.svg" alt="quote"/>
                </div>
              </div>
            </div>
          ))
        }
        
      </Slider>
        
      </section>
    </section>
  )
}


Testimonial.propTypes = {
  heading: PropTypes.string
}
Testimonial.defaultProps = {
  heading: 'See what customers and parents are saying'
}