import axios from "axios";
import cookie from "../actions/cookie.js";
import { API_END_POINT } from "../config/env.json";

export const allPlans = () => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.get(`${API_END_POINT}/v1/stripe-plans`, options)
        .then(res => res)
        .catch(error => error);
};

export const availSubscription = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/create-subscription`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const availSubscriptionParent = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    
    return axios.post(`${API_END_POINT}/v1/create-subscription-parent`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const cancelSubscription = () => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/cancel-subscription`, null, options)
        .then(res => res)
        .catch(error => error);
};

export const cancelSubscriptionParent = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/cancel-subscription-parent`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const upgradeSubscription = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.put(`${API_END_POINT}/v1/upgrade-subscription`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const upgradeSubscriptionParent = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.put(`${API_END_POINT}/v1/upgrade-subscription-parent`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const subscriptionDetails = () => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.get(`${API_END_POINT}/v1/user-subscription-details`, options)
        .then(res => res)
        .catch(error => error);
};

export const subscriptionDetailsParent = () => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.get(`${API_END_POINT}/v1/user-subscription-details-parent`, options)
        .then(res => res)
        .catch(error => error);
};

export const upgradeCard = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.put(`${API_END_POINT}/v1/update-card`, payload, options)
        .then(res => res)
        .catch(error => error);
};